import Icons from 'assets/icons'
import { Tooltip, TextWithIcon, IconElement, Anchor } from 'ui'

interface WOLockedLinkProps {
    children: string
    shopName: string
}
export const WOLockedLink = ({ children, shopName }: WOLockedLinkProps) => {
    return (
        <Tooltip title={`${children} is being handled at ${shopName} where you have no access.`}>
            <Anchor>
                <TextWithIcon icon={<IconElement component={Icons.LockOutlined} />}>
                    {children}
                </TextWithIcon>
            </Anchor>
        </Tooltip>
    )
}
