import { type NoInfer } from 'appTypes'
import { type Column, type DataListDetails, type SortBy } from 'components'

const source = 'address' as const
const label = 'Address'

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        field: (id || source) as Source,
        headerName: label,
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies DataListDetails<any, any>

const address1 = {
    source,
    label,
    sort,
    tableColumn,
    dataCardRow,
}
export default address1
