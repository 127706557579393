import Icons from 'assets/icons'

import { type CustomerModel } from '../types'

const source: keyof CustomerModel = 'logo'

const Avatar = {
    source,
    label: 'Avatar',
    Icon: Icons.Customers,
}

export default Avatar
