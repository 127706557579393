import avatar from './Avatar'
import created from './Created'
import dotNumber from './DotNumber'
import email from './Email'
import name from './Name'
import paymentMethod from './PaymentMethod'
import paymentTerm from './PaymentTerm'
import phone from './Phone'
import state from './State'
import tags from './Tags'
import taxId from './TaxId'
import units from './Units'
import poNumber from './poNumber'
import self from './self'
import website from './website'

const customerFields = {
    self,
    avatar,
    name,
    taxId,
    dotNumber,
    units,
    paymentMethod,
    paymentTerm,
    phone,
    email,
    created,
    tags,
    state,
    website,
    poNumber,
}

export default customerFields
