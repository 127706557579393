import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'invoiceDate' as const satisfies keyof InvoiceModel
const label = 'Invoice Date'

const value = (record: Pick<InvoiceModel, 'invoiceDate'>) =>
    formatDate(record.invoiceDate, (format) => format.shortenedDate)

const invoiceDate = {
    source,
    label,
    value,
}

export default invoiceDate
