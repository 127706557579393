import { type FC, type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import {
    type UtilityDrawerEditorProps,
    useOpenUtilityDrawer,
    UtilityDrawerEditor,
} from 'components'
import { NotificationMessage } from 'core'
import { useCreateResourcePath } from 'core/resource'

import { type UnitModel } from '../types'
import unitResource from '../unitResource'
import { unitSerializer } from '../utils'

import UnitForm from './UnitForm'

interface UnitDrawerTogglerProps extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    defaultValues?: Partial<UnitModel>
    shortSuccessMessage?: boolean
}

const UnitDrawerToggler = ({
    children,
    id,
    onSuccess,
    defaultValues,
    shortSuccessMessage,
}: UnitDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const createPath = useCreateResourcePath()
    return children({
        onClick: () => {
            open({
                extraArgs: {
                    resource: unitResource,
                    id,
                },
                drawerArgs: {
                    title: id ? 'Edit Details' : 'Create Unit',
                    renderWrapper: (props) => (
                        <UtilityDrawerEditor
                            defaultValues={defaultValues}
                            onSuccess={onSuccess}
                            {...props}
                            serializer={unitSerializer}
                            successMessage={({ defaultMessages, response }) => {
                                if (id) {
                                    return defaultMessages.updated
                                }
                                if (shortSuccessMessage) {
                                    return defaultMessages.created
                                }

                                const unit = response as UnitModel

                                return {
                                    title: defaultMessages.created,
                                    message: (
                                        <NotificationMessage.Navigate
                                            to={createPath({
                                                resource: unitResource.resource,
                                                type: 'edit',
                                                id: unit.id,
                                            })}
                                        >
                                            {`Unit ${unit.number}`}
                                        </NotificationMessage.Navigate>
                                    ),
                                }
                            }}
                        />
                    ),
                    renderContent: () => (id ? <UnitEditForm /> : <UnitForm />),
                },
            })
        },
    })
}

const UnitEditForm: FC = () => {
    const record = useRecordContext<UnitModel>() || ({} as UnitModel)

    return (
        <UnitForm
            inputsProps={{
                domicile: {
                    disabled: record.hasOpenWorkOrders,
                    defaultValue: false,
                },
                customerUnit: {
                    hide: true,
                },
                customer: {
                    disabled: true,
                    hide: !record?.customer,
                },
                costCenter: record.hasOpenWorkOrders
                    ? {
                          disabled: true,
                          helperText: 'Editing is locked due to open Work Orders',
                      }
                    : null,
                division: record.hasOpenWorkOrders
                    ? {
                          disabled: true,
                          helperText: 'Editing is locked due to open Work Orders',
                      }
                    : null,
            }}
        />
    )
}

export default UnitDrawerToggler
