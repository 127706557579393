import { type FC } from 'react'

import {
    type DatagridColumnsProps,
    List,
    ListAvatar,
    ListBase,
    type CardListConfig,
    type FilterConfig,
    ListFilterValueInput,
    ListFilterDateRangeValueInput,
    ListFilterRangeInput,
    costMaskParams,
    type ListSortContentProps,
} from 'components'
import { type Action, deleteOneAction, editRedirectInListAction, type SortPayload } from 'core'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import { type InvoiceModel, invoiceFields } from 'resources/invoices'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import { woFields } from 'resources/workOrders'
import { PageContent } from 'ui'

import { InvoicesListHeader } from './components'

const InvoicesList: FC = () => {
    return (
        <ListBase sort={defaultSort}>
            <InvoicesListHeader />
            <PageContent>
                <List
                    columnsCfg={columnsConfig}
                    cardsCfg={cardsConfig}
                    filtersCfg={filterConfig}
                    sortCfg={sortConfig}
                />
            </PageContent>
        </ListBase>
    )
}

export default InvoicesList

const actions: Action<InvoiceModel> = ({ id }, { children }) => {
    return [editRedirectInListAction({ children, id }), deleteOneAction({ children, id })]
}

const defaultSort: SortPayload<InvoiceModel> = {
    field: 'created',
    order: 'DESC',
}

const cardsConfig: CardListConfig<InvoiceModel> = {
    titleSource: invoiceFields.number.source,
    subTitleSource: (record) => invoiceFields.status.value(record),
    defaultImage: (record) => <invoiceFields.avatar.Icon record={record} />,
    details: [
        shopFields.self.dataCardRow({
            dataToRecord: (record: InvoiceModel) => record.shopData,
        }),
        customerFields.self.dataCardRow({
            dataToRecord: (record: InvoiceModel) => record.customerData,
        }),
        unitFields.self.dataCardRow({
            dataToRecord: (record) => record.unitData,
        }),
        woFields.self.dataCardRow({
            dataToRecord: (record: InvoiceModel) => record.workOrderData,
        }),
        {
            source: invoiceFields.poNumber.source,
            label: invoiceFields.poNumber.label,
            render: (value, record) => invoiceFields.poNumber.value(record),
        },
        {
            source: invoiceFields.invoiceDate.source,
            label: invoiceFields.invoiceDate.label,
            render: (value, record) => invoiceFields.invoiceDate.value(record),
        },
        {
            source: invoiceFields.closedDate.source,
            label: invoiceFields.closedDate.label,
            render: (value, record) => invoiceFields.closedDate.value(record),
        },
        {
            source: invoiceFields.total.source,
            label: invoiceFields.total.label,
            render: (value, record) => invoiceFields.total.value(record),
        },
        {
            source: invoiceFields.balanceDue.source,
            label: invoiceFields.balanceDue.label,
            render: (value, record) => invoiceFields.balanceDue.value(record),
        },
    ],
    actions,
}

const columnsConfig: DatagridColumnsProps<InvoiceModel> = {
    columns: [
        {
            field: invoiceFields.avatar.source,
            headerName: invoiceFields.avatar.label,
            maxWidth: 72,
            renderCell: ({ row }) => (
                <ListAvatar
                    id={row.id}
                    defaultImage={<invoiceFields.avatar.Icon record={row} />}
                />
            ),
        },
        {
            field: invoiceFields.number.source,
            headerName: invoiceFields.number.label,
            renderCell: ({ row }) => invoiceFields.number.value(row),
        },
        shopFields.self.tableColumn({
            dataToRecord: (record: InvoiceModel) => record.shopData,
        }),
        {
            field: invoiceFields.type.source,
            headerName: invoiceFields.type.label,
            renderCell: ({ row }) => invoiceFields.type.value(row),
        },
        customerFields.self.tableColumn({
            dataToRecord: (record: InvoiceModel) => record.customerData,
        }),
        unitFields.self.tableColumn({
            dataToRecord: (record) => record.unitData,
        }),
        woFields.self.tableColumn({
            dataToRecord: (record: InvoiceModel) => record.workOrderData,
        }),
        {
            field: invoiceFields.woCompletedDate.source,
            headerName: invoiceFields.woCompletedDate.label,
            renderCell: ({ row }) => invoiceFields.woCompletedDate.value(row),
        },
        {
            field: invoiceFields.poNumber.source,
            headerName: invoiceFields.poNumber.label,
            renderCell: ({ row }) => invoiceFields.poNumber.value(row),
        },
        {
            field: invoiceFields.status.source,
            headerName: invoiceFields.status.label,
            renderCell: ({ row }) => invoiceFields.status.value(row),
        },
        createdField.tableColumn({
            dataToValue: (record) => record.created,
        }),
        {
            field: invoiceFields.invoiceDate.source,
            headerName: invoiceFields.invoiceDate.label,
            renderCell: ({ row }) => invoiceFields.invoiceDate.value(row),
        },
        {
            field: invoiceFields.dueDate.source,
            headerName: invoiceFields.dueDate.label,
            renderCell: ({ row }) => invoiceFields.dueDate.value(row),
        },
        {
            field: invoiceFields.closedDate.source,
            headerName: invoiceFields.closedDate.label,
            renderCell: ({ row }) => invoiceFields.closedDate.value(row),
        },
        {
            field: invoiceFields.total.source,
            headerName: invoiceFields.total.label,
            renderCell: ({ row }) => invoiceFields.total.value(row),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: invoiceFields.paid.source,
            headerName: invoiceFields.paid.label,
            renderCell: ({ row }) => invoiceFields.paid.value(row),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: invoiceFields.balanceDue.source,
            headerName: invoiceFields.balanceDue.label,
            renderCell: ({ row }) => invoiceFields.balanceDue.value(row),
            align: 'right',
            headerAlign: 'right',
        },
    ],
    actions: ({ row }, args) => actions(row, args),
}

const filterConfig: FilterConfig<InvoiceModel> = {
    filters: [
        shopFields.self.filter(),
        {
            id: invoiceFields.type.source,
            label: invoiceFields.type.label,
            renderComponent: (params) => (
                <ListFilterValueInput
                    {...params}
                    inputText={(record) => invoiceFields.type.value(record.id)}
                />
            ),
        },
        customerFields.self.filter(),
        unitFields.self.filter(),
        woFields.self.filter(),
        {
            id: invoiceFields.woCompletedDate.source,
            label: invoiceFields.woCompletedDate.label,
            renderComponent: (params) => <ListFilterDateRangeValueInput {...params} />,
        },
        {
            id: invoiceFields.status.source,
            label: invoiceFields.status.label,
            renderComponent: (params) => (
                <ListFilterValueInput
                    {...params}
                    inputText={(record) => invoiceFields.status.getConfig(record).text as string}
                />
            ),
        },
        createdField.filter(),
        {
            id: invoiceFields.invoiceDate.source,
            label: invoiceFields.invoiceDate.label,
            renderComponent: (params) => <ListFilterDateRangeValueInput {...params} />,
        },
        {
            id: invoiceFields.closedDate.source,
            label: invoiceFields.closedDate.label,
            renderComponent: (params) => <ListFilterDateRangeValueInput {...params} />,
        },
        {
            id: invoiceFields.total.source,
            label: invoiceFields.total.label,
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
    ],
}

const sortConfig: ListSortContentProps<InvoiceModel> = {
    sortBy: [
        {
            id: invoiceFields.number.source,
            label: invoiceFields.number.label,
        },
        shopFields.self.sort(),
        {
            id: invoiceFields.type.source,
            label: invoiceFields.type.label,
        },
        customerFields.self.sort(),
        unitFields.self.sort(),
        woFields.self.sort(),
        {
            id: invoiceFields.woCompletedDate.source,
            label: invoiceFields.woCompletedDate.label,
        },
        {
            id: invoiceFields.poNumber.source,
            label: invoiceFields.poNumber.label,
        },
        {
            id: invoiceFields.status.source,
            label: invoiceFields.status.label,
        },
        createdField.sort(),
        {
            id: invoiceFields.invoiceDate.source,
            label: invoiceFields.invoiceDate.label,
        },
        {
            id: invoiceFields.closedDate.source,
            label: invoiceFields.closedDate.label,
        },
        {
            id: invoiceFields.total.source,
            label: invoiceFields.total.label,
        },
        {
            id: invoiceFields.paid.source,
            label: invoiceFields.paid.label,
        },
        {
            id: invoiceFields.balanceDue.source,
            label: invoiceFields.balanceDue.label,
        },
    ],
}
