import Icons from 'assets/icons'
import { deleteOneAction } from 'core/actions'

import { ContactDrawerToggler } from './components/ContactDrawerToggler'
import { type ContactActions } from './types'

export const contactActions: ContactActions = ({ contact, flags }, { children }) => [
    <ContactDrawerToggler
        key="edit"
        id={contact.id}
        children={({ onClick }) => children({ title: 'View/Edit', Icon: Icons.Edit, onClick })}
    />,
    deleteOneAction({
        children,
        id: contact.id,
        confirmConfig: {
            title: 'Are you sure you want to delete this Contact Person?',
        },
    }),
]
