import { type ReactElement, type FC, Fragment } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { DialogSelector, type DialogSelectorProps } from 'components'
import { type AuthStore } from 'core/auth'
import { customerFields } from 'resources/customers'
import { displayCK2 } from 'resources/vmrs'
import { Button, SvgIcon, Typography, BoxContainer } from 'ui'
import { htmlSymbols } from 'utils'

import unitFields from '../fields'
import { type UnitModel } from '../types'
import unitResource from '../unitResource'

import UnitDrawerToggler from './UnitDrawerToggler'

interface Props
    extends Pick<
        DialogSelectorProps,
        'onSelectedChange' | 'renderAboveList' | 'referenceFilter' | 'defaultFilter' | 'resettable'
    > {
    disabled?: boolean
    required?: boolean
    source?: string
    label?: string
    contextType: string
    contextId?: string
}

export const UnitInput: FC<Props> = ({
    disabled,
    required,
    source = 'unit',
    label = 'Unit Number',
    contextType,
    contextId,
    onSelectedChange,
    renderAboveList,
    referenceFilter,
    defaultFilter,
    resettable,
}) => {
    return (
        <DialogSelector<UnitModel>
            resettable={resettable}
            referenceFilter={referenceFilter}
            defaultFilter={defaultFilter}
            reference={unitResource.resource}
            source={source}
            disabled={disabled}
            required={required}
            queryOptions={() => ({ meta: { query: { contextType, contextId } } })}
            defaultSelectorProps={{
                label,
            }}
            defaultSelectorValueSource={({ selected }) => selected?.number}
            titleSource={() => 'Unit'}
            itemAvatar={(record) => ({
                defaultImage: <unitFields.avatar.Icon />,
                imageSrc: record.photo,
            })}
            itemPrimary={(record) => (
                <Typography
                    variant="subtitle1"
                    color="inherit"
                    mb="4px"
                >
                    {record.number}
                </Typography>
            )}
            itemSecondary={(record) => (
                <>
                    <InfoRow1 record={record} />
                    <InfoRow2 record={record} />
                </>
            )}
            perPage={100}
            renderNextToResultCount={({ control, onSelect }) => (
                <UnitDrawerToggler
                    shortSuccessMessage
                    onSuccess={(record) => {
                        onSelect(record.id)
                        control.refetch()
                    }}
                >
                    {({ onClick }) =>
                        control.isFetching ? null : (
                            <Button
                                startIcon={<Icons.Add />}
                                variant="contained"
                                onClick={onClick}
                            >
                                Create Unit
                            </Button>
                        )
                    }
                </UnitDrawerToggler>
            )}
            noResults={({ searchValue }) => {
                if (!searchValue) {
                    return {
                        title: 'No Units Added',
                        text: '',
                        image: (images) => images.listEmpty,
                        imageWidth: '180px',
                    }
                }
            }}
            onSelectedChange={onSelectedChange}
            renderAboveList={renderAboveList}
        />
    )
}

const concatData = (data: (ReactElement | string | number)[]) => {
    return data.filter(Boolean).map((item, index, arr) => {
        if (index === arr.length - 1) {
            return item
        }
        return (
            <Fragment key={index}>
                {item} {htmlSymbols.dot}{' '}
            </Fragment>
        )
    })
}

const InfoIcon = ({ icon, size = '17px' }: { icon: FC; size?: string }) => {
    return (
        <SvgIcon
            component={icon}
            inheritViewBox
            sx={{ width: size, height: size, mr: '4px', verticalAlign: 'middle' }}
        />
    )
}

const InfoRow1: FC<{ record: UnitModel }> = ({ record }) => {
    if (!record.licensePlate) {
        return null
    }

    return (
        <BoxContainer mb="4px">
            <InfoIcon
                size="20px"
                icon={Icons.LP}
            />
            <Typography
                variant="body2"
                color="text.primary"
            >
                {record.licensePlate}
            </Typography>
        </BoxContainer>
    )
}

interface InfoRow2Props {
    record: UnitModel
}

const InfoRow2: FC<InfoRow2Props> = inject('auth')(
    observer(({ record, auth }: InfoRow2Props & { auth: AuthStore }) => {
        return (
            // TODO: More flexibility for custom rows
            // TODO: Dialog selector row color change to text.primary ??
            <Typography
                variant="inherit"
                overflow="hidden"
                whiteSpace="normal"
                sx={{
                    wordBreak: 'break-word',
                }}
                color="text.primary"
            >
                {concatData([
                    record.customerData && auth.companySettings.hasCustomers ? (
                        <Fragment key="customer">
                            <InfoIcon icon={customerFields.avatar.Icon} />
                            {record.customerData.name}
                        </Fragment>
                    ) : null,
                    record.name,
                    record.vin,
                    displayCK2(record.vmrsEquipmentCategoryData),
                    record.model,
                    record.modelYear,
                ])}
            </Typography>
        )
    }),
)
