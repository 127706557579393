import { useResourceContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { PTFeeDrawerToggler } from 'resources/pricingTiers'
import { FixedOnMobile, IconBox, Button } from 'ui'

export const ListHeader = () => {
    const resource = useResourceContext()
    return (
        <ViewHeader
            title="Fees"
            pt="28px"
            pb="20px"
        >
            <ViewHeader.Content>
                <IconBox title="This fee will be automatically applied to an invoice once specified conditions are fulfilled.">
                    <Icons.InfoOutlined />
                </IconBox>
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <FixedOnMobile>
                    <PTFeeDrawerToggler resource={resource}>
                        {(open) => (
                            <Button
                                onClick={open}
                                variant="contained"
                                startIcon={<Icons.Add />}
                            >
                                create
                            </Button>
                        )}
                    </PTFeeDrawerToggler>
                </FixedOnMobile>
            </ViewHeader.Content>
        </ViewHeader>
    )
}
