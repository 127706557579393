import { type FC } from 'react'

import Icons from 'assets/icons'
import { DialogSelector, type AutocompleteInputProps } from 'components'
import { Button, Typography, BoxContainer } from 'ui'

import vendorFields from '../fields'
import { type VendorType } from '../types'
import { formatVendorTypes, vendorsResource } from '../utils'

import { VendorDrawerToggler } from './VendorDrawerToggler'

import type VendorModel from '../types'

interface VendorInputProps extends Pick<AutocompleteInputProps, 'source' | 'label'> {
    disabled?: boolean
    vendorType?: VendorType
    onSelectedChange?: (record: VendorModel) => void
    required?: boolean
    contextId?: string
}

export const VendorInput: FC<VendorInputProps> = ({
    source = 'vendor',
    label = 'Vendor',
    disabled,
    vendorType,
    onSelectedChange,
    required,
    contextId,
}) => {
    return (
        <>
            <DialogSelector<VendorModel>
                reference={vendorsResource.resource}
                source={source}
                defaultSelectorProps={{
                    label,
                }}
                referenceFilter={vendorType ? { type: vendorType } : {}}
                disabled={disabled}
                required={required}
                onSelectedChange={({ selected }) => {
                    onSelectedChange?.(selected)
                }}
                queryOptions={() => ({ meta: { query: { contextId } } })}
                defaultSelectorValueSource={({ selected }) => selected?.name}
                titleSource={() => 'Vendors'}
                itemAvatar={(record) => ({
                    defaultImage: <vendorFields.avatar.Icon />,
                    imageSrc: record.logo,
                })}
                itemPrimary={(record) => record.name}
                itemSecondary={(record) => (
                    <>
                        <InfoRow1 record={record} />
                        <InfoRow2 record={record} />
                        <InfoRow3 record={record} />
                    </>
                )}
                perPage={100}
                renderNextToResultCount={({ control, onSelect }) => (
                    <VendorDrawerToggler
                        onSuccess={(record) => {
                            onSelect(record.id)
                            control.refetch()
                        }}
                        shortSuccessMessage
                        vendorFormProps={
                            vendorType
                                ? {
                                      typeInputProps: {
                                          defaultValue: [vendorType],
                                          choices: (choices) =>
                                              choices.map((choice) =>
                                                  choice.id === vendorType
                                                      ? {
                                                            ...choice,
                                                            disabled: true,
                                                        }
                                                      : choice,
                                              ),
                                      },
                                  }
                                : {}
                        }
                    >
                        {(open) =>
                            control.isFetching ? null : (
                                <Button
                                    startIcon={<Icons.Add />}
                                    variant="contained"
                                    onClick={open}
                                >
                                    Create Vendor
                                </Button>
                            )
                        }
                    </VendorDrawerToggler>
                )}
                noResults={({ searchValue }) => {
                    if (!searchValue) {
                        return {
                            title: 'No Vendors Added',
                            text: '',
                            image: (images) => images.listEmpty,
                            imageWidth: '180px',
                        }
                    }
                }}
            />
        </>
    )
}

const InfoRow1: FC<{ record: VendorModel }> = ({ record }) => {
    if (!record.type) {
        return null
    }

    return (
        <BoxContainer mb="4px">
            <Typography
                color="text.primary"
                variant="inherit"
            >
                {formatVendorTypes(record.type)}
            </Typography>
        </BoxContainer>
    )
}

interface InfoRow2Props {
    record: VendorModel
}

const InfoRow2: FC<InfoRow2Props> = ({ record }) => {
    const value = [record.address, record.address2].filter(Boolean)
    if (!value.length) {
        return null
    }

    return (
        <Typography
            variant="chartsBody"
            component="div"
            overflow="hidden"
            whiteSpace="unset"
        >
            {value.join(', ')}
        </Typography>
    )
}

interface InfoRow3Props {
    record: VendorModel
}

const InfoRow3: FC<InfoRow3Props> = ({ record }) => {
    const value = [record.city, record.state, record.zipCode].filter(Boolean)
    if (!value.length) {
        return null
    }
    return (
        <Typography
            variant="chartsBody"
            component="div"
            overflow="hidden"
            whiteSpace="unset"
        >
            {value.join(', ')}
        </Typography>
    )
}
