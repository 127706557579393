import { formatMoney } from 'components'

import { type InvoiceModel } from '../types'

const source = 'total' as const satisfies keyof InvoiceModel
const label = 'Total'

interface Data extends Pick<InvoiceModel, 'total'> {}

const value = (record: Data) => formatMoney(record.total)

const total = {
    source,
    label,
    value,
}

export default total
