import { type NoInfer } from 'appTypes'
import { type ListFilterChoice, type SortBy, type Column, type DataListDetails } from 'components'

import { type CustomerModel } from '../types'

import name from './Name'

const source = 'customer' as const
const label = 'Customer'

const value = (record: CustomerModel) => <name.Value record={record} />

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CustomerModel
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row)),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CustomerModel
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const filter = () =>
    ({
        id: source,
        label,
    }) as const satisfies ListFilterChoice

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const self = {
    source,
    label,
    value,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}

export default self
