import { ResourceWithClearEffect } from 'core'
import { customersResource } from 'resources/customers'

import List from './List'
import { CustomerShow } from './Show'

const Customers = () => {
    return (
        <ResourceWithClearEffect
            name={customersResource}
            list={<List />}
            edit={<CustomerShow />}
        />
    )
}
export default Customers
