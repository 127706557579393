import { archived } from './archived'
import { avatar } from './avatar'
import { color } from './color'
import { costPerDistance } from './costPerDistance'
import { costPerHour } from './costPerHour'
import { created } from './created'
import { domicile } from './domicile'
import { engineHp } from './engineHp'
import { engineModel } from './engineModel'
import { licensePlate } from './licensePlate'
import { model } from './model'
import { modelYear } from './modelYear'
import { name } from './name'
import { number } from './number'
import self from './self'
import { serialNumber } from './serialNumber'
import status from './status'
import { tireSize } from './tireSize'
import { total } from './total'
import { transmissionGears } from './transmissionGears'
import { transmissionModel } from './transmissionModel'
import { vin } from './vin'

const unitFields = {
    self,
    created,
    number,
    avatar,
    domicile,
    licensePlate,
    archived,
    vin,
    serialNumber,
    model,
    modelYear,
    color,
    tireSize,
    total,
    costPerDistance,
    costPerHour,
    engineModel,
    engineHp,
    transmissionModel,
    transmissionGears,
    name,
    status,
}

export default unitFields
