import { type FC, type PropsWithChildren } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { Header, InnerHeader, ShowHeaderSkeleton, type TabType } from 'components'
import { type AuthStore } from 'core'
import { type Action, ActionsMenu, deleteOneAction, downloadOneAction } from 'core/actions'
import { WoInvoiceDrawer } from 'pages/WorkOrders/Invoice/components'
import { WorkOrderSubtitle } from 'pages/WorkOrders/components'
import { SendPoActionToggler } from 'pages/WorkOrders/components/WorkOrderPurchaseInvoice'
import { poResource } from 'resources/purchaseOrders'
import {
    useWoCancelAction,
    useWoStatusAction,
    WithIsVendorWO,
    woFields,
    type WorkOrderModel,
    deleteWorkOrderAction,
    pdfOneWOArgs,
    vendorWODeleteDialogAction,
    WoStatusKeys,
} from 'resources/workOrders'

const WoShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<WorkOrderModel>()
    const actions = useActions()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="124px"
            actions={actions}
        >
            <Header.Info
                avatar={
                    <Header.Avatar defaultImage={<woFields.avatar.RecordIcon record={record} />} />
                }
            >
                <Header.Content>
                    <Header.Title>{record.number}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <WorkOrderSubtitle workOrder={record} />
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}
export default WoShowHeader

const InvoiceActionWrapper: FC<PropsWithChildren> = inject('auth')(
    observer(({ auth, children }: { auth: AuthStore } & PropsWithChildren) => {
        if (!auth.companySettings.hasInvoicing) {
            return null
        }

        return <>{children}</>
    }),
)

const setTitleOnDisabled = (status: WorkOrderModel['status']) => {
    if (status === 'CANCELED') {
        return 'Canceled WOs cannot be deleted'
    }
    if (status === 'CLOSED') {
        return 'Closed WOs cannot be deleted'
    }
    return null
}

const useActions = () => {
    const statusAction = useWoStatusAction()
    const cancel = useWoCancelAction()

    const actions: Action<WorkOrderModel> = (record, { children, resource }) => {
        return [
            statusAction(children),
            <WithIsVendorWO key="create-invoice">
                {(isVendorWorkOrder) =>
                    !isVendorWorkOrder &&
                    record.status !== WoStatusKeys.CANCELED &&
                    record.unitData.customer ? (
                        <InvoiceActionWrapper>
                            <WoInvoiceDrawer record={record}>
                                {(open) =>
                                    children({
                                        Icon: Icons.ReceiptOutlined,
                                        title: 'Create Invoice',
                                        disabled: Boolean(record.salesOrderData?.invoiceData),
                                        titleOnDisabled: 'Already invoiced',
                                        onClick: open,
                                    })
                                }
                            </WoInvoiceDrawer>
                        </InvoiceActionWrapper>
                    ) : null
                }
            </WithIsVendorWO>,
            <WithIsVendorWO key="send-purchase-wo">
                {(isVendorWorkOrder) =>
                    isVendorWorkOrder &&
                    record.status !== 'CANCELED' && (
                        <SendPoActionToggler
                            children={(open) =>
                                children({
                                    Icon: Icons.SendOutlined,
                                    title: 'Send Purchase Order',
                                    key: 'send-purchase-wo',
                                    onClick: open,
                                })
                            }
                        />
                    )
                }
            </WithIsVendorWO>,
            <WithIsVendorWO key="export-pdf">
                {(isVendorWorkOrder) =>
                    isVendorWorkOrder && (
                        <ActionsMenu
                            record={record}
                            renderToggler={(open) =>
                                children({
                                    Icon: Icons.Pdf,
                                    key: 'export-pdf',
                                    title: 'Export as PDF',
                                    onClick: (e) => open(e),
                                    disableCloseOnClick: true,
                                })
                            }
                            actions={(params, { children }) => [
                                downloadOneAction({
                                    children: (params) =>
                                        children({
                                            ...params,
                                            Icon: Icons.Pdf,
                                            key: 'export-pdf',
                                            title: 'Export Work Order',
                                        }),
                                    ...pdfOneWOArgs(record),
                                }),
                                downloadOneAction({
                                    children: (params) =>
                                        children({
                                            ...params,
                                            Icon: Icons.Pdf,
                                            key: 'export-purchaseorder',
                                            title: 'Export Purchase Order',
                                        }),
                                    resource: poResource,
                                    id: record.purchaseOrderData.id,
                                    filename: record.purchaseOrderData.number,
                                    type: 'pdf',
                                }),
                            ]}
                        />
                    )
                }
            </WithIsVendorWO>,
            <WithIsVendorWO key="downloadOne">
                {(isVendorWorkOrder) =>
                    !isVendorWorkOrder &&
                    downloadOneAction({
                        children,
                        ...pdfOneWOArgs(record),
                    })
                }
            </WithIsVendorWO>,
            cancel(children),
            <WithIsVendorWO key="deleteOne">
                {(isVendorWorkOrder) =>
                    deleteOneAction({
                        disabled: record.status !== 'OPEN' || undefined,
                        id: record.id,
                        ...(isVendorWorkOrder && record.purchaseOrderData?.totalInvoices
                            ? vendorWODeleteDialogAction()
                            : deleteWorkOrderAction(record, resource!)),
                        children,
                        titleOnDisabled: setTitleOnDisabled(record.status),
                    })
                }
            </WithIsVendorWO>,
        ]
    }

    return actions
}
