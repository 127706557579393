import { type Identifier, type BaseModel } from 'appTypes'
import { type CostCenterModel } from 'resources/costCenters'
import { type CustomerModel } from 'resources/customers'
import { type DivisionModel } from 'resources/divisions'
import { type NoteModel } from 'resources/notes'
import { type TagModel } from 'resources/tags'
import { type TelematicsDataModel } from 'resources/telematics'
import { type CK2Model, type CK34Model, type CK47Model, type CK48Model } from 'resources/vmrs'
import { type WorkOrderModel } from 'resources/workOrders'
import { type ShopModel } from 'resourcesBase'

import { type MeterModel } from './meters'

export interface UnitModel extends Totals, BaseModel {
    name: string
    created: string
    customer: Identifier
    customerData: CustomerModel
    color: string
    engineHp: number
    engineModel: number
    downtime: UnitDowntime
    engineVmrsManufacturer: string
    engineVmrsManufacturerData: CK34Model
    lastEngineHours: number
    lastOdometer: number
    lastHubometer: number
    licensePlate: string
    model: string
    modelYear: number
    photo: string
    status: UnitStatusKeys
    tireSize: string
    transmissionGears: string
    transmissionModel: string
    transmissionVmrsManufacturer: number
    transmissionVmrsManufacturerData: CK34Model
    number: string
    urlMeters: string
    vin: string
    serialNumber: string
    vmrsEquipmentCategory: number
    vmrsEquipmentCategoryData: CK2Model
    vmrsManufacturer: number
    vmrsManufacturerData: CK34Model
    lastMeters: MeterModel[]
    archived: string
    costPerDistance: number | null
    costPerHour: number | null
    lastCompletedWorkOrder: WorkOrderModel
    lastCompletedWorkOrderMeters: MeterModel[]
    costPerDistanceFleetAvg: number
    costPerDistancePoints: CPMPoints
    telematicsData?: TelematicsDataModel
    pmIntervalsCount: number
    notes: NoteModel[]
    unresolvedDefects: number
    location?: {
        description: string
        latitude: number
        longitude: number
        updated: string
    }
    tags: Identifier[]
    tagsData: TagModel[]
    openIssuesCount: number
    body?: {
        model?: string
        modelYear?: string
        serialNumber?: string
        vmrsType?: Identifier
        vmrsMaterial?: Identifier
        vmrsManufacturer?: Identifier

        vmrsTypeData?: CK47Model
        vmrsMaterialData?: CK48Model
        vmrsManufacturerData?: CK34Model
    }
    domicile: Identifier
    domicileData: ShopModel
    costCenter: Identifier
    costCenterData: CostCenterModel
    division: Identifier
    divisionData: DivisionModel
    utilizationStart: string

    // only in archived units
    hasRelatedWorkOrders?: boolean
    // only in unarchived units
    hasOpenWorkOrders?: boolean
}

export const enum UnitStatusKeys {
    IN_SERVICE = 'IN_SERVICE',
    OUT_OF_SERVICE = 'OUT_OF_SERVICE',
}
export interface UnitAggregates {
    total: number
    costPerDistance: number
    costPerHour: number
    downtime: number
    downtimePercent: number
    mtbf: number
    urme: number
}

interface Totals {
    total: number
    totalParts: number
    totalLabor: number
    totalTax: number
    totalServices: number
    totalFees: number
}

export type CPMPoints = {
    date: string
    value: number
}[]

export interface DowntimeRatioData {
    totalDowntimeSeconds: number
    percentDowntimeOfPeriod: number
    totalDowntimeStr: string
}

export interface UnitDowntime extends DowntimeRatioData {
    downtimePerPriorityClass: DowntimeList
}

interface DowntimeListItem {
    total: string
    percentOfTotalDowntime: number | null
    totalStr: string
}
export interface DowntimeList {
    EMERGENCY: DowntimeListItem
    SCHEDULED: DowntimeListItem
    NON_SCHEDULED: DowntimeListItem
}

export interface ReportDowntimeModel extends Omit<UnitModel, 'downtime'> {
    downtime: number
    downtimeEmergency: number
    downtimeNonScheduled: number
    downtimeScheduled: number
    downtimePercent: number
}
