import { type InvoiceModel } from '../types'

const source = 'number' as const satisfies keyof InvoiceModel
const label = 'Invoice Number'

interface Data extends Pick<InvoiceModel, 'number'> {}

const value = (record: Data) => record.number

const invoiceNumber = {
    source,
    label,
    value,
}

export default invoiceNumber
