import { useRecordContext } from 'react-admin'

import { ViewHeader } from 'components'
import { WoStatusKeys, type WorkOrderModel } from 'resources/workOrders'
import { Section } from 'ui'

import InvoiceWoCard from './InvoiceWoCard'

const InvoiceSection = () => {
    const record = useRecordContext<WorkOrderModel>()
    if (record?.vendor || record?.status === WoStatusKeys.CANCELED || !record?.unitData?.customer) {
        return null
    }
    const disabled = record?.status === 'CLOSED'
    return (
        <Section>
            <ViewHeader
                title="Invoice"
                loading={!record}
            />
            <InvoiceWoCard
                disabled={disabled}
                invoice={record?.salesOrderData?.invoiceData}
            />
        </Section>
    )
}

export default InvoiceSection
