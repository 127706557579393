import Icons from 'assets/icons'
import { Typography, type TypographyProps } from 'ui'
import { getInitials } from 'utils'

interface DefaultMemberIconProps extends Pick<TypographyProps, 'fontSize' | 'color'> {
    member: { name: string }
}

const MemberIcon = ({ fontSize, member, color = 'primary.main' }: DefaultMemberIconProps) => {
    const initials = getInitials(member.name)
    return initials ? (
        <Typography
            color={color}
            fontSize={fontSize}
            lineHeight="100%"
            fontWeight={500}
        >
            {initials}
        </Typography>
    ) : (
        <Icons.PersonOutlined
            sx={{
                color,
                fontSize,
            }}
        />
    )
}

export default MemberIcon
