import { styled } from 'lib'
import { Box, drawerClasses, paperClasses, SwipeableDrawer } from 'ui'

export const StyledSwipeableDrawer = styled(SwipeableDrawer)`
    & .${drawerClasses.paperAnchorRight} {
        width: 390px;
    }
    & .${drawerClasses.paperAnchorBottom} {
        width: 100%;
        & .${paperClasses.root} {
            height: unset;
            max-height: 93%;
        }
        border-radius: 4px 4px 0px 0px;
    }
`
export const UtilityDrawerPuller = styled(Box)`
    width: 30px;
    height: 6px;
    min-height: 6px;
    margin-top: 12px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 3px;
    align-self: center;
`
export const DrawerContentWrapper = styled('div')`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    & > form,
    & > div {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
        & .header {
            display: flex;
            min-height: 42px;
            padding: 20px;
            padding-top: 13px;
            padding-bottom: 13px;
            & > * {
                display: flex;
                align-items: center;
            }
            & .left {
                margin-right: auto;
            }
            & .right {
                margin-left: auto;
            }
        }
        & .content {
            height: 100%;
            overflow: auto;
            padding: 20px;
            padding-bottom: 6px;
            & .PrivateSwipeArea-root {
                display: none;
            }
        }
        & .footer {
            display: flex;
            margin-top: auto;
            padding: 20px;
            & > * {
                display: flex;
                align-items: center;
            }
            & .left {
                margin-right: auto;
            }
            & .right {
                margin-left: auto;
            }
        }

        & .below-header {
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
        }
    }
`
