import { createResource } from 'core'
import { type ShopModel } from 'resourcesBase'

export const shopDefaultFilter = (shop: ShopModel | null, name = 'shop') =>
    shop ? { [name]: [shop.name] } : undefined

export const shopResource = createResource({
    resource: 'shops',
    name: 'shops',
})
