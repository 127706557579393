import { type NoInfer, type DataRecord } from 'appTypes'
import { type Column, type DataListDetails, type ListFilterChoice, type SortBy } from 'components'
import { createResource } from 'core'

import { type CK2Model, type CK2Aspects } from './types'

export const ck2Aspects = {
    totals: 'totals',
} satisfies { [key: string]: CK2Aspects }

export const ck2Resource = createResource({
    name: 'equipment-category',
    resource: 'vmrs/ck2',
})

export const displayCK2 = (record: CK2Model) => (record ? record.description : '')

const source = 'equipmentCategory' as const
const label = 'Equipment Category'

const column = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToRecord,
}: {
    id?: Source
    label?: string
    dataToRecord: (data: DataRecord) => CK2Model
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => displayCK2(dataToRecord(row)),
    }) as const satisfies Column

const cardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CK2Model
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => displayCK2(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies SortBy

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies ListFilterChoice

export const CK2Field = { column, cardRow, sort, filter }
