import { createContext, type FC, type ReactNode, useContext } from 'react'

const FormInfoContext = createContext<Omit<FormInfoProps, 'children'>>(null)

interface FormInfoProps {
    children: ReactNode
    name?: string
}

export const FormInfo: FC<FormInfoProps> = ({ children, name = '' }) => {
    return <FormInfoContext.Provider value={{ name }}>{children}</FormInfoContext.Provider>
}

export const useFormInfo = () => {
    return useContext(FormInfoContext)
}
