import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { ListBase, useRecordContext } from 'react-admin'

import { type AuthStore } from 'core'
import WorkOrderSimpleList from 'pages/WorkOrders/SimpleList'
import { ContactResource, ContactsSimpleListSection } from 'resources/contacts'
import { shopDefaultFilter } from 'resources/shops'
import { type VendorModel } from 'resources/vendors'
import { PageContent, FlexReverse } from 'ui'

import { vendorContactsResourceName } from '../constants/config'

import VendorDetails from './VendorDetails'
import VendorTopSection from './VendorTopSection'

const VendorOverview: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const record = useRecordContext<VendorModel>()
        return (
            <>
                <VendorTopSection />
                <PageContent>
                    <FlexReverse>
                        <VendorDetails />
                        <ContactResource resourceName={vendorContactsResourceName}>
                            <ListBase perPage={3}>
                                <ContactsSimpleListSection />
                            </ListBase>
                        </ContactResource>
                        {(record?.type.length > 1 || record?.type[0] === 'REPAIR_SERVICES') && (
                            <WorkOrderSimpleList<VendorModel>
                                workOrderType="vendor"
                                createFormInitialValues={(record) => ({
                                    vendor: record?.id,
                                    vendorWorkOrderSwitch: true,
                                })}
                                filter={(record) => ({
                                    ...shopDefaultFilter(auth.defaultShop),
                                    vendor: [record?.name],
                                })}
                                disabledFields={{
                                    vendorWorkOrderSwitch: true,
                                }}
                            />
                        )}
                    </FlexReverse>
                </PageContent>
            </>
        )
    }),
)

export default VendorOverview
