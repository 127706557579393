import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'dueDate' as const satisfies keyof InvoiceModel
const label = 'Due Date'

const value = (record: Pick<InvoiceModel, 'dueDate'>) =>
    formatDate(record.dueDate, (format) => format.shortenedDate)

const dueDate = {
    source,
    label,
    value,
}

export default dueDate
