import Icons from 'assets/icons'
import { urls } from 'configs'
import { ConfigureMenuIcon } from 'pages/Configure'
import { customerFields } from 'resources/customers'
import { inventoryFields } from 'resources/inventory'
import { invoiceFields } from 'resources/invoices'
import { issueFields } from 'resources/issues'
import { partFields } from 'resources/parts'
import { reportsUrls } from 'resources/reports'
import { unitFields } from 'resources/units'
import { vendorFields } from 'resources/vendors'
import { woFields } from 'resources/workOrders'

import { type MenuItemInterface } from './SidebarNavItem'

const mainMenu: MenuItemInterface[] = [
    {
        key: 'dashboard',
        to: urls.root,
        primary: 'Dashboard',
        icon: Icons.Dashboard,
    },
    {
        key: 'work-orders',
        to: urls.workOrders,
        primary: 'Work Orders',
        icon: woFields.avatar.Icon,
    },
    {
        key: 'issues',
        primary: 'Issues',
        to: urls.issues,
        icon: issueFields.avatar.Icon,
    },
    {
        key: 'units',
        primary: 'Units',
        to: urls.units,
        icon: unitFields.avatar.Icon,
    },
    {
        key: 'customers',
        primary: 'Customers',
        to: urls.customers,
        icon: customerFields.avatar.Icon,
        hide: ({ auth }) => !auth.companySettings.hasCustomers,
    },
    {
        key: 'vendors',
        primary: 'Vendors',
        to: urls.vendors,
        icon: vendorFields.avatar.Icon,
        tooltip: 'Vendors',
    },
    {
        key: 'parts',
        primary: 'Parts Catalog',
        to: urls.parts,
        icon: partFields.avatar.Icon,
    },
    {
        key: 'vendor-parts',
        primary: 'Vendor Parts',
        to: urls.vendorParts,
        icon: partFields.avatar.Vendor,
    },
    {
        key: 'inventory',
        primary: 'Inventory',
        icon: inventoryFields.avatar.Icon,
        to: urls.inventory,
        hide: ({ auth }) => !auth.companySettings.hasInventory,
    },
    {
        key: 'invoices',
        primary: 'Invoices',
        icon: invoiceFields.avatar.BaseIcon,
        to: urls.invoices,
        hide: ({ auth }) => !auth.companySettings.hasInvoicing,
    },
    {
        key: 'purchase-orders',
        primary: 'Purchase Orders',
        to: urls.purchaseOrders,
        icon: Icons.ReceiptLongOutlined,
        hide: ({ auth }) => !auth.companySettings.hasInventory,
    },
    {
        key: 'reports',
        primary: 'Reports',
        to: urls.reports,
        icon: Icons.Reports,
        tooltip: 'Reports',
        submenu: [
            {
                title: 'Maintenance Cost',
                key: 'maintenance-cost',
                to: reportsUrls.maintenanceCost,
            },
            {
                title: 'Cost per Meter',
                key: 'cost-per-meter',
                to: reportsUrls.costPerMeter,
            },
            {
                title: 'Downtime by Repair Class',
                key: 'downtime',
                to: reportsUrls.downtime,
            },
            {
                title: 'Cost per Category',
                key: 'cost-per-category',
                to: reportsUrls.costPerCategory,
            },
            {
                title: 'Cost per VMRS Group',
                key: 'cost-per-vmrs-group',
                to: reportsUrls.costPerVMRSGroup,
                hide: ({ auth }) =>
                    !(
                        auth.companySettings.ck33GroupsEnabled &&
                        auth.companySettings.ck33GroupsSelectable
                    ),
            },
            {
                title: 'Total Vendor Expenses',
                key: 'vendor-expenses',
                to: reportsUrls.vendorExpenses,
            },
            {
                title: 'Cost per Reason for Repair',
                key: 'reason-for-repair',
                to: reportsUrls.costPerReasonForRepair,
            },
            {
                title: 'Cost per Equipment Category',
                key: 'equipment-category',
                to: reportsUrls.costPerEquipmentCategory,
            },
            {
                title: 'PM Intervals',
                key: 'intervals',
                to: reportsUrls.intervals,
            },
            {
                title: 'Unresolved Defects',
                key: 'defects',
                to: reportsUrls.defects,
            },
            {
                title: 'Unit Expirations',
                key: 'unit-expirations',
                to: reportsUrls.unitExpirations,
                hide: ({ flags }) => !flags.flag4775UnitExpirations,
            },
            {
                title: 'Unscheduled Roadside Maintenance Event',
                key: 'maintenance-event',
                to: reportsUrls.urme,
                hide: ({ flags }) => !flags.flag4598MtbfUrme,
            },
            {
                title: 'Mean Time Between Failures',
                key: 'time-between-failures',
                to: reportsUrls.mtbf,
                hide: ({ flags }) => !flags.flag4598MtbfUrme,
            },
        ],
    },
    {
        key: 'configure',
        primary: 'Configure',
        to: urls.configure,
        icon: Icons.SettingsOutlined,
        renderIcon: (params) => <ConfigureMenuIcon {...params} />,
    },
]

export default mainMenu
