import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { type WorkOrderModel } from 'resources/workOrders'
import { IconBox } from 'ui'

import AddVendorDrawerToggler from './AddVendorDrawerToggler'

const VendorSectionHeader = () => {
    const record = useRecordContext<WorkOrderModel>()
    const disableAction = Boolean(record?.jobItemsCount)
    const isWOClosed = record?.status === 'CLOSED'
    return (
        <ViewHeader
            title="Vendor"
            loading={!record}
        >
            <ViewHeader.Content placement="rightMobile">
                {record?.purchaseOrderData ? (
                    <IconBox title="To change or remove vendor, you'll need to create a new work order.">
                        <Icons.InfoOutlined />
                    </IconBox>
                ) : (
                    <AddVendorDrawerToggler id={record?.id}>
                        {({ onClick }) => (
                            <IconBox
                                disabled={disableAction || isWOClosed}
                                onClick={disableAction || isWOClosed ? undefined : onClick}
                                title={
                                    disableAction &&
                                    'Vendor editing is disabled due to existing WO job items. Clear them first.'
                                }
                            >
                                <Icons.Add />
                            </IconBox>
                        )}
                    </AddVendorDrawerToggler>
                )}
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default VendorSectionHeader
