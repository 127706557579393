import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import {
    type DatagridColumnsProps,
    List,
    ListAvatar,
    ListBase,
    type CardListConfig,
    ListFilterValueInput,
    type FilterConfig,
    ListFilterDateRangeValueInput,
    type ListBulkActions,
    type ListSortContentProps,
} from 'components'
import { type SortPayload } from 'core'
import {
    type ActionChildren,
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    multiselectAction,
} from 'core/actions'
import { type AuthStore } from 'core/auth'
import { useCreateResourcePath } from 'core/resource'
import { formatDate } from 'lib'
import {
    formatPOTotal,
    getPOIcon,
    poFields,
    poResource,
    type PoStatusKeys,
    type PurchaseOrderModel,
} from 'resources/purchaseOrders'
import { shopDefaultFilter } from 'resources/shops'
import { woResource } from 'resources/workOrders'
import { PageContent, Typography } from 'ui'
import { displayBooleanValue } from 'utils'

import POExportPDF from '../Show/components/POExportPDF'
import { PurchaseOrderDrawerToggler } from '../components'
import { deletePOTitle, getDisableDeleteTitle } from '../utils'

import {
    InvoiceField,
    PoListHeader,
    PurchaseOrderTitle,
    WOPurchaseOrderEditButton,
} from './components'

const defaultSort: SortPayload<PurchaseOrderModel> = {
    field: 'created',
    order: 'DESC',
}

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Purchase Orders?',
        },
    }),
]

const actions = (record: PurchaseOrderModel, children: ActionChildren, cardActions?: boolean) => {
    const disableDeleteTitle = getDisableDeleteTitle(record)
    const actions = [
        record.workOrder ? (
            <WOPurchaseOrderEditButton
                record={record}
                key="edit"
            >
                {children({
                    Icon: Icons.Edit,
                    title: 'View/Edit',
                })}
            </WOPurchaseOrderEditButton>
        ) : (
            editRedirectInListAction({
                children,
                id: record.id,
            })
        ),
        <POExportPDF
            key="pdf"
            record={record}
        >
            {(open) =>
                children({
                    onClick: open,
                    title: 'Export PDF',
                    Icon: Icons.Pdf,
                    ...(cardActions && { disableCloseOnClick: true }),
                })
            }
        </POExportPDF>,
        deleteOneAction({
            children: (params) =>
                children({
                    ...params,
                }),
            id: record.id,
            disabled: Boolean(disableDeleteTitle),
            titleOnDisabled: disableDeleteTitle,
            confirmConfig: {
                title: deletePOTitle,
            },
        }),
    ]
    if (cardActions) {
        return [
            actions[0],
            multiselectAction({
                children,
                id: record.id,
            }),
            ...actions.slice(1),
        ]
    }
    return actions
}

const filtersCfg: FilterConfig<PurchaseOrderModel & { invoice: never }> = {
    filters: [
        {
            id: 'number',
            label: 'PO Number',
        },
        { id: 'shop', label: 'Repair Shop' },
        {
            id: 'type',
            label: 'PO Type',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => poFields.type.config[record.id]}
                />
            ),
        },
        { id: 'vendor', label: 'Vendor' },
        {
            id: 'status',
            label: 'Status',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) =>
                        poFields.status.value({ status: record.id as PoStatusKeys })
                    }
                />
            ),
        },
        {
            id: 'created',
            label: 'Created on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'dateLastReceived',
            label: 'Last Received on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'closedOn',
            label: 'Closed on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'backorder',
            label: 'Backorder',
        },
        {
            id: 'invoice',
            label: 'Invoice Number',
        },
    ],
}
const sortCfg: ListSortContentProps<PurchaseOrderModel & { invoice: never }> = {
    sortBy: [
        { id: 'number', label: 'PO Number' },
        { id: 'shop', label: 'Repair Shop' },
        { id: 'type', label: 'PO Type' },
        { id: 'vendor', label: 'Vendor' },
        {
            id: 'status',
            label: 'Status',
        },
        {
            id: 'created',
            label: 'Created on',
        },
        { id: 'dateLastReceived', label: 'Last Received on' },
        { id: 'closedOn', label: 'Closed on' },
        { id: 'items', label: 'Items' },
        { id: 'backorder', label: 'Backorder' },
        { id: 'invoice', label: 'Invoices Count' },
        {
            id: 'total',
            label: 'Total Purchase',
        },
    ],
}

const getColumnCfg: (
    navigateTo: (record: PurchaseOrderModel) => string,
) => DatagridColumnsProps<PurchaseOrderModel & { photo: string; invoice: never }> = (
    navigateTo,
) => ({
    constantColumns: {
        number: true,
    },
    columns: [
        {
            field: 'photo',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: (cell) => {
                const Icon = getPOIcon(cell.row.type)

                return (
                    <ListAvatar
                        linkProps={{
                            'aria-label': `View Purchase Order with unit number ${cell.row.number}`,
                        }}
                        id={cell.id}
                        imageSrc={cell.value}
                        defaultImage={<Icon />}
                        customPath={navigateTo(cell.row)}
                    />
                )
            },
        },
        {
            field: 'number',
            headerName: 'PO Number',
            renderCell: ({ row }) => <PurchaseOrderTitle record={row} />,
        },
        {
            field: 'shop',
            headerName: 'Repair Shop',
            valueGetter: ({ row }) => row.shopData.name,
        },
        {
            field: 'type',
            headerName: 'PO Type',
            valueFormatter: ({ value }) => poFields.type.config[value],
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
            valueGetter: ({ row }) => row.vendorData.name,
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: ({ row }) => poFields.status.value(row),
        },
        {
            field: 'created',
            headerName: 'Created on',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            field: 'dateLastReceived',
            headerName: 'Last Received on',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            field: 'closedOn',
            headerName: 'Closed on',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        { field: 'items', headerName: 'Items' },
        {
            field: 'backorder',
            headerName: 'Backorder',
            valueFormatter: ({ value }) => displayBooleanValue(value),
        },
        {
            field: 'invoice',
            headerName: 'Invoice Number',
            renderCell: ({ row }) =>
                row.invoiceData.length ? <InvoiceField invoices={row.invoiceData} /> : null,
        },
        {
            field: 'total',
            headerName: 'Total Purchase',
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ value }) => {
                return formatPOTotal(value)
            },
        },
    ],
    actions: ({ row }, { children }) => actions(row, children),
})

const cardCfg: CardListConfig<PurchaseOrderModel> = {
    titleSource: (record) => <PurchaseOrderTitle record={record} />,
    disableTitleLink: true,
    defaultImage: (record) => {
        const Icon = getPOIcon(record.type)

        return <Icon />
    },
    subTitleSource: (record) => poFields.status.value(record),
    details: [
        { label: 'vendor', source: 'vendorData', render: (value) => value.name },
        {
            source: 'shop',
            label: 'Repair Shop',
            render: (value, data) => data.shopData.name,
        },
        { label: 'PO Type', source: 'type', render: (value) => poFields.type.config[value] },
        {
            label: 'Created on',
            source: 'created',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            label: 'Last Received on',
            source: 'dateLastReceived',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            label: 'Closed on',
            source: 'closedOn',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        { label: 'Items', source: 'items' },
        { label: 'Backorder', source: 'backorder', render: displayBooleanValue },
        {
            label: 'Invoice Number',
            source: 'invoiceData',
            render: (value) =>
                value.length ? (
                    <InvoiceField
                        invoices={value}
                        togglerSize="extraSmall"
                    />
                ) : null,
        },
        {
            label: 'Total Purchase',
            source: 'total',
            render: (value) => {
                return formatPOTotal(value)
            },
        },
    ],
    actions: (record, { children }) => actions(record, children, true),
}

const PurchaseOrdersList = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const createPath = useCreateResourcePath()

        const navigateTo = (record: PurchaseOrderModel) => {
            return record.workOrder
                ? createPath({
                      resource: woResource.resource,
                      type: 'edit',
                      id: record.workOrder,
                  }) + '/invoice'
                : createPath({
                      resource: poResource.resource,
                      type: 'edit',
                      id: record.id,
                  })
        }

        return (
            <ListBase
                sort={defaultSort}
                filterDefaultValues={shopDefaultFilter(auth.defaultShop)}
            >
                <PoListHeader />
                <PageContent>
                    <List
                        sortCfg={sortCfg}
                        columnsCfg={getColumnCfg(navigateTo)}
                        cardsCfg={cardCfg}
                        filtersCfg={filtersCfg}
                        bulkActions={bulkActions}
                        listFTUProps={{
                            secondaryTitle: 'Would you like to create one?',
                            linkText: (
                                <PurchaseOrderDrawerToggler>
                                    {({ onClick }) => (
                                        <Typography
                                            variant="body1"
                                            onClick={onClick}
                                            color={(theme) => theme.palette.primary.main}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            Create Purchase Order{' '}
                                        </Typography>
                                    )}
                                </PurchaseOrderDrawerToggler>
                            ),
                            linkAction: (e) => {
                                e.preventDefault()
                            },
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
) as FC

export default PurchaseOrdersList
