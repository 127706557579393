import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { type ShopModel } from 'resourcesBase'
import { GridContainer, GridItemLayout, IconBox, Section } from 'ui'

import InvoicingCard from './InvoicingCard'
import InvoicingSettingsDrawerToggler from './InvoicingSettingsDrawerToggler'

const InvoicingSection = () => {
    const { isLoading, record } = useShowContext<ShopModel>()
    return (
        <Section>
            <ViewHeader
                title="Invoicing"
                loading={isLoading}
            >
                <ViewHeader.Content placement="rightMobile">
                    <InvoicingSettingsDrawerToggler id={record?.id}>
                        {({ onClick }) => (
                            <IconBox
                                title="Edit"
                                onClick={onClick}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </InvoicingSettingsDrawerToggler>
                </ViewHeader.Content>
            </ViewHeader>
            <GridContainer>
                <GridItemLayout>
                    <InvoicingCard />
                </GridItemLayout>
            </GridContainer>
        </Section>
    )
}

export default InvoicingSection
