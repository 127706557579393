import { type FC } from 'react'

import { useResourceContext } from 'react-admin'

import { PtTitle } from '../../../components'
import { PtServiceDrawerToggler } from '../../components'
import { type PtServicesModel } from '../../types'
interface Props {
    record: Pick<PtServicesModel, 'name' | 'id' | 'isDefault'>
}

const Value: FC<Props> = ({ record }) => {
    const resource = useResourceContext()

    return (
        <PtServiceDrawerToggler
            id={record.id}
            resource={resource}
        >
            {(open) => (
                <PtTitle
                    showStatus={record.isDefault}
                    onClick={open}
                    tooltip={`The "Default" setting is your shop's standard pricing for
                    repairs, used whenever a customer doesn't have
                    a specific pricing plan in place.`}
                >
                    {record.name}
                </PtTitle>
            )}
        </PtServiceDrawerToggler>
    )
}

export default Value
