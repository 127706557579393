import { type InvoiceModel } from '../types'

const source = 'poNumber' as const satisfies keyof InvoiceModel
const label = 'Customer PO Number'

interface Data extends Pick<InvoiceModel, 'poNumber'> {}

const value = (record: Data) => record.poNumber

const poNumber = {
    source,
    label,
    value,
}

export default poNumber
