import { type ReactElement, type ReactNode } from 'react'

import NoResultsCard, { ListFtuCardContainer } from 'components/NoResultsCard'
import { type UseCreateResourcePathParams, useCreateResourcePath } from 'core/resource'
import { Link } from 'lib'
import { Anchor, type AnchorProps } from 'ui'

export interface ListFTUProps {
    title?: ReactNode
    secondaryTitle?: ReactNode
    linkText?: ReactNode
    queryParams?: UseCreateResourcePathParams['queryParams']
    linkAction?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    imageSrc?: string
    action?: ReactElement
}

export const FtuCreateText: typeof Anchor = (props) => {
    return (
        <Anchor
            component="div"
            variant="body1"
            color="primary"
            mb="55px"
            textAlign="center"
            {...props}
        />
    )
}

const LinkTextLink = ({
    queryParams,
    ...props
}: Pick<UseCreateResourcePathParams, 'queryParams'> & AnchorProps) => {
    const createPath = useCreateResourcePath()

    return (
        <FtuCreateText
            {...props}
            component={Link}
            to={createPath({ type: 'create', queryParams })}
        />
    )
}

const ListFTU = ({
    secondaryTitle,
    linkText = 'Create',
    queryParams,
    title = 'No Items',
    linkAction,
    imageSrc,
    action,
}: ListFTUProps) => {
    return (
        <ListFtuCardContainer
            container
            sx={{ flex: 1 }}
        >
            <NoResultsCard
                transparent
                height="auto"
                title={title}
                subtitle={secondaryTitle}
                imageSrc={(images) => imageSrc || images.listEmpty}
                imageProps={{
                    mb: {
                        sm: '32px',
                        xs: '16px',
                    },
                    width: {
                        sm: 'auto',
                        xs: '180px',
                    },
                }}
                action={
                    action ||
                    (linkText &&
                        (linkAction ? (
                            <FtuCreateText onClick={linkAction}>{linkText}</FtuCreateText>
                        ) : (
                            <LinkTextLink queryParams={queryParams}>{linkText}</LinkTextLink>
                        )))
                }
            />
        </ListFtuCardContainer>
    )
}

export default ListFTU
