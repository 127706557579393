import { type FC, type ReactNode } from 'react'

import { type GridRenderCellParams, type GridValueGetterParams } from '@mui/x-data-grid'
import { gridClasses, DataGridPremium, type GridPinnedColumns } from '@mui/x-data-grid-premium'
import { inject, observer } from 'mobx-react'
import { useListContext, type ListControllerProps, type ListControllerResult } from 'react-admin'

import { type DataRecord, type ObjectAny } from 'appTypes'
import { ListViewMode } from 'components/list/ListViewContext'
import useFirstListLoad from 'components/list/useFirstLoad'
import { type Action, type ActionChildren } from 'core/actions'
import { type AuthStore } from 'core/auth'
import { type ResourceType, useResource } from 'core/resource'
import { alpha, classes, type Flags, globalClassNames, useFlags } from 'lib'
import { BoxContainer, Skeleton, typographyClasses } from 'ui'

import Pagination from '../list/Pagination'

import {
    DatagridActionView,
    DatagridActionsContainer,
    type GridActionViewProps,
} from './DatagridAction'

import type { DataGridProps, GridSlotsComponent } from '@mui/x-data-grid'
import type { GridEnrichedColDef, GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'

export type Column<RecordType extends DataRecord = any> = Omit<
    GridEnrichedColDef,
    'renderCell' | 'valueGetter'
> & {
    hidden?: boolean
    field: keyof RecordType | 'actions'
    renderCell?: (
        params: Parameters<NonNullable<GridEnrichedColDef<RecordType>['renderCell']>>[0],
        extraParams: {
            flags: Flags
            listContext: ListControllerResult<RecordType>
        },
    ) => ReturnType<NonNullable<GridEnrichedColDef['renderCell']>>
    toExport?: {
        separate?: Pick<Column<RecordType>, 'field' | 'headerName'>[]
    }
    valueGetter?: (params: GridValueGetterParams<any, RecordType>) => string | number
}

export type DatagridActionType<RecordType extends DataRecord = any> = // TODO: Action - reduce amount of generics
    Action<GridRenderCellParams<never, RecordType>, {}, ActionChildren<GridActionViewProps>>

type RecordKeys<RecordType extends ObjectAny> = keyof RecordType

export interface DatagridColumnsProps<RecordType extends DataRecord = any>
    extends Pick<DataGridProps, 'checkboxSelection'> {
    columns: Column<RecordType>[]
    actions?: DatagridActionType<RecordType>
    massColProps?: MassColProps
    resetColumns?: { [key in RecordKeys<RecordType>]?: boolean } // if undefined sets all visible
    constantColumns?: { [key in RecordKeys<RecordType>]?: boolean }
    skeletonRowsCount?: number
    hideFooter?: boolean
    pinnedColumns?: GridPinnedColumns
}

export interface DatagridProps<RecordType extends DataRecord = any>
    extends ListControllerProps,
        DatagridColumnsProps<RecordType> {
    visibleColsRef: any
    preferencesResource: ResourceType
    initialVisibleColsRef: React.MutableRefObject<{ [key in RecordKeys<RecordType>]?: boolean }>
    disableSelectRecord?: (record: RecordType) => string
    loading: boolean
}

interface MassColProps
    extends Pick<
        GridColDef,
        'sortable' | 'filterable' | 'hideable' | 'disableColumnMenu' | 'flex'
    > {}

export type ExportColumnType = [string, string]

export const getVisibleColumns = (columns: Column[]) => {
    return columns.reduce((cols, col) => {
        const appendColumn = (column: { field: string; headerName?: string }) => {
            cols.push([column.field, column.headerName || column.field])
        }
        if (col.toExport) {
            if (col.toExport.separate) {
                col.toExport.separate.forEach(appendColumn)
            }
        } else {
            appendColumn(col)
        }
        return cols
    }, [] as ExportColumnType[])
}

const defaultMassColProps: MassColProps = {
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    flex: 1,
}
const extraClasses = {
    [`& .${gridClasses.virtualScroller}`]: {
        marginTop: '0px !important',
    },
    [`& .${gridClasses.columnHeaders}`]: {
        display: 'none',
    },
    [`& .${gridClasses.virtualScrollerContent}`]: {
        padding: '26px 0px',
    },
    [`& .${gridClasses.virtualScrollerRenderZone}`]: {
        width: '100%',
        height: '100%',
    },
}
const skeletonComponent: Partial<GridSlotsComponent> = {
    Row: () => (
        <BoxContainer
            width="100%"
            height="56px"
            padding="0px 16px"
        >
            {new Array(5).fill(0).map((v, i) => {
                return (
                    <Skeleton
                        sx={{
                            flex: i === 0 ? '1' : '4',
                            height: '8px',
                        }}
                        key={i}
                    />
                )
            })}
        </BoxContainer>
    ),
    Footer: () => null,
}
const getSkeletonGridProps = (perPage: number): Partial<DataGridProps> => ({
    rowHeight: 56,
    rows: new Array(perPage).fill({}).map((v, i) => {
        return { id: `${i}` }
    }),
    rowCount: perPage,
    components: skeletonComponent,
})

const Datagrid: FC<DatagridProps> = inject('auth')(
    observer(
        <RecordType extends DataRecord = any>({
            columns,
            massColProps,
            actions = () => [],
            checkboxSelection = true,
            visibleColsRef,
            auth,
            preferencesResource,
            initialVisibleColsRef,
            disableSelectRecord,
            loading,
            skeletonRowsCount,
            hideFooter = false,
            pinnedColumns,
            ...props
        }: DatagridProps<RecordType> & { auth: AuthStore }) => {
            const listContext = useListContext(props)
            const { data, onSelect, selectedIds, page, setPage, perPage, setPerPage, total } =
                listContext
            const flags = useFlags()

            const resource = useResource()
            const firstLoad = useFirstListLoad(listContext, ListViewMode.list, loading)

            const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value)
            }
            const handleRowsChange = (event: React.ChangeEvent<unknown>, callback) => {
                auth.updatePreferences({
                    resources: {
                        [preferencesResource.name]: {
                            perPage: Number(callback.props.value),
                        },
                    },
                })
                setPerPage(callback.props.value)
            }
            const configuredColumns: GridEnrichedColDef<RecordType>[] = (columns || []).map(
                (col) => {
                    const column = {
                        ...defaultMassColProps,
                        ...massColProps,
                        ...col,
                        renderCell: (value) => {
                            const element = col?.renderCell?.(value, { flags, listContext })
                            if (!element) {
                                return null
                            }
                            return <GridCell>{element}</GridCell>
                        },
                    }

                    if (column.width) {
                        column.flex = undefined
                    }

                    return column
                },
            )

            if (actions) {
                configuredColumns.push({
                    field: 'actions',
                    headerName: 'Actions',
                    flex: 1,
                    maxWidth: 122,
                    minWidth: 122,
                    sortable: false,
                    filterable: false,
                    disableColumnMenu: true,
                    hideable: false,
                    renderCell: (cell) => (
                        <DatagridActionsContainer>
                            {actions(cell as GridRenderCellParams<never, RecordType>, {
                                children: (params) => <DatagridActionView {...params} />,
                                resource,
                            })}
                        </DatagridActionsContainer>
                    ),
                })
            }
            const { rows: fakeData, ...skeletonGridProps } = firstLoad
                ? getSkeletonGridProps(skeletonRowsCount ?? perPage)
                : { rows: [] }

            return (
                <DataGridPremium
                    disableColumnReorder
                    disableSelectionOnClick
                    autoHeight
                    rowHeight={undefined}
                    hideFooterSelectedRowCount
                    disableColumnResize
                    components={{
                        Pagination,
                    }}
                    pagination
                    hideFooter={hideFooter}
                    isRowSelectable={
                        disableSelectRecord ? ({ row }) => !disableSelectRecord(row) : undefined
                    }
                    componentsProps={{
                        basePopper: {
                            disablePortal: true,
                            sx: {
                                bottom: '55px !important',
                                top: 'unset !important',
                                transform:
                                    data?.length < 5
                                        ? 'translateY(50%) !important'
                                        : 'unset !important',
                            },
                        },
                        pagination: {
                            count: total,
                            component: 'div',
                            page: page - 1,
                            onPageChange: handlePageChange,
                            rowsPerPage: perPage,
                            rowsPerPageOptions: [5, 10, 25, 50, 100],
                            labelDisplayedRows: () =>
                                `${page * perPage - perPage + 1}-\
                        ${total < page * perPage ? total : page * perPage} of ${total}`,
                            onRowsPerPageChange: handleRowsChange,
                        },
                    }}
                    sx={{
                        flexGrow: 0,
                        width: '100%',
                        bgcolor: 'white',
                        color: 'text.main',
                        [`& .${gridClasses.checkboxInput}`]: {
                            padding: '0px !important',
                        },
                        [`& .${gridClasses.cell}`]: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '&:focus-within': {
                                outline: 'none',
                            },
                            [`& .${typographyClasses.root}`]: {
                                whiteSpace: 'inherit',
                                overflow: 'inherit',
                                textOverflow: 'inherit',
                            },
                        },
                        [`& .${gridClasses.cellContent}, & .${gridClasses.cell}`]: {
                            '&:empty::before': {
                                content: '"-"',
                                display: 'inline-block',
                            },
                        },
                        [`& .${gridClasses.columnHeader}.${gridClasses['columnHeader--sortable']}`]:
                            {
                                padding: '16px',
                            },
                        [`& .${gridClasses.columnHeader}:focus-within`]: {
                            outline: 'none',
                        },
                        [`& 	.${gridClasses.footerContainer}`]: {
                            justifyContent: 'flex-end',
                        },
                        [`& .${classes.selected}`]: (theme) => ({
                            backgroundColor: `${alpha(
                                theme.palette.primary.main,
                                0.08,
                            )} !important`,
                        }),
                        ...(firstLoad ? extraClasses : {}),
                        [`& .MuiSkeleton-root`]: {
                            marginLeft: '16px',
                            marginRight: '16px',
                        },
                    }}
                    rows={data ? data : fakeData}
                    rowCount={total}
                    columns={configuredColumns}
                    columnBuffer={Object.keys(visibleColsRef.current).length}
                    onColumnVisibilityModelChange={(newModel) => {
                        const { __check__, ...newModelRest } = newModel
                        auth.updateLocalUserPreferencesByResource(
                            preferencesResource.name,
                            'visibleColumns',
                            newModelRest,
                        )
                        auth.syncPreferences()

                        visibleColsRef.current = getVisibleColumns(
                            columns.filter(
                                (column) =>
                                    newModelRest[column.field] !== false &&
                                    column.field !== 'actions',
                            ),
                        )
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: initialVisibleColsRef.current,
                        },
                        pinnedColumns,
                    }}
                    paginationMode="server"
                    rowsPerPageOptions={[1, 5, 10, 25, 100]}
                    pageSize={perPage}
                    checkboxSelection={checkboxSelection}
                    selectionModel={checkboxSelection ? selectedIds : defaultSelectionModel}
                    onSelectionModelChange={(selectionModel) => {
                        try {
                            onSelect(selectionModel)
                        } catch {
                            // sometimes this throws an error when selectedIds change during render
                        }
                    }}
                    localeText={{
                        toolbarColumns: 'MANAGE COLUMNS',
                        checkboxSelectionHeaderName: 'Checkbox Selection',
                    }}
                    {...(firstLoad ? skeletonGridProps : {})}
                />
            )
        },
    ),
)

export default Datagrid

const GridCell: FC<{ children: ReactNode }> = ({ children }) => (
    <div className="MuiDataGrid-cellContent">{children}</div>
)

const defaultSelectionModel = []

export const datagridEmptyCell = <div className={globalClassNames.displayNone}>-</div>
