import { type FC } from 'react'

import { inject } from 'mobx-react'
import { Navigate, useLocation } from 'react-router-dom'

import { type Identifier } from 'appTypes'
import { ListBase, type ListBaseProps } from 'components'
import { type AuthStore } from 'core/auth'

export const ListBaseWithShop = inject('auth')((props: ListBaseProps & { auth: AuthStore }) => {
    return (
        <>
            <ListBase
                {...props}
                filter={{ ...props.filter, shopId: props.auth.defaultShopId }}
            />
            <AppendShop shopId={props.auth.defaultShopId} />
        </>
    )
}) as typeof ListBase

/**
 * If shop is not in the query params, append it
 *
 * Seems like setSort and setFilter removes the unknown params, so we need to append it each time
 */
const AppendShop: FC<{ shopId: Identifier }> = ({ shopId }) => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    if (params.get('shopId')) {
        return null
    }

    params.append('shopId', String(shopId))
    return <Navigate to={{ search: params.toString() }} />
}
