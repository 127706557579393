import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    ListBase,
    List,
    ListFilterValueInput,
    ResourceLinkButton,
    LinkArrowButton,
} from 'components'
import { type ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { type FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterNoResults from 'components/list/filter/ListFilterNoResults'
import { type AuthStore, ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import {
    expirationFields,
    expirationsResource,
    type ExpirationModel,
    type StatusKeys,
} from 'resources/expirations'
import { defaultDomicileFilter, unitResource } from 'resources/units'
import { SvgIcon, PageContent, Typography, BoxContainer } from 'ui'

import { ReportsHeader } from '../components'

const StatusRow = ({ status }: { status: StatusKeys }) => {
    const config = expirationFields.status.getConfig(status)
    return (
        <BoxContainer
            component="span"
            gap="8px"
        >
            <SvgIcon
                component={Icons.UnitExpirations}
                inheritViewBox
                sx={{
                    color: config.color,
                    width: '16px',
                    height: '16px',
                }}
            />
            <Typography
                variant="menuItem"
                color={config.color}
            >
                {config.text}
            </Typography>
        </BoxContainer>
    )
}

const defaultSort: SortPayload<ExpirationModel> = {
    field: expirationFields.status.source,
    order: 'DESC',
}
const navigateToExpirations = (unitData: ExpirationModel['unitData']) =>
    `/${unitResource.resource}/${unitData.id}/unit-expirations`
const navigateToUnit = (unitData: ExpirationModel['unitData']) =>
    `/${unitResource.resource}/${unitData.id}`

const columnsCfg: DatagridColumnsProps<ExpirationModel & { domicile: string }> = {
    checkboxSelection: false,
    constantColumns: {
        name: true,
    },
    resetColumns: { domicile: false },
    columns: [
        {
            field: expirationFields.name.source,
            headerName: expirationFields.name.label,
            renderCell: ({ row }) => {
                return (
                    <ResourceLinkButton to={navigateToExpirations(row.unitData)}>
                        {row.name}
                    </ResourceLinkButton>
                )
            },
        },
        {
            field: 'unit',
            headerName: 'Unit',
            renderCell: ({ row }) => {
                return (
                    <ResourceLinkButton to={navigateToUnit(row.unitData)}>
                        {row.unitData.number}
                    </ResourceLinkButton>
                )
            },
        },
        {
            field: 'domicile',
            headerName: 'Unit Domicile',
            valueGetter: ({ row }) => row.unitData.domicileData?.name,
            flex: 1,
        },
        {
            field: expirationFields.description.source,
            headerName: expirationFields.description.label,
        },
        {
            field: expirationFields.date.source,
            headerName: expirationFields.date.label,
            valueFormatter: (cell) => {
                return expirationFields.date.value(cell.value)
            },
        },
        {
            field: expirationFields.threshold.leftSource,
            headerName: expirationFields.threshold.leftLabel,
            renderCell: ({ row }) =>
                expirationFields.threshold.leftValue(row.remainingTillOverduePretty),
        },
        {
            field: expirationFields.status.source,
            headerName: expirationFields.status.label,
            renderCell: ({ value }) => {
                return <StatusRow status={value} />
            },
        },
        {
            field: expirationFields.files.source,
            headerName: expirationFields.files.label,
            renderCell: ({ row }) => <expirationFields.files.Value record={row} />,
        },
    ],
    actions: null,
}

const cardsCfg: CardListConfig<ExpirationModel> = {
    titleSource: (record) => record.name,
    titleLink: (record) => navigateToExpirations(record.unitData),
    defaultImage: null,
    details: [
        {
            source: 'unit',
            label: 'unit',
            render: (_, record) => (
                <ResourceLinkButton to={navigateToUnit(record.unitData)}>
                    {record.unitData.number}
                </ResourceLinkButton>
            ),
        },
        { source: expirationFields.description.source, label: expirationFields.description.label },
        {
            source: expirationFields.date.source,
            label: expirationFields.date.label,
            render: (value) => {
                return expirationFields.date.value(value)
            },
        },
        {
            source: expirationFields.threshold.leftSource,
            label: expirationFields.threshold.leftLabel,
            render: (_, data) =>
                expirationFields.threshold.leftValue(data.remainingTillOverduePretty),
        },
        {
            source: expirationFields.status.source,
            label: expirationFields.status.label,
            render: (value) => <StatusRow status={value} />,
        },
        {
            source: expirationFields.files.source,
            label: expirationFields.files.label,
            render: (_, data) => <expirationFields.files.Value record={data} />,
        },
    ],
    action: (record) => <LinkArrowButton path={navigateToExpirations(record.unitData)} />,
}
const sortCfg: ListSortContentProps<ExpirationModel> = {
    sortBy: [
        {
            id: expirationFields.name.source,
            label: expirationFields.name.label,
        },
        {
            id: 'unit',
            label: 'Unit',
        },
        {
            id: expirationFields.date.source,
            label: expirationFields.date.label,
        },
        {
            id: expirationFields.threshold.leftSource,
            label: expirationFields.threshold.leftLabel,
        },
        {
            id: expirationFields.status.source,
            label: expirationFields.status.label,
        },
        {
            id: expirationFields.files.source,
            label: expirationFields.files.label,
        },
    ],
}

const filtersCfg: FilterConfig<ExpirationModel & { domicile: string }> = {
    filters: [
        { id: 'unit', label: 'Unit' },
        {
            id: 'domicile',
            label: 'Unit Domicile',
        },
        {
            id: expirationFields.status.source,
            label: expirationFields.status.label,
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        const status = expirationFields.status.getConfig(record.id as StatusKeys)
                        return status ? status.text : record.id
                    }}
                />
            ),
        },
        {
            id: expirationFields.files.source,
            label: expirationFields.files.label,
        },
    ],
}

const UnitExpirations: FC = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        return (
            <ResourceContextProviderWithClearEffect value={expirationsResource}>
                <ListBase
                    sort={defaultSort}
                    filterDefaultValues={defaultDomicileFilter(auth.defaultShop)}
                >
                    <ReportsHeader>Unit Expirations</ReportsHeader>
                    <PageContent>
                        <List
                            renderNoResults={() => (
                                <ListFilterNoResults
                                    disableFilter
                                    subtitle={
                                        <>
                                            No results match your criteria.
                                            <br />
                                            Try modifying the search or filters.
                                        </>
                                    }
                                />
                            )}
                            sortCfg={sortCfg}
                            columnsCfg={columnsCfg}
                            cardsCfg={cardsCfg}
                            filtersCfg={filtersCfg}
                        />
                    </PageContent>
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default UnitExpirations
