import { type Identifier } from 'appTypes'
import { api, dispatchAction, updatedMessage, useNotify } from 'core'
import { pathJoin } from 'utils'

import { woResource } from '../../utils'

import { type WoActionKeys } from './types'

const useUpdateWoStatus = () => {
    const notify = useNotify()

    return async (id: Identifier, action: WoActionKeys, data?: any) => {
        await api.post(pathJoin(woResource.resource, id, action), data)
        dispatchAction(woResource.name, {
            name: 'statusChange',
        })
        notify(updatedMessage)
    }
}

export default useUpdateWoStatus
