import { type FC } from 'react'

import { inject } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core/auth'
import { Typography, type TypographyProps } from 'ui'
import { getInitials } from 'utils'

interface DefaultUserIconProps extends Pick<TypographyProps, 'fontSize'> {}

const UserIcon = inject('auth')(
    ({ auth, fontSize }: DefaultUserIconProps & { auth: AuthStore }) => {
        const initials = getInitials(auth.user?.name)
        return initials ? (
            <Typography
                color="primary.main"
                fontSize={fontSize}
                lineHeight="100%"
                fontWeight={500}
            >
                {initials}
            </Typography>
        ) : (
            <Icons.PersonOutlined
                sx={{
                    color: 'primary.main',
                }}
            />
        )
    },
) as FC<DefaultUserIconProps>

export default UserIcon
