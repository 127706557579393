import { BooleanInput } from 'react-admin'
import { useWatch } from 'react-hook-form'

import { EmailInput, TelInput, TextInput, UploadImage } from 'components'
import { maxLengthValidation, requiredValidation, validateMaxLength } from 'core'
import { addressFields } from 'resources/address'
import { renderOnBilling } from 'resources/billing'
import { companyFields } from 'resources/company'
import { countryFields, CountryInput } from 'resources/country'
import { stateFields, StateInput } from 'resources/state'
import { type BillingModel } from 'resourcesBase'
import { formControlLabelClasses, SectionTitleSmall } from 'ui'
import { capitalize } from 'utils'

export const sameAsBilling: keyof Pick<BillingModel['billingDetails'], 'sameAsBillingInfo'> =
    'sameAsBillingInfo'

export const companyNameValidation = [maxLengthValidation, requiredValidation]
export const dotNumberValidation = validateMaxLength(50)

const CompanyFormContent = ({ prefix = '' }: { prefix?: string }) => {
    const getName = (name: string) => (prefix === '' ? name : prefix + capitalize(name))

    return (
        <>
            <SectionTitleSmall>
                {prefix === '' ? 'Basic Information' : 'Billing Information'}
            </SectionTitleSmall>
            <TextInput
                source={getName('name')}
                label="Company Name"
                validate={companyNameValidation}
            />
            <TextInput
                source="dotNumber"
                label="DOT Number"
                validate={dotNumberValidation}
            />
            <TelInput
                source={getName('phone')}
                label="Phone Number"
                validate={requiredValidation}
            />
            <EmailInput source={getName('email')} />
            <UploadImage
                defaultIcon={<companyFields.avatar.Icon />}
                source={companyFields.avatar.source}
                buttonUploadText="UPLOAD LOGO"
                buttonChangeText="CHANGE LOGO"
            />

            <SectionTitleSmall>Address</SectionTitleSmall>
            <TextInput
                source={getName(addressFields.address1.source)}
                label={addressFields.address1.label}
                validate={maxLengthValidation}
            />
            <TextInput
                source={getName(addressFields.address2.source)}
                label={addressFields.address2.label}
                validate={maxLengthValidation}
            />
            <CountryInput
                source={getName(countryFields.self.source)}
                stateSource={getName(stateFields.self.source)}
                disabled
            />
            <StateInput
                source={getName(stateFields.self.source)}
                countrySource={getName(countryFields.self.source)}
            />
            <TextInput
                source={getName(addressFields.city.source)}
                label={addressFields.city.label}
                validate={maxLengthValidation}
            />
            <TextInput
                source={getName(addressFields.zipCode.source)}
                label={addressFields.zipCode.label}
            />
        </>
    )
}

const BillingFormContent = renderOnBilling(() => {
    const same = useWatch({ name: sameAsBilling })

    return (
        <>
            <BooleanInput
                source={sameAsBilling}
                label="Same as Billing Info?"
                sx={{
                    [`& .${formControlLabelClasses.root}`]: {
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        margin: 0,
                    },
                    [`& .${formControlLabelClasses.label}`]: {
                        typography: (theme) => theme.typography.h6,
                    },
                }}
            />
            {!same && <CompanyFormContent prefix="billing" />}
        </>
    )
})

const CompanyInfoDrawerForm = () => {
    return (
        <>
            <CompanyFormContent />
            <BillingFormContent />
        </>
    )
}

export default CompanyInfoDrawerForm
