import { globalClassNames } from 'lib'
import { type MemberModel } from 'resourcesBase'
import { DataAvatar, Typography, BoxContainer } from 'ui'
import { stringJoin } from 'utils'

import role from '../fields/role'

import MemberIcon from './MemberIcon'

export const MemberField = ({ record }: { record: MemberModel }) => {
    return (
        <BoxContainer
            justifyContent="space-between"
            overflow="hidden"
            width="100%"
        >
            <BoxContainer overflow="hidden">
                <DataAvatar
                    sx={{
                        width: '24px',
                        height: '24px',
                    }}
                    imageSrc={record.avatar}
                    avatarOpacity={1}
                    defaultImage={
                        <MemberIcon
                            fontSize="small"
                            color={(theme) => `${theme.palette.white} !important`}
                            member={record}
                        />
                    }
                />
                <Typography
                    px="8px"
                    className={globalClassNames.ellipsis}
                >
                    {stringJoin(record.name, record.email)}
                </Typography>
            </BoxContainer>
            <Typography
                color="text.secondary"
                whiteSpace="nowrap"
            >
                {role.value(record)}
            </Typography>
        </BoxContainer>
    )
}
