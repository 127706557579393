import { DateInput } from 'components'
import { requiredValidation } from 'core'
import { CustomerInput, CustomerPONumberInput, customerFields } from 'resources/customers'
import { PaymentTermInput, paymentTermFields } from 'resources/paymentTerms'
import { ShopInput, shopFields } from 'resources/shops'
import { UnitInput, unitFields } from 'resources/units'
import { WorkOrderInput } from 'resources/workOrders/components'
import woFields from 'resources/workOrders/fields'

import invoiceFields from '../fields'
import { type InvoiceModel } from '../types'
import { invoicesResource } from '../utils'

export interface InvoiceFormProps {
    isDisabled?: (source: keyof InvoiceModel) => boolean
    isHidden?: (source: keyof InvoiceModel) => boolean
}

const defaultDisabled = () => false

const InvoiceForm = ({ isDisabled = defaultDisabled, isHidden }: InvoiceFormProps) => {
    return (
        <>
            <ShopInput
                contextType={invoicesResource.name}
                validate={requiredValidation}
                disabled={isDisabled(shopFields.self.source)}
            />

            <CustomerInput
                required
                disabled={isDisabled(customerFields.self.source)}
            />

            {isHidden?.(woFields.self.source) ? null : (
                <WorkOrderInput disabled={isDisabled(woFields.self.source)} />
            )}

            {isHidden?.(unitFields.self.source) ? null : (
                <UnitInput
                    contextType={invoicesResource.name}
                    disabled={isDisabled(unitFields.self.source)}
                />
            )}

            <CustomerPONumberInput
                source={invoiceFields.poNumber.source}
                disabled={isDisabled(invoiceFields.poNumber.source)}
            />

            <DateInput
                source={invoiceFields.invoiceDate.source}
                label={invoiceFields.invoiceDate.label}
                validate={requiredValidation}
                defaultValue={new Date()}
                disabled={isDisabled(invoiceFields.invoiceDate.source)}
            />

            <PaymentTermInput disabled={isDisabled(paymentTermFields.self.source)} />
        </>
    )
}

export default InvoiceForm
