import { type ReactNode } from 'react'

import { type Identifier, type DataRecord } from 'appTypes'
import { CollapsibleInfoCard } from 'components/InfoCard'
import { ActionsMenu, type Action, type SingleAction } from 'core/actions'
import { globalClassNames, alpha, type CSSProperties, styled, type SxProps, withColor } from 'lib'
import {
    CardHeader,
    CardContent,
    cardHeaderClasses,
    type CardHeaderProps,
    Typography,
    BoxContainer,
    Stack,
    Box,
    avatarClasses,
} from 'ui'

import DataCardAvatar, { type DataCardAvatarProps } from './DataCardAvatar'
import DataCardSubheader from './DataCardSubheader'
import CardLink from './DataCardTitle'

const DataCardRow = styled('div')`
    padding-top: 10px;
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    height: 20px;

    &:first-of-type {
        padding-top: 16px;
    }
`
const DataCardRowContent = styled(Typography)`
    box-orient: vertical;
    min-width: 0px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & svg {
        vertical-align: middle;
        width: 15px;
    }
`
export interface DataCardDetail {
    label: string
    value: ReactNode
    key: string
}

export type DataCardDetailsProp = DataCardDetail[] | ReactNode
export interface DataCardProps<RecordType extends DataRecord = any>
    extends Pick<CardHeaderProps, 'subheaderTypographyProps'> {
    title: ReactNode
    subtitle: ReactNode
    disableTitleLink?: boolean
    defaultImage?: React.ReactElement | string
    image?: string
    details: DataCardDetailsProp
    id: Identifier
    actions?: Action<RecordType>
    actionsDisabled?: boolean
    record: DataRecord
    action?: SingleAction<RecordType>
    avatarColor?: DataCardAvatarProps['color'] | string
    avatarOpacity?: DataCardAvatarProps['avatarOpacity']
    styles?: {
        subheader?: SxProps
    }
    to?: string
    collapsibleContent?: ReactNode
    nonSelectable?: string
}
const ellipsisOverflow: CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
}

const DataCard = ({
    title,
    subtitle,
    image,
    defaultImage,
    details,
    to,
    id,
    actions,
    action,
    actionsDisabled,
    disableTitleLink,
    record,
    avatarColor,
    avatarOpacity,
    styles = {},
    collapsibleContent,
    nonSelectable,
    subheaderTypographyProps,
}: DataCardProps) => {
    const isDetailsArray = Array.isArray(details)
    const detailsHasLength = isDetailsArray && details?.length
    return (
        <CollapsibleInfoCard
            sameParent
            disableActiveState={!collapsibleContent}
        >
            <CardHeader
                subheaderTypographyProps={subheaderTypographyProps}
                className={globalClassNames.ellipsis}
                sx={(theme) => ({
                    padding: `0px 0px ${detailsHasLength ? 16 : 0}px 0px`,
                    borderBottom: detailsHasLength
                        ? `1px solid ${alpha(theme.palette.text.primary, 0.12)};`
                        : undefined,
                    [`& .${cardHeaderClasses.action}`]: {
                        margin: 0,
                    },
                    [`& .${cardHeaderClasses.content}`]: ellipsisOverflow,
                    [`& .${cardHeaderClasses.subheader}`]: {
                        ...ellipsisOverflow,
                        ...styles.subheader,
                    },
                    [`& .${cardHeaderClasses.avatar}`]: {
                        marginRight: 0,
                        [`& .${avatarClasses.root}:not(:empty)`]: {
                            marginRight: '16px',
                        },
                    },
                })}
                title={
                    <CardLink
                        id={id}
                        to={to}
                        disableLink={disableTitleLink}
                        lineHeight="1.1"
                        pb="3px"
                        sx={ellipsisOverflow}
                        color={disableTitleLink ? 'text.main' : 'primary'}
                    >
                        {title}
                    </CardLink>
                }
                subheader={<DataCardSubheader value={subtitle} />}
                avatar={
                    <DataCardAvatar
                        avatarOpacity={avatarOpacity}
                        imageSrc={image}
                        disableLink={disableTitleLink}
                        defaultImage={defaultImage || ''}
                        id={id}
                        color={avatarColor}
                        nonSelectable={nonSelectable}
                        to={to}
                    />
                }
                action={
                    (action && action(record)) ||
                    (actions && (
                        <ActionsMenu
                            title={actionsDisabled ? 'This action is disabled.' : undefined}
                            actions={actions}
                            record={record}
                            disabled={actionsDisabled}
                        />
                    ))
                }
            />
            <CardContent
                sx={{
                    '&:last-child': {
                        padding: 0,
                    },
                }}
            >
                {isDetailsArray ? (
                    <>
                        {details.map((detail) => (
                            <DataCardRow key={detail.key}>
                                <Box className={globalClassNames.ellipsis}>
                                    <Typography
                                        component="div"
                                        color={withColor('text.disabled')}
                                        variant="tooltip"
                                        className={globalClassNames.ellipsis}
                                        sx={{
                                            textTransform: 'uppercase',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {detail.label}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    overflow="hidden"
                                >
                                    <DataCardRowContent
                                        color={withColor('text.primary')}
                                        variant="body2"
                                        // @ts-ignore
                                        component="div"
                                        sx={{
                                            '&:empty::before': {
                                                content: '"-"',
                                                display: 'inline-block',
                                            },
                                        }}
                                    >
                                        {detail.value}
                                    </DataCardRowContent>
                                </Box>
                            </DataCardRow>
                        ))}
                    </>
                ) : (
                    <BoxContainer
                        sx={{
                            textTransform: 'uppercase',
                            paddingTop: 34,
                            paddingBottom: 20,
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            color={withColor('text.disabled')}
                            variant="tooltip"
                        >
                            {details}
                        </Typography>
                    </BoxContainer>
                )}
                {collapsibleContent ? <Stack pt="10px">{collapsibleContent}</Stack> : null}
            </CardContent>
        </CollapsibleInfoCard>
    )
}
export default DataCard
