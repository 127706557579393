import { useFormContext } from 'react-hook-form'

import { TextInput, costMaskParams, formatMoney } from 'components'
import { maxLengthValidation, requiredValidation, validateMaxLength } from 'core'
import { shopFields } from 'resources/shops'

import { DefaultFormInput } from '../../components'
import { nameLabel, nameSource } from '../fields/laborRateName/name'
import { rate } from '../fields/rate'

const nameValidation = [requiredValidation, maxLengthValidation]
const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

export const PTLaborForm = () => {
    return (
        <>
            <TextInput
                source={nameSource}
                label={nameLabel}
                validate={nameValidation}
            />
            <RateInput />
            <DefaultFormInput />
        </>
    )
}

const RateInput = () => {
    const { getValues, watch } = useFormContext()
    const shopRate = getValues(shopFields.hourlyLaborRate.source)
    const value = watch(rate.source) || getValues(rate.source)

    return (
        <TextInput
            source={rate.source}
            label={rate.label}
            helperText={
                value < shopRate ? (
                    <>Current shop's hourly labor rate is {formatMoney(shopRate)}</>
                ) : undefined
            }
            validate={qtyAndCostValidator}
            {...costMaskParams}
        />
    )
}
