import Icons from 'assets/icons'
import { IconBox } from 'ui'

const ArchivedUnitBadge = () => {
    return (
        <IconBox
            title="Archived"
            sx={{ ml: '6px', minHeight: '20px', minWidth: '20px' }}
        >
            <Icons.Unarchive
                sx={(theme) => ({
                    color: `${theme.palette.text.disabled} !important`,
                })}
            />
        </IconBox>
    )
}

export default ArchivedUnitBadge
