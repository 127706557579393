import { type ReactElement, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { TextInput, EmailInput, useUtilityDrawerContext } from 'components'
import { requiredValidation } from 'core'
import { membersResource } from 'resources/members'
import { RoleInput } from 'resources/roles'
import { ShopInput } from 'resources/shops'

const ShopsInputWrapper = ({ children }: { children: ReactElement }) => {
    const { watch, setValue } = useFormContext()
    const shops = watch('shops')
    const isWrongData = typeof shops?.[0] === 'object'

    useEffect(() => {
        if (isWrongData) {
            const data = shops.map(({ id }) => id)
            setTimeout(() => setValue('shops', data), 1)
        }
    }, [isWrongData])

    if (isWrongData) {
        return null
    }
    return children
}
const MemberForm = () => {
    const { extraArgs } = useUtilityDrawerContext()
    return (
        <>
            <EmailInput
                label="Email Address"
                disabled
            />

            <TextInput
                source="name"
                label="Name"
                disabled
            />
            <RoleInput required />
            <ShopsInputWrapper>
                <ShopInput
                    contextType={membersResource.name}
                    contextId={extraArgs.id}
                    source="shops"
                    multiple
                    validate={requiredValidation}
                    defaultValue={false}
                />
            </ShopsInputWrapper>
        </>
    )
}

export default MemberForm
