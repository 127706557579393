import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { roundTop3WidgetData } from '../utils'

import { CostPerCategoryChart, CostPerCategoryClarification, TopCategories } from './components'
import includeUncategorized from './includeUncategorized'
import {
    type ResponseType,
    type listType,
    type QueryResponse,
    type maxTotalCategoriesType,
} from './types'

const description = `Drill down further into maintenance costs,
 by exploring the VMRS repair systems which account for most of the expenses.`
let validData = false
let hasData = false
const CostPerCategoryWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-category'),
        sideEffect: (data) => {
            const { maxTotalCategories, ...ratioData } = data as unknown as ResponseType
            const listWithUncategorized = includeUncategorized<maxTotalCategoriesType>(
                ratioData,
                maxTotalCategories,
            )

            const { listData, positiveDataTotal, ...listExtras } = roundTop3WidgetData(
                listWithUncategorized,
                { allowNegative: true },
            )

            hasData = typeof ratioData.total === 'number'
            validData =
                ratioData.total >= 0 &&
                typeof listExtras.top3Percentage === 'number' &&
                listExtras.top3Percentage >= 0
            const list: listType = {
                data: listData,
                ...listExtras,
                sumOfExcludedCategories: positiveDataTotal - listExtras.top3Total,
            }
            return {
                ...ratioData,
                ...listExtras,
                list,
            }
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Top 3 Categories Account for',
                label: hasData ? (validData ? `${list.top3Percentage}%` : 'N/A') : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        of all Maintenance Costs
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerCategoryChart
                        list={list}
                        disabled={!hasData || !validData}
                        total={ratioData.total}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <TopCategories
                        list={list}
                        description={description}
                    />
                ),
            }}
            separator="Top 3 Categories"
            link={createLink(reportsUrls.costPerCategory)}
        />
    )
}
export default CostPerCategoryWidget
