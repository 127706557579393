import { useListContext, useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ActionsMenu } from 'core/actions'
import { type VendorModel } from 'resources/vendors'
import { GridContainer, GridItem } from 'ui'

import { type ContactModel } from '../types'

import { ContactCard } from './ContactCard'
import { ContactDrawerToggler } from './ContactDrawerToggler'
import { ContactsFallback } from './ContactResource'
import { ContactsEmpty } from './ContactsEmpty'
import { ContactsHeader } from './ContactsHeader'

export const ContactsList = () => {
    const { data = [], total, isLoading } = useListContext<ContactModel>()
    const vendor = useRecordContext<VendorModel>() || ({} as VendorModel)

    if (isLoading) {
        return <ContactsFallback />
    }

    return (
        <>
            <ContactsHeader
                actions={
                    <ActionsMenu
                        record={vendor}
                        actions={(params, { children }) => [
                            <ContactDrawerToggler
                                key="add-contact"
                                children={({ onClick }) =>
                                    children({
                                        Icon: Icons.Add,
                                        title: 'Add Contact Person',
                                        onClick,
                                    })
                                }
                            />,
                        ]}
                    />
                }
            />
            {total ? (
                <GridContainer spacing={{ xs: 3, sm: 6 }}>
                    <>
                        {data.map((contact) => (
                            <GridItem
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                                key={contact.id}
                            >
                                <ContactCard contact={contact} />
                            </GridItem>
                        ))}
                    </>
                </GridContainer>
            ) : (
                <ContactsEmpty />
            )}
        </>
    )
}
