import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const getPtLaborResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.LABOR),
    name: 'pricing-tiers-labor',
})

export const ptLaborPreferencesResource: ResourceType = {
    resource: 'pricing-tiers-labor',
    name: 'pricing-tiers-labor',
}

export const ptDeleteTitleOnDisabled = 'Default and system created tiers cannot be deleted.'
