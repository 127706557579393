import { type ReactNode, type FC } from 'react'

import { inject } from 'mobx-react'
import { ListBase, type ListControllerProps } from 'react-admin'

import { type AuthStore } from 'core/auth'
import { type ResourceType, useResource } from 'core/resource'

import ListPurify from './ListPurify'
import { useListView, ListViewMode } from './ListViewContext'

interface Props extends ListControllerProps {
    preferencesResource?: ResourceType
    auth?: AuthStore
    isLoading?: boolean
    children: ReactNode
}

const ListBaseViewMode = inject('auth')(
    ({
        children,
        preferencesResource,
        auth,
        isLoading,
        queryOptions,
        ...rest
    }: Props & { auth: AuthStore }) => {
        const { viewMode } = useListView()
        const { name } = useResource(preferencesResource)
        const listPerPage = auth.preferences.resources[name]?.perPage || 10

        return (
            <ListBase
                {...rest}
                queryOptions={{
                    enabled: !isLoading,
                    ...queryOptions,
                }}
                {
                    ...(viewMode === ListViewMode.list
                        ? { perPage: listPerPage }
                        : { page: 1, perPage: 100 }) // limit of free version of x-data-grid
                }
            >
                {children}
                <ListPurify perPage={listPerPage} />
            </ListBase>
        )
    },
) as FC<Props>

export default ListBaseViewMode
