import { ListBase } from 'react-admin'

import { PageContent } from 'ui'

import { ContactResource } from './ContactResource'
import { ContactsList } from './ContactsList'

export const ContactsListPage = ({ resourceName }: { resourceName: string }) => {
    return (
        <PageContent>
            <ContactResource resourceName={resourceName}>
                <ListBase perPage={100}>
                    <ContactsList />
                </ListBase>
            </ContactResource>
        </PageContent>
    )
}
