import {
    type KeyboardEvent,
    type MouseEvent,
    type ReactElement,
    type ReactNode,
    type MutableRefObject,
} from 'react'

import { useSetBlocker } from 'core/blocker'
import { useMediaQuery } from 'lib'
import { Divider, type SwipeableDrawerProps, paperClasses, Typography, StyledElement } from 'ui'

import { DrawerContentWrapper, StyledSwipeableDrawer, UtilityDrawerPuller } from './styled'

export interface ListUtilityDrawerInterface {
    open: boolean
    setOpen: (open: boolean, extraArgs?: any) => void
    onClose: () => void
    onForceClose?: () => void
    anchorBottomHeightSize?: 'big' | 'small'
    renderWrapper?: (props: RenderWrapperProps) => ReactElement
    title: ReactElement | string
    renderContent: () => ReactElement
    renderAboveFooter?: () => ReactNode
    renderBelowHeader?: () => ReactNode
    renderTopRight?: () => ReactElement | null
    renderBottomLeft: () => ReactElement
    renderBottomRight: () => ReactElement
    sx?: SwipeableDrawerProps['sx']
    drawerRef?: MutableRefObject<HTMLDivElement>
}

export interface RenderWrapperProps {
    children: ReactNode
}

const defaultRenderWrapper: ListUtilityDrawerInterface['renderWrapper'] = (props) => (
    <div {...props} />
)

const ListUtilityDrawer = ({
    open,
    setOpen,
    onClose,
    onForceClose,
    anchorBottomHeightSize,
    renderWrapper = defaultRenderWrapper,
    title,
    renderContent,
    renderAboveFooter,
    renderTopRight,
    renderBottomLeft,
    renderBottomRight,
    sx,
    renderBelowHeader,
    drawerRef,
}: ListUtilityDrawerInterface) => {
    const isExtraSmall = useMediaQuery((theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        setOpen(open)
    }

    const anchor = isExtraSmall ? 'bottom' : 'right'

    const content = (
        <>
            {anchor === 'bottom' && <UtilityDrawerPuller />}
            <StyledElement
                className="header"
                sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                }}
            >
                <Typography
                    className="left"
                    variant="h6"
                    sx={{
                        display: 'block !important',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {title}
                </Typography>
                <div className="right">{renderTopRight && renderTopRight()}</div>
            </StyledElement>
            <Divider />
            {renderBelowHeader ? <div className="below-header">{renderBelowHeader()}</div> : null}
            <div className="content">{renderContent()}</div>
            {renderAboveFooter?.()}
            <Divider />
            <div className="footer">
                <div className="left">{renderBottomLeft()}</div>
                <div className="right">{renderBottomRight()}</div>
            </div>
        </>
    )

    useSetBlocker(
        {
            close: onForceClose || onClose,
        },
        {
            isOpen: open,
        },
    )

    return (
        <StyledSwipeableDrawer
            ref={drawerRef}
            swipeAreaWidth={0}
            {
                // XXX could be more modular if these pixels are prop, but will be unpleasant to use
                // if need for modularity arises => create wrapper component that interfaces anchorBottomHeightSize
                ...(anchor === 'bottom'
                    ? {
                          sx: {
                              [`& > .${paperClasses.root}`]: {
                                  height: anchorBottomHeightSize === 'small' ? 334 : 654,
                              },
                          },
                      }
                    : {})
            }
            SwipeAreaProps={{ display: 'none' }}
            anchor={anchor}
            open={open}
            onClose={(event: KeyboardEvent | MouseEvent) => {
                toggleDrawer(false)(event)
                onClose?.()
            }}
            onOpen={toggleDrawer(true)}
            sx={sx}
        >
            <DrawerContentWrapper>
                {renderWrapper({
                    children: content,
                })}
            </DrawerContentWrapper>
        </StyledSwipeableDrawer>
    )
}

export default ListUtilityDrawer
