import { type Serializer, emptyOptionValue, serialize } from 'core'
import { type ShopModel } from 'resourcesBase'

import { type UnitModel } from './types'

export const isUnitArchived = (unit: Pick<UnitModel, 'archived'>) => Boolean(unit?.archived)

export const unitAspects = {
    totals: 'totals',
    costPerMeter: 'cost_per_meter',
    downtime: 'downtime',
}

export const unitSerializer: Serializer<UnitModel> = [
    { name: 'customer', parse: ['emptyToNull', 'disableOnEdit'] },
    'number',
    'name',
    'vin',
    'serialNumber',
    'licensePlate',
    'model',
    'domicile',
    { name: 'modelYear', parse: 'year' },
    'engineModel',
    { name: 'engineHp', parse: 'number' },
    { name: 'engineVmrsManufacturer', parse: 'emptyToNull' },
    'transmissionModel',
    { name: 'transmissionGears', parse: 'number' },
    'color',
    'tireSize',
    { name: 'status', parse: 'disableOnEdit' },
    { name: 'photo', parse: 'file' },
    { name: 'vmrsEquipmentCategory', parse: 'emptyToNull' },
    { name: 'vmrsManufacturer', parse: 'emptyToNull' },
    { name: 'transmissionVmrsManufacturer', parse: 'emptyToNull' },
    { name: 'tags', parse: 'emptyToNull' },
    {
        name: 'body',
        parse: (values, data) => {
            if (!data[equipmentHasBodySource]) {
                return undefined
            }

            return serialize(values, [
                { name: 'vmrsType', parse: 'emptyToNull' },
                { name: 'vmrsMaterial', parse: 'emptyToNull' },
                { name: 'vmrsManufacturer', parse: 'emptyToNull' },
                'serialNumber',
                'model',
                { name: 'modelYear', parse: 'year' },
            ])
        },
    },
    {
        name: 'costCenter',
        parse: ['emptyToNull', (value, data) => (data.hasOpenWorkOrders ? undefined : value)],
    },
    {
        name: 'division',
        parse: ['emptyToNull', (value, data) => (data.hasOpenWorkOrders ? undefined : value)],
    },
]

export const equipmentHasBodySource = 'equipmentHasBody' as keyof UnitModel

export const defaultDomicileFilter = (shop: ShopModel | null) =>
    shop ? { domicile: [shop.name, emptyOptionValue] } : undefined
