import {
    InfoCard,
    InfoCardDetails,
    InfoCardHeader as CardHeader,
    phoneMaskResolver,
} from 'components'
import { useFlags, globalClassNames, styled } from 'lib'
import { MemberIcon } from 'resources/members'
import { cardHeaderClasses, Typography, StyledElement, DataAvatar } from 'ui'

import { type ContactModel } from '../types'
import { contactActions } from '../utils'

import { ContactDrawerToggler } from './ContactDrawerToggler'

interface ContactCardProps {
    contact: ContactModel
}

const InfoCardHeader = styled(CardHeader)`
    .${cardHeaderClasses.content} {
        overflow: hidden;
    }
`
export const ContactCard = ({ contact }: ContactCardProps) => {
    const flags = useFlags()
    return (
        <InfoCard>
            <InfoCardHeader
                record={{ contact, flags }}
                avatar={
                    <ContactDrawerToggler id={contact.id}>
                        {({ onClick }) => (
                            <DataAvatar
                                sx={{ cursor: 'pointer' }}
                                defaultImage={<MemberIcon member={contact} />}
                                onClick={onClick}
                            />
                        )}
                    </ContactDrawerToggler>
                }
                actions={contactActions}
                title={
                    <ContactDrawerToggler id={contact.id}>
                        {({ onClick }) => (
                            <StyledElement
                                onClick={onClick}
                                sx={{ width: 'fit-content' }}
                            >
                                <Typography
                                    sx={{
                                        display: 'block',
                                        color: 'primary.main',
                                        cursor: 'pointer',
                                        width: 'fit-content',
                                        fontSize: 20,
                                        fontWeight: 500,
                                    }}
                                    className={globalClassNames.ellipsis}
                                >
                                    {contact.name}
                                </Typography>
                            </StyledElement>
                        )}
                    </ContactDrawerToggler>
                }
            />
            <InfoCardDetails<ContactModel>
                details={[
                    {
                        label: 'Email',
                        source: 'email',
                    },
                    {
                        label: 'Phone',
                        source: ({ phone }) => phoneMaskResolver(phone),
                    },
                ]}
                propRecord={contact}
            />
        </InfoCard>
    )
}
