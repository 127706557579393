import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'closedDate' as const satisfies keyof InvoiceModel
const label = 'Closed on'

const value = (record: Pick<InvoiceModel, 'invoiceDate'>) =>
    formatDate(record.invoiceDate, (format) => format.fullDateTime)

const closedDate = {
    source,
    label,
    value,
}

export default closedDate
