import { type FC } from 'react'

import { useListContext, useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { SliderView, BoxLink } from 'components'
import { type Action, ActionsMenu } from 'core/actions'
import { Section } from 'ui'

import { type ContactModel } from '../types'

import { ContactCard } from './ContactCard'
import { ContactDrawerToggler } from './ContactDrawerToggler'
import { ContactsFallback } from './ContactResource'
import { ContactsEmpty } from './ContactsEmpty'
import { ContactsHeader } from './ContactsHeader'

const contactsActions: Action<ContactModel> = (params, { children }) => [
    <ContactDrawerToggler
        key="add-contact"
        children={({ onClick }) =>
            children({ Icon: Icons.Add, title: 'Add Contact Person', onClick })
        }
    />,
    children({
        Icon: Icons.GridView,
        title: 'See All',
        key: 'see-all',
        to: 'contact-persons',
    }),
]

export const ContactsSimpleListSection: FC = () => {
    const { data = [], total, isLoading } = useListContext<ContactModel>()
    const vendor = useRecordContext() || {}

    let content
    if (isLoading) {
        return <ContactsFallback />
    }

    if (total) {
        const items = data.map((contact) => <ContactCard contact={contact} />)
        if (total >= 4) {
            items.push(
                <BoxLink
                    to="contact-persons"
                    sx={{ height: '142px' }}
                    label="SEE ALL CONTACTS"
                    icon={<Icons.ArrowForward />}
                />,
            )
        }
        content = <SliderView items={items} />
    } else {
        content = <ContactsEmpty />
    }

    return (
        <Section>
            <ContactsHeader
                actions={
                    <ActionsMenu
                        record={vendor}
                        actions={contactsActions}
                    />
                }
            />
            {content}
        </Section>
    )
}
