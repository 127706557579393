import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { ListBase } from 'react-admin'

import { type AuthStore } from 'core'
import WorkOrderSimpleList from 'pages/WorkOrders/SimpleList/WorkOrderSimpleList'
import { ContactResource, ContactsSimpleListSection } from 'resources/contacts'
import { type CustomerModel } from 'resources/customers'
import { shopDefaultFilter } from 'resources/shops'
import { FlexReverse, PageContent } from 'ui'

import { customerContactsResourceName } from '../config'

import { CustomerDetails } from './components'

export const CustomerOverview: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        return (
            <PageContent>
                <FlexReverse>
                    <CustomerDetails />
                    <ContactResource resourceName={customerContactsResourceName}>
                        <ListBase perPage={4}>
                            <ContactsSimpleListSection />
                        </ListBase>
                    </ContactResource>
                    <WorkOrderSimpleList<CustomerModel>
                        createFormInitialValues={(record) => ({})}
                        filter={(record) => ({
                            ...shopDefaultFilter(auth.defaultShop),
                            customer: record?.name,
                        })}
                        createFormConfig={(record) => ({
                            unit: {
                                defaultFilter: {
                                    customerId: record?.id,
                                },
                            },
                        })}
                    />
                </FlexReverse>
            </PageContent>
        )
    }),
)
