import {
    ArrayControllerBox,
    ArrayControllerElements,
    SelectInput,
    EmailInput,
    useArrayControllerElementContext,
} from 'components'
import { requiredValidation } from 'core'
import { membersResource } from 'resources/members'
import { useGetRoles, type Role } from 'resources/roles'
import { ShopInput } from 'resources/shops'
import { formHelperTextClasses } from 'ui'

const MemberInviteFormContent = () => {
    const roles = useGetRoles()

    if (roles.isError || roles.isFetching || !roles.data?.length) {
        return null
    }

    return (
        <ArrayControllerElements<{ id: string }> itemKey={({ item }) => item.id}>
            <ArrayControllerBox title="New User">
                <MemberInviteFormElement roles={roles.data} />
            </ArrayControllerBox>
        </ArrayControllerElements>
    )
}

const MemberInviteFormElement = ({ roles }: { roles: Role[] }) => {
    const { index } = useArrayControllerElementContext()
    return (
        <>
            <EmailInput
                source={`data.${index}.email`}
                required
                label="Email Address"
            />

            <ShopInput
                contextType={membersResource.name}
                source={`data.${index}.shops`}
                multiple
                validate={requiredValidation}
                defaultValue={false}
            />
            <SelectInput
                helperText={false}
                source={`data.${index}.role`}
                choices={roles}
                validate={requiredValidation}
                optionText="label"
                label="Role"
                sx={{
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                disableEmptyValue
                clearable={false}
                defaultValue={roles[0].id}
            />
        </>
    )
}

export default MemberInviteFormContent
