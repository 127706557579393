import { inject, observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'

import { type Identifier, type Optional } from 'appTypes'
import Icons from 'assets/icons'
import { type DialogSelectorProps, DialogSelector } from 'components'
import { type AuthStore } from 'core/auth'
import { Button } from 'ui'

import { type PartModel } from '../types'
import { partsResource } from '../utils'

import { PartDrawerToggler } from './PartDrawerToggler'
import { VendorPartDrawerToggler } from './VendorPartDrawerToggler'

interface Props<RecordType extends PartModel = PartModel>
    extends Optional<
        Omit<
            DialogSelectorProps<RecordType>,
            | 'titleSource'
            | 'defaultSelectorProps'
            | 'filter'
            | 'itemPrimary'
            | 'itemSecondary'
            | 'renderNoResults'
            | 'noResults'
            | 'renderAboveList'
            | 'defaultSelectorValueSource'
            | 'defaultSelectorHelperText'
            | 'queryOptions'
            | 'defaultFilter'
        >,
        'source' | 'reference'
    > {
    successMessage?: string
    disableCreateOption?: boolean
    inputTitle?: string
    contextType: string
    contextId?: Identifier
    type?: 'vendor' | 'inventory' | 'internal'
}

export const PartInput = inject('auth')(
    observer(
        ({
            reference = partsResource.resource,
            successMessage,
            disableCreateOption,
            inputTitle,
            auth,
            contextType,
            contextId,
            type = 'internal',
            required = true,
            ...props
        }: Props & { auth: AuthStore }) => {
            const { getValues } = useFormContext()

            const _inventoryParts = auth.companySettings.hasInventory && type === 'inventory'
            const { title, noResultsTitle } = getTitles(type, _inventoryParts)
            return (
                <DialogSelector<PartModel>
                    source="part"
                    queryOptions={
                        contextType
                            ? () => ({ meta: { query: { contextType, contextId } } })
                            : undefined
                    }
                    titleSource={() => title}
                    reference={reference}
                    defaultSelectorProps={{
                        label: inputTitle || 'Part No',
                    }}
                    filter={{
                        search: {
                            placeholder: 'Search by Part Number or Description',
                        },
                    }}
                    required={required}
                    itemPrimary="number"
                    itemSecondary={(record) => [
                        record.description,
                        // TODO: why part selector can't use the standart formatting for ck33?
                        `VMRS: ${record.componentData.code} - ${record.componentData.text}`,
                        record.manufacturerData ? record.manufacturerData.description : '',
                    ]}
                    noResults={({ searchValue }) => {
                        if (!searchValue) {
                            return {
                                title: noResultsTitle,
                                text: _inventoryParts ? (
                                    <>
                                        Add parts by going
                                        <br />
                                        to the "Inventory" page
                                    </>
                                ) : (
                                    <>
                                        Add parts by clicking
                                        <br />
                                        the "Create New Part" button
                                    </>
                                ),
                                image: (images) => images.listEmpty,
                                imageWidth: '180px',
                            }
                        }
                    }}
                    renderNoResults={({ filterValues }) =>
                        filterValues.recentlyUsed ? null : undefined
                    }
                    renderAboveList={
                        _inventoryParts
                            ? null
                            : ({ onSelect, control }) =>
                                  type === 'vendor' ? (
                                      <VendorPartDrawerToggler
                                          shortSuccessMessage
                                          onSuccess={(record) => {
                                              onSelect(record.id)
                                              control.refetch()
                                          }}
                                          children={({ onClick }) =>
                                              control.isFetching ? null : (
                                                  <Button
                                                      startIcon={<Icons.Add />}
                                                      variant="contained"
                                                      onClick={onClick}
                                                      sx={{ mt: '20px' }}
                                                  >
                                                      Create New Part
                                                  </Button>
                                              )
                                          }
                                      />
                                  ) : (
                                      <PartDrawerToggler
                                          shortSuccessMessage
                                          onSuccess={(record) => {
                                              onSelect(record.id)
                                              control.refetch()
                                          }}
                                          children={({ onClick }) =>
                                              control.isFetching ? null : (
                                                  <Button
                                                      startIcon={<Icons.Add />}
                                                      variant="contained"
                                                      onClick={onClick}
                                                      sx={{ mt: '20px' }}
                                                  >
                                                      Create New Part
                                                  </Button>
                                              )
                                          }
                                      />
                                  )
                    }
                    defaultSelectorValueSource={({ selected, value }) => {
                        const id = getValues('id')
                        if (id && !value) {
                            return '(Deleted) ' + getValues('partNumber')
                        }

                        return selected?.number
                    }}
                    defaultSelectorHelperText={({ value }) => {
                        const id = getValues('id')
                        if (id && !value) {
                            return 'This part has been deleted from the catalog.'
                        }

                        return null
                    }}
                    {...props}
                />
            )
        },
    ),
) as <RecordType extends PartModel = PartModel>(props: Props<RecordType>) => JSX.Element

const getTitles = (type: Props['type'], inventoryParts: boolean) => {
    if (type === 'vendor') {
        return { title: 'Select from Vendor Parts', noResultsTitle: 'No Vendor Parts' }
    }
    if (inventoryParts) {
        return { title: 'Select from Inventory', noResultsTitle: 'No Inventory Parts' }
    }
    return { title: 'Select from Catalog', noResultsTitle: 'No Catalog Parts' }
}
