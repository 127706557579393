import { stringify } from 'query-string'
import { useListContext } from 'react-admin'

import { type DataRecord } from 'appTypes'
import Icons from 'assets/icons'
import { getOrderingQuery } from 'core/data'
import { useFileDownload } from 'core/files'
import { Tooltip, BoxContainer, SvgIcon, IconButton } from 'ui'

export interface ExportButtonProps<RecordType extends DataRecord = any> {
    fileName?: string
    visibleColsRef: { current: string[][] }
    filters?: {
        [key in keyof RecordType]?: string
    }
}

const ExportButton = <RecordType extends DataRecord = any>({
    fileName,
    visibleColsRef,
    filters,
}: ExportButtonProps<RecordType>) => {
    const listContext = useListContext()
    const fileDownload = useFileDownload()

    const downloadCSV = () => {
        const resource = listContext.resource
        const query = {
            ...listContext.filterValues,
            ...listContext.filter,
            csvFields: visibleColsRef.current || [],
            ...getOrderingQuery(listContext.sort),
            ...filters,
        }

        fileDownload({
            filename: fileName || listContext.resource,
            type: 'csv',
            headers: { accept: 'text/csv' },
            url: `${resource}?${stringify(query)}`,
        })
    }
    return (
        <Tooltip title="Export">
            <BoxContainer>
                <IconButton
                    aria-label="Export List"
                    size="small"
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    onClick={downloadCSV}
                >
                    <SvgIcon
                        component={Icons.Export}
                        sx={{
                            width: { xs: '20px', sm: '24px' },
                            height: { xs: '20px', sm: '24px' },
                        }}
                    />
                </IconButton>
            </BoxContainer>
        </Tooltip>
    )
}

export default ExportButton
