import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'woCompleted' as const satisfies keyof InvoiceModel
const label = 'WO Completed on'

interface Data extends Pick<InvoiceModel, 'woCompleted'> {}

const value = (record: Data) => formatDate(record.woCompleted, (format) => format.fullDateTime)

const woCompletedDate = {
    source,
    label,
    value,
}

export default woCompletedDate
