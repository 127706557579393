import { defaultStatuses, options, type StatusConfig } from 'resources/common'

import { InvoiceStatusKeys, type InvoiceModel } from '../types'

const source = 'status' as const satisfies keyof InvoiceModel
const label = 'Status'

const getConfig = options.createConfig<StatusConfig<InvoiceStatusKeys>>([
    { ...defaultStatuses.open, id: InvoiceStatusKeys.OPEN },
    { ...defaultStatuses.closed, id: InvoiceStatusKeys.CLOSED },
    { ...defaultStatuses.canceled, id: InvoiceStatusKeys.CANCELED },
])

const value = options.makeIconValue(getConfig)

const status = {
    source,
    label,
    value: (data: Pick<InvoiceModel, 'status'>) => value(data?.status),
    getConfig,
}

export default status
