import { type FC } from 'react'

import { type CustomerModel } from '../types'

interface Props {
    record: Pick<CustomerModel, 'name'>
}

const Value: FC<Props> = ({ record }) => {
    if (!record) {
        return null
    }
    return <>{record.name}</>
}

const source: keyof CustomerModel = 'name'

const Name = {
    source,
    label: 'Customer Name',
    Value,
}

export default Name
