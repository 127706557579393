import { updatedMessage } from 'core/data'
import { useErrorHandler } from 'core/errors'
import { type NotificationPayload, useNotify } from 'core/notifications'

export interface UseSubmitExtraArgs<T = any, R = any> {
    successMessage?:
        | ((params: {
              response: T
              formData: R
              defaultMessages: {
                  created: string
                  updated: string
              }
          }) => NotificationPayload)
        | NotificationPayload
}

export const useSubmit = <ReqData = any, RespData = any>(
    submit: (formData: ReqData) => Promise<RespData>,
    { successMessage }: UseSubmitExtraArgs<RespData, ReqData> = {},
) => {
    const notify = useNotify()
    const errorHandler = useErrorHandler({
        anonymous: true,
    })
    return async (formData: ReqData) => {
        try {
            const response = await submit(formData)
            const message =
                typeof successMessage === 'function'
                    ? successMessage({
                          response,
                          formData,
                          defaultMessages: {
                              created: 'Successfully created',
                              updated: updatedMessage,
                          },
                      })
                    : successMessage
            if (message) {
                notify(message)
            }
        } catch (error) {
            return await errorHandler(error).then((isErrorHandled) => {
                return isErrorHandled ? {} : error
            })
        }
    }
}
