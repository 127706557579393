import { useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { useListController, useRecordContext } from 'react-admin'

import { type DataRecord, type SvgIconElement } from 'appTypes'
import { emptyOptionValue, useActionsMenuContext, type AuthStore } from 'core'
import { shopDefaultFilter } from 'resources/shops'
import { type UnitModel } from 'resources/units'
import { type VendorModel } from 'resources/vendors'
import { woFields, woResource } from 'resources/workOrders'
import { Icon, Stack, BoxContainer, Typography } from 'ui'

import { type WorkOrderCreateMenuProps } from './AddWorkOrderMenu'
import useAttachWO from './UseWO/useAttachWO'

interface WORowProps {
    icon: SvgIconElement
    title: string
    onClick?: () => void
    vendorData?: VendorModel
    disabled?: boolean
}
const WORow = ({ icon, onClick, title, vendorData, disabled }: WORowProps) => {
    return (
        <BoxContainer gap="18px">
            <Icon
                component={icon}
                sx={{
                    color: (theme) =>
                        disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                    opacity: 0.54,
                    height: '20px',
                    width: '20px',
                }}
            />
            <BoxContainer
                gap="4px"
                overflow="hidden"
                onClick={onClick}
            >
                <Typography
                    whiteSpace="nowrap"
                    variant="menuItem"
                    sx={{
                        cursor: disabled ? 'default' : 'pointer',
                        color: (theme) =>
                            disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                    }}
                >
                    {title}
                </Typography>
                {vendorData ? (
                    <Typography
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        variant="menuItem"
                        color="text.disabled"
                        sx={{ cursor: 'pointer' }}
                    >
                        {vendorData.name}
                    </Typography>
                ) : null}
            </BoxContainer>
        </BoxContainer>
    )
}
const ExistingWOSection = inject('auth')(
    observer(
        <ParentType extends DataRecord = any>({
            parentRecord,
            cardMenuClose,
            options,
            unit,
            auth,
        }: Pick<WorkOrderCreateMenuProps<ParentType>, 'parentRecord' | 'options'> & {
            cardMenuClose?: () => void
            unit?: UnitModel
        } & { auth?: AuthStore }) => {
            const { close } = useActionsMenuContext()
            const woUse = useAttachWO()
            const record = useRecordContext<UnitModel>({ record: unit })
            const listController = useListController({
                resource: woResource.resource,
                filter: {
                    status: ['OPEN'],
                    unit: [record?.number],
                    customer: record?.customerData?.name || emptyOptionValue,
                    ...shopDefaultFilter(auth.defaultShop),
                },
                perPage: 1000,
                queryOptions: {
                    enabled: Boolean(record?.id),
                },
            })
            useEffect(() => {
                if (listController.isFetching === false) {
                    window.dispatchEvent(new Event('resize'))
                }
            }, [listController.isFetching])
            if (listController.isLoading || listController.error) {
                return null
            }

            const data = listController.data

            return (
                <Stack sx={{ padding: '14px 16px 6px' }}>
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        pb="14px"
                    >
                        USE EXISTING WORK ORDER
                    </Typography>
                    <Stack spacing="12px">
                        {data.length ? (
                            data?.map((item) => (
                                <WORow
                                    title={item.number}
                                    icon={woFields.avatar.getRecordIcon(item)}
                                    onClick={() => {
                                        close()
                                        cardMenuClose?.()
                                        woUse({
                                            workOrder: item,
                                            parentRecord,
                                            options: {
                                                ...options,
                                                woDefaultValues: {
                                                    vendorWorkOrderSwitch: Boolean(
                                                        item?.purchaseOrderData?.vendorData,
                                                    ),
                                                    vendor: item?.purchaseOrderData?.vendorData?.id,
                                                    domicile: record?.domicile,
                                                    customer: record?.customer,
                                                },
                                                woDisabledFields: {
                                                    vendorWorkOrderSwitch: true,
                                                    vendorInput: true,
                                                    ...options?.woDisabledFields,
                                                },
                                            },
                                        })
                                    }}
                                    vendorData={item?.purchaseOrderData?.vendorData}
                                    key={item.id}
                                />
                            ))
                        ) : (
                            <WORow
                                icon={woFields.avatar.Icon}
                                title="No Existing Work Orders"
                                disabled
                            />
                        )}
                    </Stack>
                </Stack>
            )
        },
    ),
)

export default ExistingWOSection
