import { useEffect, type FC, useRef } from 'react'

import { inject } from 'mobx-react'
import { useChoicesContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { AutocompleteInput, type SelectInputProps } from 'components'
import { ReferenceArrayInput, ReferenceInput } from 'core'
import { type AuthStore } from 'core/auth'
import { type ShopModel } from 'resourcesBase'

import { shopResource } from '../utils'

interface ShopInputProps
    extends Pick<
        SelectInputProps,
        'validate' | 'disabled' | 'multiple' | 'source' | 'helperText' | 'onChange'
    > {
    contextType: string
    defaultValue?: boolean
    defaultValueIfExist?: boolean | Pick<SetDefaultShopProps, 'onSelect'>
    contextId?: Identifier
    label?: string
    filter?: Record<string, any>
}

const ShopInput: FC<ShopInputProps> = inject('auth')(({
    validate,
    disabled,
    multiple,
    source = 'shop',
    contextType,
    contextId,
    auth,
    defaultValue = true,
    defaultValueIfExist,
    label,
    helperText,
    filter,
    onChange,
}: ShopInputProps & { auth: AuthStore }) => {
    const Component = multiple ? ReferenceArrayInput : ReferenceInput

    return (
        <Component
            source={source}
            reference={shopResource.resource}
            disabled={disabled}
            perPage={100}
            queryOptions={{
                meta: { query: { contextType, contextId } },
            }}
            filter={filter}
        >
            <>
                <AutocompleteInput
                    helperText={helperText}
                    label={label || `Repair shop${multiple ? 's' : ''}`}
                    validate={validate}
                    multiple={multiple}
                    disabled={disabled}
                    optionText="name"
                    defaultValue={defaultValue && !defaultValueIfExist ? auth.defaultShopId : null}
                    filterSelectedOptions
                    onChange={onChange}
                />
                {defaultValueIfExist ? (
                    <SetDefaultShop
                        source={source}
                        onSelect={
                            typeof defaultValueIfExist === 'object'
                                ? defaultValueIfExist.onSelect
                                : undefined
                        }
                    />
                ) : null}
            </>
        </Component>
    )
})

export default ShopInput

interface SetDefaultShopProps {
    source: string
    onSelect?: (item: ShopModel | undefined) => void
}

const SetDefaultShop: FC<SetDefaultShopProps> = inject('auth')(({
    auth,
    source,
    onSelect,
}: SetDefaultShopProps & { auth: AuthStore }) => {
    const { setValue } = useFormContext()
    const { availableChoices } = useChoicesContext<ShopModel>()
    const fetched = useRef(false)

    useEffect(() => {
        if (fetched.current || !availableChoices.length) {
            return
        }

        fetched.current = true
        const item = availableChoices.find(({ id }) => id === auth.defaultShopId)

        if (item) {
            setValue(source, auth.defaultShopId)
        }

        onSelect?.(item)
    }, [availableChoices])

    return null
})
