import { type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { SliderView, ViewHeader } from 'components'
import { RaResourceContextProvider, useResource, useClearResource } from 'core'
import { VendorSliderSkeleton } from 'resources/vendors'
import { Section } from 'ui'

const contactsSkeletonSlides = new Array(4).fill(<VendorSliderSkeleton height="145px" />)
export const ContactsFallback = () => (
    <Section>
        <ViewHeader
            title={null}
            loading
        />
        <SliderView items={contactsSkeletonSlides} />
    </Section>
)
export const ContactResource = ({
    children,
    resourceName,
}: {
    children: ReactElement
    resourceName: string
}) => {
    const { id } = useRecordContext() || {}
    const resource = useResource()

    const contactsResource = `${resource.resource}/${id}/contacts`

    useClearResource(contactsResource)

    if (!id) {
        return <ContactsFallback />
    }

    return (
        <RaResourceContextProvider
            value={{
                resource: contactsResource,
                name: resourceName,
            }}
        >
            {children}
        </RaResourceContextProvider>
    )
}
