import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import {
    InfoCard,
    InfoCardDetails,
    type InfoCardDetailsRow,
    InfoCardHeader,
    CardDefaultSkeleton,
} from 'components'
import { createdField } from 'resources/base'
import {
    type InvoiceModel,
    invoiceFields,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
} from 'resources/invoices'
import { paymentTermFields } from 'resources/paymentTerms'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import { woFields } from 'resources/workOrders'

const DetailsCard: FC = () => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return <CardDefaultSkeleton />
    }

    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails<InvoiceModel> details={getDetails(record)} />
        </InfoCard>
    )
}

export default DetailsCard

const getDetails = (record: InvoiceModel) => {
    const details: InfoCardDetailsRow<InvoiceModel>[] = [
        {
            label: shopFields.self.source,
            source: shopFields.self.source,
        },
        ...(record.type === InvoiceTypeKeys.WORK_ORDER
            ? [
                  {
                      label: unitFields.self.label,
                      source: (record) => unitFields.self.linkValue(record.unitData),
                  },
                  {
                      label: woFields.self.label,
                      source: (record) => woFields.self.linkValue(record.workOrderData),
                  },
                  {
                      label: invoiceFields.woCompletedDate.label,
                      source: (record) => invoiceFields.woCompletedDate.value(record),
                  },
              ]
            : []),
        {
            label: invoiceFields.poNumber.label,
            source: (record) => invoiceFields.poNumber.value(record),
        },
        {
            label: createdField.label,
            source: (record) => createdField.value(record),
        },
        {
            label: invoiceFields.invoiceDate.label,
            source: (record) => invoiceFields.invoiceDate.value(record),
        },
        {
            label: paymentTermFields.self.label,
            source: (record) => paymentTermFields.self.value(record.paymentTermData),
        },
        ...(record.status === InvoiceStatusKeys.CLOSED
            ? [
                  {
                      label: invoiceFields.closedDate.label,
                      source: (record) => invoiceFields.closedDate.value(record),
                  },
              ]
            : []),
        ...(record.status === InvoiceStatusKeys.OPEN
            ? []
            : [
                  {
                      label: invoiceFields.statusChanged.dateLabel(record),
                      source: (record) => invoiceFields.statusChanged.dateValue(record),
                  },
                  {
                      label: invoiceFields.statusChanged.byLabel(record),
                      source: (record) => invoiceFields.statusChanged.byValue(record),
                  },
              ]),
    ]

    return details
}
