import { formatMoney } from 'components'

import { type InvoiceModel } from '../types'

const source = 'paid' as const satisfies keyof InvoiceModel
const label = 'Paid'

interface Data extends Pick<InvoiceModel, 'paid'> {}

const value = (record: Data) => formatMoney(record.paid)

const paid = {
    source,
    label,
    value,
}

export default paid
