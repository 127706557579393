import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core'
import { useCreateResourcePath } from 'core/resource'
import { type Theme } from 'lib'
import { type PriorityConfig } from 'resources/common'
import { issuesResource, issueFields, IssueStatusKeys, IssuePriorityKeys } from 'resources/issues'
import { createReportResource } from 'resources/reports'
import { defaultDomicileFilter } from 'resources/units'
import { SvgIcon, BoxContainer, StatusText } from 'ui'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface DataType {
    lowCount: number
    lowInProgressCount: number
    mediumCount: number
    mediumInProgressCount: number
    highCount: number
    highInProgressCount: number
}

const MonitorIssues: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const createPath = useCreateResourcePath()
        const data = useMonitor<DataType>(
            createReportResource('issues-by-priority'),
            defaultDomicileFilter(auth.defaultShop),
        )

        if (!data) {
            return <Monitor.Skeleton />
        }

        return (
            <Monitor>
                <BoxContainer gap="4px">
                    <Monitor.Title
                        icon={issueFields.avatar.Icon}
                        statusText="OPEN"
                    >
                        Issues by Priority
                    </Monitor.Title>
                </BoxContainer>
                <Monitor.ArrowButton
                    navigateTo={
                        createPath({
                            resource: issuesResource.resource,
                            type: 'list',
                        }) +
                        '?filter=' +
                        encodeURIComponent(
                            JSON.stringify({
                                [issueFields.status.source]: [IssueStatusKeys.OPEN],
                                ...defaultDomicileFilter(auth.defaultShop),
                            }),
                        )
                    }
                />
                <Monitor.Container>
                    <Column
                        color={(theme) => theme.palette.charts.red}
                        config={issueFields.priority.getConfig(IssuePriorityKeys.HIGH)}
                        count={data.highCount}
                        inProgressCount={data.highInProgressCount}
                    />
                    <Column
                        color={(theme) => theme.palette.charts.orange}
                        config={issueFields.priority.getConfig(IssuePriorityKeys.MEDIUM)}
                        count={data.mediumCount}
                        inProgressCount={data.mediumInProgressCount}
                    />
                    <Column
                        color={(theme) => theme.palette.primary.main}
                        config={issueFields.priority.getConfig(IssuePriorityKeys.LOW)}
                        count={data.lowCount}
                        inProgressCount={data.lowInProgressCount}
                    />
                </Monitor.Container>
            </Monitor>
        )
    }),
)
export default MonitorIssues

interface ColumnProps {
    config: PriorityConfig<IssuePriorityKeys>
    color: (theme: Theme) => string
    count: number
    inProgressCount: number
}

const Column: FC<ColumnProps> = inject('auth')(
    observer(
        ({ config, color, count, inProgressCount, auth }: ColumnProps & { auth: AuthStore }) => {
            const createPath = useCreateResourcePath()

            const createLink = (value: IssuePriorityKeys) => {
                return (
                    createPath({ resource: issuesResource.resource, type: 'list' }) +
                    '?filter=' +
                    encodeURIComponent(
                        JSON.stringify({
                            [issueFields.priority.source]: [value],
                            [issueFields.status.source]: [
                                IssueStatusKeys.OPEN,
                                IssueStatusKeys.IN_PROGRESS,
                            ],
                            ...defaultDomicileFilter(auth.defaultShop),
                        }),
                    )
                )
            }

            return (
                <Monitor.Button navigateTo={createLink(config.id)}>
                    <BoxContainer gap="4px">
                        <Monitor.ValueText>{count}</Monitor.ValueText>
                        <Monitor.Counter
                            image={
                                issueFields.status.getConfig(IssueStatusKeys.IN_PROGRESS)
                                    .iconComponent
                            }
                        >
                            {inProgressCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText
                        statusColor={color}
                        display="flex"
                        alignItems="center"
                        gap="4px"
                    >
                        <SvgIcon
                            component={config.iconComponent}
                            inheritViewBox
                            color="inherit"
                            sx={{ width: '10px', height: '10px' }}
                        />
                        {config.text}
                    </StatusText>
                </Monitor.Button>
            )
        },
    ),
)
