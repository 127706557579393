import { Fragment } from 'react'

import { useRecordContext } from 'react-admin'

import { type TabType, Header, InnerHeader, ShowHeaderSkeleton } from 'components'
import { urls } from 'configs'
import { archiveOneAction, type Action, deleteOneAction } from 'core'
import { WorkOrderDrawerToggler } from 'pages/WorkOrders/components'
import {
    archiveUnitActionParams,
    deleteUnitAction,
    DownloadRecordAction,
    isUnitArchived,
    meterTypesConfig,
    UnitArchivedStatus,
    unitFields,
    UnitIntegrationStatus,
    type UnitModel,
    useUnitStatusAction,
} from 'resources/units'
import { woFields } from 'resources/workOrders'
import { AutoGrid, SvgIcon } from 'ui'

import { UnitMetersDrawerToggler } from '../../Meters/components'
import UnitMetersResource from '../../Meters/components/UnitMetersResource'

interface UnitShowHeaderInterface {
    tabs: TabType[]
}

const UnitShowHeader = ({ tabs }: UnitShowHeaderInterface) => {
    const record = useRecordContext<UnitModel>()
    const isArchived = isUnitArchived(record)
    const actions = useActions()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="130px"
            tabMinWidth="130px"
            actions={actions}
            backTo={isArchived ? urls.unitsArchived : urls.units}
        >
            <Header.Info
                avatar={
                    <Header.Avatar
                        color={isArchived ? (theme) => theme.palette.text.primary : undefined}
                        imageSrc={record.photo}
                        defaultImage={
                            <SvgIcon
                                component={unitFields.avatar.Icon}
                                inheritViewBox
                                sx={isArchived ? { opacity: 0.54 } : undefined}
                            />
                        }
                    />
                }
            >
                <Header.Content>
                    <AutoGrid.Row>
                        <AutoGrid.Column>
                            <Header.Title>{record.number}</Header.Title>
                        </AutoGrid.Column>
                        <AutoGrid.Column>
                            <UnitIntegrationStatus />
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        <AutoGrid.Column>{unitFields.status.value(record)}</AutoGrid.Column>
                        <AutoGrid.Column>
                            <UnitArchivedStatus />
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default UnitShowHeader

const useActions = () => {
    const statusActions = useUnitStatusAction()

    const actions: Action<UnitModel> = (record, { children }) => {
        const isArchived = isUnitArchived(record)

        return [
            statusActions(children),
            isArchived ? (
                <Fragment key="archived">
                    {deleteOneAction({
                        children,
                        id: record.id,
                        ...deleteUnitAction(record.hasRelatedWorkOrders),
                    })}
                    <DownloadRecordAction
                        children={children}
                        record={record}
                        key="download-record"
                    />
                </Fragment>
            ) : (
                <Fragment key="un-archived">
                    <UnitMetersResource>
                        <UnitMetersDrawerToggler>
                            {({ onClick }) => {
                                return children({
                                    title: 'Add Meters',
                                    Icon: meterTypesConfig.HUBOMETER.Icon,
                                    key: 'create-meter',
                                    onClick,
                                })
                            }}
                        </UnitMetersDrawerToggler>
                    </UnitMetersResource>
                    <DownloadRecordAction
                        children={children}
                        record={record}
                        key="download-record"
                    />
                    <WorkOrderDrawerToggler
                        defaultValues={{
                            unit: record.id,
                            domicile: record.domicile,
                            customer: record.customer,
                        }}
                    >
                        {({ onClick }) =>
                            children({
                                title: 'Create Work Order',
                                Icon: woFields.avatar.Icon,
                                key: 'create-wo',
                                onClick,
                            })
                        }
                    </WorkOrderDrawerToggler>
                </Fragment>
            ),
            archiveOneAction({
                children,
                ...archiveUnitActionParams(
                    isUnitArchived(record),
                    Boolean(record.telematicsData),
                    record.hasOpenWorkOrders,
                ),
            }),
        ]
    }
    return actions
}
