import { useShowContext } from 'react-admin'

import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtPartResource, ptPartPreferencesResource } from 'resources/pricingTiers'

import { PartsList, ListHeader } from './components'
const defaultSort: SortPayload = {
    field: 'name',
    order: 'DESC',
}

export const PricingTiersParts = () => {
    const { record } = useShowContext()

    return (
        <ResourceContextProviderWithClearEffect value={getPtPartResource(record?.id)}>
            <ListBase
                sort={defaultSort}
                isLoading={!record}
                preferencesResource={ptPartPreferencesResource}
            >
                <ListHeader />
                <PartsList preferencesResource={ptPartPreferencesResource} />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}
