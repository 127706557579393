import { formatPercent } from 'components'

import { type PtTaxesModel } from '../types'

const source = 'markupPercent' as const satisfies keyof PtTaxesModel

const label = 'Percentage on Total'

const value = (record: Pick<PtTaxesModel, 'markupPercent'>) => {
    return formatPercent(record.markupPercent, undefined, true)
}

export const percentageOnTotal = {
    source,
    label,
    value,
}
