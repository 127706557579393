import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { displayBooleanValue } from 'utils'

const InvoicingCard = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Settings" />
            <InfoCardDetails
                details={[
                    { label: 'Invoice on WO close', source: () => displayBooleanValue(true) },
                    { label: 'Include Company Logo', source: () => displayBooleanValue(true) },
                    { label: 'Show Part Number in PDF', source: () => displayBooleanValue(true) },
                ]}
            />
        </InfoCard>
    )
}

export default InvoicingCard
