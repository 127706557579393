import { type FC, useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { BooleanInput, useChoicesContext } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import {
    UploadImage,
    SelectInput as SelectInputBase,
    DateInput,
    TextInput as TextInputBase,
    type AutocompleteInputProps,
    inputUppercaseMaskParams,
    inputIntegerPositiveMaskParams,
} from 'components'
import { requiredValidation, validateMaxNum, maxLengthValidation, validateMaxLength } from 'core'
import { type AuthStore } from 'core/auth'
import { CostCenterInput } from 'resources/costCenters'
import { CustomerInput } from 'resources/customers'
import { DivisionInput } from 'resources/divisions'
import { ShopInput } from 'resources/shops'
import { TagInput } from 'resources/tags'
import { CK2Input, type CK2Model, CK34Input } from 'resources/vmrs'
import { SectionTitleSmall, TextWithIcon } from 'ui'

import unitFields from '../fields'
import { type UnitModel } from '../types'
import { equipmentHasBodySource } from '../utils'

import UnitFormBodyType from './UnitFormBodyType'

const SelectInput = SelectInputBase<UnitModel>
const TextInput = TextInputBase<UnitModel>

const engineHPValidation = validateMaxNum(20000)
const gearsValidation = validateMaxNum(100)
const maxLengthValidation17 = validateMaxLength(17)
const maxLengthValidation20 = validateMaxLength(20)
const serialNumberValidation = validateMaxLength(30)

const StatusSelect = () => {
    const id = useWatch({ name: 'id' })

    if (id) {
        return null
    }

    return (
        <SelectInput
            optionText={statusText}
            label="Status"
            source="status"
            choices={unitFields.status.choices}
            validate={requiredValidation}
            disableEmptyValue
        />
    )
}

const statusText = (option: (typeof unitFields.status.choices)[0]) => {
    const config = unitFields.status.getConfig(option.id)
    return <TextWithIcon icon={config.icon}>{config.text}</TextWithIcon>
}

const CustomersInputWrapper = ({
    ...props
}: Pick<AutocompleteInputProps, 'disabled' | 'defaultValue'>) => {
    const enableCustomer = useWatch({ name: 'customerUnit', defaultValue: true })
    if (!enableCustomer) {
        return null
    }
    return (
        <CustomerInput
            label="Customer"
            source="customer"
            required={enableCustomer}
            {...props}
        />
    )
}
export interface UnitFormProps {
    inputsProps?: {
        [key in keyof (UnitModel & { customerUnit: boolean })]?: {
            disabled?: boolean
            hide?: boolean
            defaultValue?: boolean
            helperText?: string
        }
    }
    auth?: AuthStore
}
const UnitForm = inject('auth')(
    observer(({ inputsProps = {}, auth }: UnitFormProps) => {
        const { getValues, setValue } = useFormContext()
        return (
            <>
                {inputsProps.customerUnit?.hide || !auth.companySettings.hasCustomers || (
                    <BooleanInput
                        source="customerUnit"
                        label="Customer Unit"
                        defaultValue
                        {...inputsProps.customerUnit}
                    />
                )}
                {inputsProps.customer?.hide || !auth.companySettings.hasCustomers || (
                    <CustomersInputWrapper {...inputsProps.customer} />
                )}
                <SectionTitleSmall>Basic Details</SectionTitleSmall>

                <StatusSelect />

                <TextInput
                    source="number"
                    label="Unit Number"
                    validate={requiredValidation}
                />

                <ShopInput
                    disabled={inputsProps.domicile?.disabled}
                    source="domicile"
                    contextType="units"
                    label="Domicile"
                    defaultValue={inputsProps.domicile?.defaultValue}
                    helperText={
                        inputsProps.domicile?.disabled
                            ? 'Domicile editing is disabled due to an open WO.'
                            : undefined
                    }
                />

                <CostCenterInput {...inputsProps.costCenter} />

                <DivisionInput {...inputsProps.division} />

                <UploadImage
                    source="photo"
                    defaultIcon={<unitFields.avatar.Icon />}
                />
                <TextInput
                    validate={maxLengthValidation}
                    source="name"
                    label="Unit Name"
                />

                <TextInputBase
                    source="vin"
                    label="VIN"
                    validate={maxLengthValidation17}
                    {...inputUppercaseMaskParams}
                />

                <TextInputBase
                    source="serialNumber"
                    label="Serial Number"
                    validate={serialNumberValidation}
                />

                <TextInputBase
                    source="licensePlate"
                    label="License Plate"
                    validate={maxLengthValidation20}
                    {...inputUppercaseMaskParams}
                />
                <TagInput />

                <SectionTitleSmall>Specifications</SectionTitleSmall>

                <CK2Input
                    onChange={() => {
                        const body = getValues('body')
                        if (!body) {
                            return
                        }
                        Object.keys(body).forEach((key) => {
                            setValue(`body.${key}`, '', {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                            })
                        })
                    }}
                    referenceInputChildren={<EquipmentChangeHandler />}
                />

                <UnitFormBodyType />
                <CK34Input
                    label="Manufacturer"
                    source="vmrsManufacturer"
                />
                <TextInput
                    source="model"
                    label="Model"
                    validate={maxLengthValidation}
                />

                <DateInput
                    source="modelYear"
                    label="Model Year"
                    views={['year']}
                />

                <TextInput
                    source="color"
                    label="Color"
                    validate={maxLengthValidation20}
                />

                <TextInput
                    source="tireSize"
                    label="Tire Size"
                    validate={maxLengthValidation}
                />

                <SectionTitleSmall
                    variant="subtitle2"
                    frontLine
                >
                    Engine
                </SectionTitleSmall>

                <CK34Input
                    label="Manufacturer/Make"
                    source="engineVmrsManufacturer"
                />

                <TextInput
                    source="engineModel"
                    label="Model"
                    validate={maxLengthValidation}
                />
                <TextInputBase
                    source="engineHp"
                    label="HP"
                    validate={engineHPValidation}
                    {...inputIntegerPositiveMaskParams}
                />

                <SectionTitleSmall
                    variant="subtitle2"
                    frontLine
                >
                    Transmission
                </SectionTitleSmall>

                <CK34Input
                    label="Manufacturer/Make"
                    source="transmissionVmrsManufacturer"
                />

                <TextInput
                    source="transmissionModel"
                    label="Model"
                    validate={maxLengthValidation}
                />

                <TextInputBase
                    source="transmissionGears"
                    label="Gears"
                    validate={gearsValidation}
                    {...inputIntegerPositiveMaskParams}
                />
            </>
        )
    }),
)

export default UnitForm

const EquipmentChangeHandler: FC = () => {
    const { selectedChoices } = useChoicesContext<CK2Model>()
    const { setValue } = useFormContext()

    const hasBody = Boolean((selectedChoices[0] as CK2Model)?.hasBodySpecs)
    useEffect(() => {
        setValue(equipmentHasBodySource, hasBody)
    }, [hasBody])

    return null
}
