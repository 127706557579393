import { type ReactElement, type FC } from 'react'

import { InvoiceDrawerToggler, InvoiceTypeKeys } from 'resources/invoices'
import { type WorkOrderModel } from 'resources/workOrders'

interface WoInvoiceDrawerProps {
    record: WorkOrderModel
    children: (open: () => void) => ReactElement
}
const WoInvoiceDrawer: FC<WoInvoiceDrawerProps> = ({ record, children }) => {
    return (
        <InvoiceDrawerToggler
            defaultValues={{
                shop: record.shop,
                customer: record.unitData.customer,
                workOrder: record.id,
                unit: record.unit,
            }}
            type={InvoiceTypeKeys.WORK_ORDER}
        >
            {children}
        </InvoiceDrawerToggler>
    )
}

export default WoInvoiceDrawer
