import { type ResourceType } from 'core/resource'

export const resourceToFormName = (resource: ResourceType) => {
    return resource?.resource?.replaceAll('/', '__')
}

export const resourceToFormId = (resource: ResourceType) => {
    const name = resourceToFormName(resource)
    return name && `${name}-form`
}
