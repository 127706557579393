import { inject, observer } from 'mobx-react'
import {
    ListContextProvider,
    useListContext,
    useListController,
    useRecordContext,
} from 'react-admin'

import { type DataRecord } from 'appTypes'
import Icons from 'assets/icons'
import { CardListCard, SliderView, ViewHeader } from 'components'
import {
    useCreateResourcePath,
    type AuthStore,
    ActionsMenu,
    RaResourceContextProvider,
    perPageLimit,
} from 'core'
import { UnitSliderSkeleton } from 'pages/Units/components'
import { editRedirectWOFromListAction, pdfOneWOFromListAction } from 'pages/WorkOrders/List/actions'
import { woResource, WoStatusKeys, type WorkOrderModel } from 'resources/workOrders'
import { InfoBadge, Section } from 'ui'

import { getWoCardsCfg } from '../List/WorkOrdersList'
import { WorkOrderDrawerToggler } from '../components'
import { type WorkOrderFormProps } from '../components/WorkOrderForm'

import WorkOrderSimpleListNoResults, {
    type WorkOrderSimpleListNoResultsProps,
} from './WorkOrderSimpleListNoResults'

type WorkOrderSimpleListActionFilterType = {
    [key in keyof WorkOrderModel]?: [WorkOrderModel[key]]
}

const WorkOrderSimpleListAction = ({
    filter,
    createFormInitialValues,
    disabledFields,
    createFormConfig,
}: {
    filter: WorkOrderSimpleListActionFilterType
    createFormInitialValues: (record: DataRecord) => Partial<WorkOrderModel>
    disabledFields: WorkOrderFormProps['disabledFields']
    createFormConfig: WorkOrderFormProps['createFormConfig']
}) => {
    const createPath = useCreateResourcePath()
    const listContext = useListContext()
    const record = useRecordContext<DataRecord>()
    const defaultValues = createFormInitialValues(record)

    const woLink: string =
        createPath({ resource: woResource.resource, type: 'list' }) +
        '?filter=' +
        encodeURIComponent(JSON.stringify(filter))

    return (
        <ActionsMenu
            disabled={listContext.isLoading}
            record={record}
            actions={(params, { children }) => [
                children({
                    Icon: Icons.GridView,
                    title: 'See All',
                    key: 'see-all',
                    to: woLink,
                    disabled: !listContext.total,
                }),
                <WorkOrderDrawerToggler
                    defaultValues={defaultValues}
                    key="add-wo"
                    children={({ onClick }) =>
                        children({ Icon: Icons.WorkOrders, title: 'Create Work Order', onClick })
                    }
                    disabledFields={disabledFields}
                    createFormConfig={createFormConfig}
                />,
            ]}
        />
    )
}

interface WorkOrderSimpleListProps<RecordType extends DataRecord>
    extends Omit<WorkOrderSimpleListNoResultsProps<RecordType>, 'createFormConfig'> {
    filter: (record: RecordType) => {
        [key in keyof WorkOrderModel & { customer?: string }]?: [WorkOrderModel[key]]
    }
    auth?: AuthStore
    disabledFields?: WorkOrderFormProps['disabledFields']
    createFormConfig?: (record: RecordType) => WorkOrderFormProps['createFormConfig']
}
const skeletonSlides = new Array(4).fill(<UnitSliderSkeleton height="308px" />)

const WorkOrderSimpleList = inject('auth')(
    observer(
        <RecordType extends DataRecord>({
            filter: filterProp,
            createFormInitialValues,
            workOrderType,
            removeCreate,
            auth,
            disabledFields,
            createFormConfig,
        }: WorkOrderSimpleListProps<RecordType>) => {
            const record = useRecordContext<RecordType>()

            const woCardsCfg = getWoCardsCfg(
                auth.companySettings.hasCustomers,
                auth.companySettings.hasInvoicing,
            )
            const cardsCfg: typeof woCardsCfg = {
                ...woCardsCfg,
                actions: (record, { children }) => [
                    editRedirectWOFromListAction({ id: record.id, children }),
                    pdfOneWOFromListAction({ children, record }),
                ],
            }

            const filter: WorkOrderSimpleListActionFilterType = {
                status: [WoStatusKeys.OPEN],
                ...filterProp(record),
            }

            const listController = useListController({
                resource: woResource.resource,
                filter,
                perPage: perPageLimit,
                queryOptions: {
                    enabled: Boolean(record?.id),
                },
            })
            let content

            if (listController.isLoading || !record?.id) {
                content = <SliderView items={skeletonSlides} />
            } else if (listController.total) {
                content = (
                    <SliderView
                        items={listController.data.map((wo) => (
                            <CardListCard<WorkOrderModel>
                                cardsCfg={cardsCfg}
                                record={wo}
                            />
                        ))}
                    />
                )
            } else {
                content = (
                    <WorkOrderSimpleListNoResults
                        createFormInitialValues={createFormInitialValues}
                        workOrderType={workOrderType}
                        removeCreate={removeCreate}
                        disabledFields={disabledFields}
                        createFormConfig={createFormConfig?.(record)}
                    />
                )
            }

            return (
                <RaResourceContextProvider value={{ resource: woResource.resource }}>
                    <ListContextProvider value={listController}>
                        <Section>
                            <ViewHeader
                                title="Open Work Orders"
                                loading={listController.isLoading || !record?.id}
                            >
                                <ViewHeader.Content>
                                    <InfoBadge badgeContent={String(listController.total)} />
                                </ViewHeader.Content>
                                <ViewHeader.Content placement="rightMobile">
                                    <WorkOrderSimpleListAction
                                        createFormInitialValues={createFormInitialValues}
                                        disabledFields={disabledFields}
                                        filter={filter}
                                        createFormConfig={createFormConfig?.(record)}
                                    />
                                </ViewHeader.Content>
                            </ViewHeader>
                            {content}
                        </Section>
                    </ListContextProvider>
                </RaResourceContextProvider>
            )
        },
    ),
)

export default WorkOrderSimpleList
