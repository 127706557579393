import { useState } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core/auth'
import { shopFields, DefaultRSMenu } from 'resources/shops'
import { DataAvatar } from 'ui'

export const DefaultRSButton = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

        const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handleMenuClose = () => {
            setAnchorEl(null)
        }
        return (
            <>
                <DataAvatar
                    sx={{
                        width: 24,
                        height: 24,
                        '& > *': auth.defaultShop
                            ? undefined
                            : {
                                  width: 16,
                                  height: 16,
                                  color: '#FFFFFF !important',
                              },
                    }}
                    defaultImage={
                        <shopFields.avatar.Icon
                            record={auth.defaultShop}
                            fontWeight={400}
                            variant="chartLabel"
                        />
                    }
                    avatarOpacity={auth.defaultShop ? 1 : 0.54}
                    color={(theme) => auth.defaultShop?.avatarColor || theme.palette.text.primary}
                    onClick={handleMenuClick}
                />
                <DefaultRSMenu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                />
            </>
        )
    }),
)
