import { formatMoney } from 'components'

import { type InvoiceModel } from '../types'

const source = 'balanceDue' as const satisfies keyof InvoiceModel
const label = 'Balande Due'

interface Data extends Pick<InvoiceModel, 'balanceDue'> {}

const value = (record: Data) => formatMoney(record.balanceDue)

const balanceDue = {
    source,
    label,
    value,
}

export default balanceDue
