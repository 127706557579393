import { useShowContext } from 'react-admin'

import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtTaxResource, ptTaxPreferencesResource } from 'resources/pricingTiers'

import { ListHeader, TaxesList } from './components'

const defaultSort: SortPayload = {
    field: 'name',
    order: 'DESC',
}

export const PricingTiersTaxes = () => {
    const { record } = useShowContext()

    return (
        <ResourceContextProviderWithClearEffect value={getPtTaxResource(record?.id)}>
            <ListBase
                sort={defaultSort}
                isLoading={!record}
                preferencesResource={ptTaxPreferencesResource}
            >
                <ListHeader />
                <TaxesList preferencesResource={ptTaxPreferencesResource} />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default PricingTiersTaxes
