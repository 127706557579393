import { useEffect, type FC } from 'react'

import { BooleanInput } from 'react-admin'
import { useWatch, useFormContext } from 'react-hook-form'

import { DateTimeInput, type DialogSelectorProps, TextareaInput } from 'components'
import { authStore, requiredValidation, maxLengthValidationText } from 'core'
import { PriorityInput } from 'resources/common'
import { CustomerPONumberInput, CustomerPopUpInput } from 'resources/customers'
import { ShopInput } from 'resources/shops'
import { VendorInput } from 'resources/vendors'
import { woFields, WoPriorityKeys, woResource, type WorkOrderModel } from 'resources/workOrders'
import { SectionTitleSmall } from 'ui'

import WoSelectInput from './WoSelectInput'
import WorkOrderFormMeter from './WorkOrderFormMeter'
import { WorkOrderUnitInput } from './WorkOrderUnitInput'

export interface WorkOrderFormProps {
    disabledFields?: {
        [key in keyof (WorkOrderModel & {
            vendorWorkOrderSwitch: boolean
            vendorInput: string
            poNumber: string
        })]?: boolean
    }
    createFormConfig?: { unit: { defaultFilter: DialogSelectorProps['defaultFilter'] } }
}

const WorkOrderForm: FC<WorkOrderFormProps> = ({ disabledFields, createFormConfig }) => {
    return (
        <>
            {disabledFields?.vendor ? null : (
                <>
                    <VendorWoSwitch disabled={disabledFields?.vendorWorkOrderSwitch} />

                    <VendorInputElement disabled={disabledFields?.vendorInput} />
                </>
            )}

            <SectionTitleSmall>Repair Shop Details</SectionTitleSmall>

            <WOShopInput disabled={disabledFields?.shop} />

            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            <SectionTitleSmall
                variant="subtitle1"
                frontLine
            >
                Details
            </SectionTitleSmall>

            <WorkOrderUnitInput
                disabled={disabledFields?.unit}
                defaultFilter={createFormConfig?.unit?.defaultFilter}
            />

            <ShopInput
                defaultValue={false}
                disabled
                source="domicile"
                contextType="units"
                label="Domicile"
            />
            <CustomerPopUpInput />

            <WoCustomerPONumberInput disabled={disabledFields?.poNumber} />

            <PriorityInput
                choices={woFields.priority.choices}
                optionText={woFields.priority.inputValue}
                isRequired
                defaultValue={WoPriorityKeys.LOW}
            />

            <WorkOrderFormMeter />

            <SectionTitleSmall>Repair Details</SectionTitleSmall>

            <WoSelectInput
                optionText={woFields.repairPriorityClass.inputValue}
                label={woFields.repairPriorityClass.label}
                source={woFields.repairPriorityClass.source}
                choices={woFields.repairPriorityClass.choices}
                validate={requiredValidation}
                disableEmptyValue
            />

            <DateTimeInput
                source="started"
                label="Start Date &amp; Time"
                validate={requiredValidation}
                defaultValue={new Date()}
            />

            <TextareaInput<WorkOrderModel>
                source="description"
                label="Description"
                validate={maxLengthValidationText}
            />
        </>
    )
}

const WoCustomerPONumberInput = ({ disabled }: { disabled: boolean }) => {
    const customer = useWatch({ name: 'customer' })
    if (!customer) {
        return null
    }

    return <CustomerPONumberInput disabled={disabled} />
}

const VendorWoSwitch = ({ disabled }: { disabled: boolean }) => {
    const { setValue } = useFormContext()

    return (
        <BooleanInput
            source="vendorWorkOrderSwitch"
            defaultValue={authStore.preferences.defaultWoType}
            label="Vendor Work Order"
            onChange={({ target }) => {
                if (!target.checked) {
                    setValue('vendor', null)
                }
                authStore.updatePreferences({
                    defaultWoType: target.checked,
                })
            }}
            color={disabled ? 'default' : 'primary'}
            disabled={disabled}
        />
    )
}

const VendorInputElement = ({ disabled }: { disabled: boolean }) => {
    const { watch } = useFormContext()
    const vendorsWorkOrder =
        useWatch({
            name: 'vendorWorkOrderSwitch',
        }) || watch('vendorWorkOrderSwitch')

    if (!vendorsWorkOrder) {
        return null
    }

    return (
        <VendorInput
            vendorType="REPAIR_SERVICES"
            source="vendor"
            required
            disabled={disabled}
            label="Vendor"
        />
    )
}

const WOShopInput = ({ disabled }: { disabled: boolean }) => {
    const { setValue, watch, getValues } = useFormContext()
    const domicile = watch('domicile') || getValues('domicile')

    useEffect(() => {
        if (domicile) {
            setValue('shop', domicile)
        }
    }, [])

    return (
        <ShopInput
            defaultValue={!domicile}
            contextType={woResource.resource}
            validate={requiredValidation}
            onChange={(shopId) => {
                if (!shopId) {
                    setValue('unit', null)
                }
            }}
            disabled={disabled || Boolean(domicile)}
        />
    )
}

export default WorkOrderForm
