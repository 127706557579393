import { type FC, type ReactElement } from 'react'

import { useShowContext } from 'react-admin'

import { isVendorWo } from 'resources/workOrders/isVendorWo'
import { pathJoin } from 'utils'

import { type WorkOrderModel } from '../../types'
import { woResource } from '../../utils'

import JobDrawerToggler, { type JobDrawerTogglerProps } from './JobDrawerToggler'

interface Props extends Omit<JobDrawerTogglerProps, 'billable' | 'resource'> {
    children: (open: () => void) => ReactElement
}

const WoJobDrawerToggler: FC<Props> = (props) => {
    const { record: wo } = useShowContext<WorkOrderModel>()

    return (
        <JobDrawerToggler
            {...props}
            mode={wo.status === 'OPEN' ? undefined : 'readonly'}
            billable={!isVendorWo(wo) && Boolean(wo?.unitData.customer)}
            resource={{
                name: 'wo-jobs',
                resource: pathJoin(woResource.resource, wo.id, 'jobs'),
            }}
        />
    )
}

export default WoJobDrawerToggler
