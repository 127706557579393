import { Show, tabRoutes } from 'components'
import { Routes } from 'lib'
import { ContactsListPage } from 'resources/contacts'

import VendorOverview from '../Overview'
import { vendorContactsResourceName } from '../constants/config'

import { VendorShowHeader } from './components'

const VendorShow = () => {
    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <VendorOverview />,
        },
        {
            value: 'contact-persons',
            label: 'Contact Persons',
            element: <ContactsListPage resourceName={vendorContactsResourceName} />,
        },
    ])
    return (
        <Show>
            <>
                <VendorShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
            </>
        </Show>
    )
}

export default VendorShow
