import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { useCreateResourcePath, type AuthStore } from 'core'
import { createReportResource } from 'resources/reports'
import { shopDefaultFilter } from 'resources/shops'
import { type WorkOrderModel, woResource, woFields, WoRepairClassKeys } from 'resources/workOrders'
import { BoxContainer, StatusText } from 'ui'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface DataType {
    emergencyCount: number
    nonScheduledCount: number
    scheduledCount: number
}

const MonitorWORepairClass: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const createPath = useCreateResourcePath()
        const defaultShopId = auth.defaultShopId
        const data = useMonitor<DataType>(createReportResource('work-orders-by-repair-class'), {
            shop: defaultShopId,
        })

        if (!data) {
            return <Monitor.Skeleton />
        }

        const { emergencyCount, nonScheduledCount, scheduledCount } = data
        const createLink = (repairPriorityClass: WorkOrderModel['repairPriorityClass']) => {
            return (
                createPath({ resource: woResource.resource, type: 'list' }) +
                '?filter=' +
                encodeURIComponent(
                    JSON.stringify({
                        repairPriorityClass: [repairPriorityClass],
                        status: ['OPEN'],
                        ...shopDefaultFilter(auth.defaultShop),
                    }),
                )
            )
        }
        return (
            <Monitor>
                <BoxContainer gap="4px">
                    <Monitor.Title
                        icon={woFields.avatar.Icon}
                        statusText="OPEN"
                    >
                        Work Orders by Repair Class
                    </Monitor.Title>
                </BoxContainer>
                <Monitor.ArrowButton
                    navigateTo={
                        createPath({
                            resource: woResource.resource,
                            type: 'list',
                        }) +
                        '?filter=' +
                        encodeURIComponent(JSON.stringify({ status: ['OPEN'] }))
                    }
                />
                <Monitor.Container>
                    <Monitor.Button navigateTo={createLink(WoRepairClassKeys.EMERGENCY)}>
                        <Monitor.ValueText>{emergencyCount}</Monitor.ValueText>

                        <StatusText statusColor={(theme) => theme.palette.charts.red}>
                            {
                                woFields.repairPriorityClass.getConfig(WoRepairClassKeys.EMERGENCY)
                                    .text
                            }
                        </StatusText>
                    </Monitor.Button>
                    <Monitor.Button navigateTo={createLink(WoRepairClassKeys.NON_SCHEDULED)}>
                        <Monitor.ValueText>{nonScheduledCount}</Monitor.ValueText>
                        <StatusText statusColor={(theme) => theme.palette.charts.orange}>
                            {
                                woFields.repairPriorityClass.getConfig(
                                    WoRepairClassKeys.NON_SCHEDULED,
                                ).text
                            }
                        </StatusText>
                    </Monitor.Button>
                    <Monitor.Button navigateTo={createLink(WoRepairClassKeys.SCHEDULED)}>
                        <Monitor.ValueText>{scheduledCount}</Monitor.ValueText>
                        <StatusText statusColor={(theme) => theme.palette.charts.greenBody}>
                            {
                                woFields.repairPriorityClass.getConfig(WoRepairClassKeys.SCHEDULED)
                                    .text
                            }
                        </StatusText>
                    </Monitor.Button>
                </Monitor.Container>
            </Monitor>
        )
    }),
)

export default MonitorWORepairClass
