import { type FC, useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { useChoicesContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { TextInput, TextareaInput, CheckboxInput } from 'components'
import {
    type AuthStore,
    maxLengthValidationText,
    requiredValidation,
    validateMaxLength,
} from 'core'
import { PhotosInput, photoSource } from 'resources/gallery'
import { maxJobNameLength } from 'resources/jobs'
import { NotesInput } from 'resources/notes'
import {
    ck14Fields,
    CK14Input,
    ck18Fields,
    CK18Input,
    ck33Fields,
    CK33InputWithoutParts,
} from 'resources/vmrs'
import { GridItem, GridContainer, SectionTitleSmall } from 'ui'
import { isInputDisabledDefault } from 'utils'

import { type JobModel } from '../types'

const RepairTypeValueField = () => {
    const { availableChoices, selectedChoices } = useChoicesContext()
    const { setValue } = useFormContext()
    const repairType = selectedChoices?.[0]

    useEffect(() => {
        if (!availableChoices || !repairType) {
            return
        }

        setValue('repairType', repairType?.type)
    }, [repairType])
    return null
}

const nameValidation = [validateMaxLength(maxJobNameLength), requiredValidation]

interface JobFormProps {
    isInputDisabled?: (source: keyof (JobModel & { [photoSource]: string })) => boolean
    isHidden?: (source: keyof JobModel) => boolean
    reasonForRepairDefaultValueByCode?: string
}

const JobForm: FC<JobFormProps> = inject('auth')(
    observer(
        ({
            isInputDisabled = isInputDisabledDefault,
            isHidden,
            reasonForRepairDefaultValueByCode,
            auth,
        }: JobFormProps & { auth: AuthStore }) => {
            return (
                <>
                    <SectionTitleSmall>Basic Details</SectionTitleSmall>
                    <GridContainer>
                        <GridItem>
                            <TextInput
                                disabled={isInputDisabled('name')}
                                source="name"
                                label="Name"
                                validate={nameValidation}
                            />
                        </GridItem>
                        <GridItem>
                            <CK33InputWithoutParts
                                disabled={isInputDisabled(ck33Fields.self.source)}
                                required
                            />
                        </GridItem>
                        <GridItem>
                            <CK14Input
                                disabled={isInputDisabled(ck14Fields.self.source)}
                                defaultValueByCode={reasonForRepairDefaultValueByCode}
                            >
                                <RepairTypeValueField />
                            </CK14Input>
                        </GridItem>
                        <GridItem>
                            <TextInput
                                label="Repair Type"
                                source="repairType"
                                disabled
                            />
                        </GridItem>
                        <GridItem>
                            <CK18Input disabled={isInputDisabled(ck18Fields.self.source)} />
                        </GridItem>
                        <GridItem>
                            <TextareaInput<JobModel>
                                source="description"
                                validate={maxLengthValidationText}
                                label="Description"
                                disabled={isInputDisabled('description')}
                            />
                        </GridItem>
                        <GridItem>
                            <NotesInput
                                disabled={isInputDisabled('notesToAdd')}
                                titleOnDisabled="Reopen the WO to add notes"
                            />
                        </GridItem>

                        <GridItem mb="36px">
                            <PhotosInput
                                disabled={isInputDisabled(photoSource)}
                                TooltipOnDisabled="Reopen the WO to add photo"
                            />
                        </GridItem>

                        {auth.companySettings.hasInvoicing && !isHidden?.('billable') ? (
                            <>
                                <SectionTitleSmall>Charge Details</SectionTitleSmall>

                                <GridItem>
                                    <CheckboxInput
                                        disabled={isInputDisabled('billable')}
                                        disableLabelClick
                                        source="billable"
                                        label="Billable"
                                        helperText="This job and its items won't be included in the invoice."
                                        defaultValue
                                    />
                                </GridItem>
                            </>
                        ) : null}
                    </GridContainer>
                </>
            )
        },
    ),
)
export default JobForm
