import { useRecordContext, useResourceContext, useShowContext } from 'react-admin'

import { DynamicRoutes, tabRoutes } from 'components'
import { Show } from 'components/resource'
import { urls } from 'configs'
import { onAction } from 'core'
import { useDidMount } from 'hooks'
import { getCurrentYear, Routes, useFlags } from 'lib'
import { documentsTab } from 'resources/documents'
import { type UnitModel } from 'resources/units'
import { woResource } from 'resources/workOrders'

import UnitCostReport from '../CostReport'
import UnitDVIR from '../DVIR'
import UnitExpirations from '../Expirations'
import UnitIssues from '../Issues'
import UnitMeters from '../Meters'
import UnitNotes from '../Notes'
import UnitOverview from '../Overview'
import UnitPM from '../PM'

import { UnitShowHeader } from './components'

const UnitShow = () => {
    return (
        <Show
            actionConfig={(record: UnitModel) =>
                record.archived ? urls.unitsArchived : urls.units
            }
        >
            <UnitShowBase />
        </Show>
    )
}

export default UnitShow

const UnitTrackActions = () => {
    const { refetch } = useShowContext()

    useDidMount(() => {
        return onAction(woResource.name, ({ name }) => {
            if (name === 'statusChange') {
                refetch()
            }
        })
    })

    return null
}

const UnitShowBase = () => {
    const record = useRecordContext<UnitModel>()
    const resource = useResourceContext()
    const { flag4775UnitExpirations, flag4975UnitCostSummaryReport } = useFlags()
    const isArchived = Boolean(record?.archived)

    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <UnitOverview />,
        },
        {
            value: 'pm',
            label: 'PM',
            element: <UnitPM />,
        },
        {
            value: 'dvir',
            label: 'DVIR',
            element: <UnitDVIR />,
        },
        {
            value: 'issues',
            label: 'Issues',
            element: <UnitIssues />,
        },
        {
            value: 'meters',
            label: 'Meters',
            element: <UnitMeters />,
        },
        {
            value: 'notes',
            label: 'Notes',
            element: <UnitNotes />,
        },
        documentsTab({
            elementProps: {
                readOnly: isArchived,
                resource: record
                    ? {
                          name: 'unit-documents',
                          resource: resource + '/' + record?.id + '/documents',
                      }
                    : null,
            },
        }),
        ...(flag4775UnitExpirations
            ? [
                  {
                      value: 'unit-expirations',
                      label: 'Expirations',
                      element: <UnitExpirations readOnly={isArchived} />,
                  },
              ]
            : []),
        flag4975UnitCostSummaryReport
            ? {
                  value: 'cost-report/*',
                  path: `cost-report/${getCurrentYear()}`,
                  label: 'Cost Report',
                  element: (
                      <DynamicRoutes
                          path=":year"
                          element={<UnitCostReport />}
                      />
                  ),
              }
            : null,
    ])

    return (
        <>
            <UnitShowHeader tabs={tabs} />
            <Routes>{routes}</Routes>
            <UnitTrackActions />
        </>
    )
}
