import { type MouseEventHandler, type FC } from 'react'

import Icons from 'assets/icons'
import { IconBox } from 'ui'

interface Props {
    onClick?: MouseEventHandler<HTMLDivElement>
    disabled?: boolean
}

const AddButton: FC<Props> = ({ onClick, disabled }) => {
    return (
        <IconBox
            title="Add"
            disabled={disabled}
            onClick={onClick}
        >
            <Icons.Add />
        </IconBox>
    )
}

export default AddButton
