import { type FC } from 'react'

import { config } from 'configs'
import { useMediaQuery, useFlags } from 'lib'
import { Box, ButtonBase, Tooltip, BoxContainer, StyledElement, Button } from 'ui'

import LogoSection from '../LogoSection'
import { useSidebarContext } from '../Sidebar'

import { DefaultRSButton } from './DefaultRSButton'
import MenuButton from './MenuButton'
import { HeaderLayoutContainer } from './styled'

const AppHeader: FC = () => {
    const { toggle } = useSidebarContext()
    const flags = useFlags()
    const isVisible = useMediaQuery((theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    if (isVisible) {
        return (
            <StyledElement
                sx={{
                    width: '100%',
                }}
            >
                <HeaderLayoutContainer>
                    <Box component="span">
                        <LogoSection />
                    </Box>
                    {/* FP-1713 Show fleetpal-version on mobile -> to be deleted */}
                    {flags.showVersionInMobileMode && (
                        <Box component="span">
                            <Tooltip title={`Current Version:${config.VERSION}`}>
                                <Button variant="text">Version</Button>
                            </Tooltip>
                        </Box>
                    )}
                    {/* FP-1713 ------------- to be deleted */}
                    <BoxContainer>
                        <DefaultRSButton />
                        <ButtonBase
                            aria-label="Open Sidebar"
                            onClick={toggle}
                            sx={{
                                width: '56px',
                                height: '56px',
                            }}
                        >
                            <MenuButton />
                        </ButtonBase>
                    </BoxContainer>
                </HeaderLayoutContainer>
            </StyledElement>
        )
    }
    return null
}

export default AppHeader
