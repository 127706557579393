import { formatPercent } from 'components'
import { type PtServicesModel } from 'resources/pricingTiers'

const source = 'markupPercent' as const satisfies keyof PtServicesModel

const label = 'Markup'

const value = (record: Pick<PtServicesModel, 'markupPercent'>) => {
    return formatPercent(record.markupPercent, undefined, true)
}

export const markup = {
    source,
    label,
    value,
}
