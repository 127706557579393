import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core'
import { PMIntervalStatusKeys } from 'resources/pm'
import { reportsUrls, createReportResource } from 'resources/reports'
import { defaultDomicileFilter } from 'resources/units'
import { BoxContainer, StatusText } from 'ui'

import { Monitor } from '../../MonitorComponents'
import { useMonitor } from '../useMonitor'

interface dataType {
    dueCount: number
    dueWorkOrdersCount: number
    overdueCount: number
    overdueWorkOrdersCount: number
    plannedCount: number
    plannedWorkOrdersCount: number
    total: number
}

const MonitorPM: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const data = useMonitor<dataType>(
            createReportResource('pm-schedules'),
            defaultDomicileFilter(auth.defaultShop),
        )
        if (!data) {
            return <Monitor.Skeleton />
        }

        const {
            dueCount,
            dueWorkOrdersCount,
            overdueCount,
            overdueWorkOrdersCount,
            plannedCount,
            plannedWorkOrdersCount,
        } = data
        const pmCountFormatter = (value: number) => {
            if (value < 1000) {
                return value
            }

            return `${Math.floor(value / 1000)} k`
        }
        const createLink = (status: PMIntervalStatusKeys) => {
            return (
                reportsUrls.intervals +
                '?filter=' +
                encodeURIComponent(
                    JSON.stringify({
                        status: [status],
                        ...defaultDomicileFilter(auth.defaultShop),
                    }),
                )
            )
        }
        return (
            <Monitor>
                <Monitor.Title icon={Icons.AccessAlarm}>PM Intervals</Monitor.Title>

                <Monitor.ArrowButton navigateTo={reportsUrls.intervals} />
                <Monitor.Container>
                    <Monitor.Button navigateTo={createLink(PMIntervalStatusKeys.OVERDUE)}>
                        <BoxContainer>
                            <Monitor.ValueText>{pmCountFormatter(overdueCount)}</Monitor.ValueText>
                            <Monitor.Counter image={Icons.ClipboardClock}>
                                {overdueWorkOrdersCount}
                            </Monitor.Counter>
                        </BoxContainer>
                        <StatusText statusColor={(theme) => theme.palette.charts.red}>
                            Overdue
                        </StatusText>
                    </Monitor.Button>
                    <Monitor.Button navigateTo={createLink(PMIntervalStatusKeys.DUE)}>
                        <BoxContainer>
                            <Monitor.ValueText>{pmCountFormatter(dueCount)}</Monitor.ValueText>
                            <Monitor.Counter image={Icons.ClipboardClock}>
                                {dueWorkOrdersCount}
                            </Monitor.Counter>
                        </BoxContainer>
                        <StatusText statusColor={(theme) => theme.palette.charts.orange}>
                            Due Soon
                        </StatusText>
                    </Monitor.Button>
                    <Monitor.Button navigateTo={createLink(PMIntervalStatusKeys.PLANNED)}>
                        <BoxContainer>
                            <Monitor.ValueText>{pmCountFormatter(plannedCount)}</Monitor.ValueText>
                            <Monitor.Counter image={Icons.ClipboardClock}>
                                {plannedWorkOrdersCount}
                            </Monitor.Counter>
                        </BoxContainer>
                        <StatusText
                            sx={{ width: 'fit-content' }}
                            statusColor={(theme) => theme.palette.charts.greenBody}
                        >
                            Planned
                        </StatusText>
                    </Monitor.Button>
                </Monitor.Container>
            </Monitor>
        )
    }),
)
export default MonitorPM
