import { TextInput } from 'components'
import { maxLengthValidation, requiredValidation } from 'core'

import { DefaultFormInput } from '../../components'
import { nameLabel, nameSource } from '../fields/name/name'

const PtPartForm = () => {
    return (
        <>
            <TextInput
                source={nameSource}
                label={nameLabel}
                validate={[requiredValidation, maxLengthValidation]}
            />
            <DefaultFormInput />
        </>
    )
}
export default PtPartForm
