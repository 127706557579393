import { type FC } from 'react'

import {
    ReferenceInput as RaReferenceInput,
    ReferenceArrayInput as RaReferenceArrayInput,
    type ReferenceInputProps,
    type ReferenceArrayInputProps,
} from 'react-admin'

import { useInputState } from 'core/inputs'

import { ReferenceInputMetaContextProvider } from './ReferenceInputMetaContext'

export const ReferenceInput: FC<ReferenceInputProps & { disabled?: boolean }> = (props) => {
    const { source, disabled } = useInputState(props.source, { disabled: props.disabled })
    const meta = { ...props.queryOptions?.meta, referenceInput: true }
    return (
        <ReferenceInputMetaContextProvider value={{ meta }}>
            <RaReferenceInput
                {...props}
                source={source}
                enableGetChoices={(...args) => {
                    if (disabled) {
                        return false
                    }

                    return props.enableGetChoices?.(...args)
                }}
                queryOptions={{
                    ...props.queryOptions,
                    meta,
                }}
            />
        </ReferenceInputMetaContextProvider>
    )
}

export const ReferenceArrayInput: FC<ReferenceArrayInputProps> = (props) => {
    const meta = { ...props.queryOptions?.meta, referenceInput: true }
    return (
        <ReferenceInputMetaContextProvider value={{ meta }}>
            <RaReferenceArrayInput
                {...props}
                queryOptions={{
                    ...props.queryOptions,
                    meta,
                }}
            />
        </ReferenceInputMetaContextProvider>
    )
}
