import { type FC } from 'react'

import { Header, InnerHeader, ListTotalBadge } from 'components'
import { ConnectedUnitStatus } from 'resources/units'
import { AutoGrid } from 'ui'

import { NewUnits } from '../../components'

import ConfigureButton, { type ConfigureButtonProps } from './ConfigureButton'

interface Props extends ConfigureButtonProps {}

const IntegrationsUnitListHeader: FC<Props> = ({ integration }) => {
    return (
        <InnerHeader
            backTo={false}
            rightAside={<ConfigureButton integration={integration} />}
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Units from {integration.providerName}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        <AutoGrid.Column>
                            <ListTotalBadge renderContent={() => integration.vehiclesTotal} />
                        </AutoGrid.Column>
                        <AutoGrid.Column>
                            <ConnectedUnitStatus type="sync">
                                {integration.vehiclesSynced}
                            </ConnectedUnitStatus>
                        </AutoGrid.Column>
                        <AutoGrid.Column>
                            <ConnectedUnitStatus type="error">
                                {integration.vehiclesWithError}
                            </ConnectedUnitStatus>
                        </AutoGrid.Column>
                        <AutoGrid.Column>
                            <ConnectedUnitStatus type="notImported">
                                {integration.vehiclesNotSynced}
                            </ConnectedUnitStatus>
                        </AutoGrid.Column>
                    </AutoGrid.Row>

                    <NewUnits integration={integration} />
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default IntegrationsUnitListHeader
