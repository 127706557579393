import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { type AuthStore } from 'core/auth'
import { globalClassNames, useMediaQuery } from 'lib'
import { type ShopModel } from 'resourcesBase'
import {
    DataAvatar,
    SimpleScrollbar,
    Typography,
    BoxContainer,
    StyledElement,
    Menu,
    MenuItem,
    Stack,
    type TypographyProps,
} from 'ui'

import avatar from '../fields/avatar'

const noShopText = 'None'

interface SidebarDefaultRSMenuProps {
    anchorEl: HTMLElement | null
    onClose: () => void
}

export const DefaultRSMenu = inject('auth')(
    observer(({ auth, anchorEl, onClose }: SidebarDefaultRSMenuProps & { auth?: AuthStore }) => {
        const open = Boolean(anchorEl)
        const matches = useMediaQuery((theme) =>
            theme.breakpoints.up(theme.props.mobileViewBreakpoint),
        )
        const shops = auth.user.membership.shops
        const defaultShop = auth.defaultShop
        const defaultShopId = defaultShop?.id

        const setShop = (shopId: Identifier | null) => {
            auth.setDefaultShop(shopId)
            onClose()
        }

        return (
            <Menu
                anchorOrigin={
                    matches
                        ? { vertical: 'center', horizontal: 'right' }
                        : { vertical: 'bottom', horizontal: 'right' }
                }
                transformOrigin={
                    matches
                        ? { vertical: 'bottom', horizontal: 'left' }
                        : { vertical: 'top', horizontal: 'center' }
                }
                onClose={onClose}
                open={open}
                anchorEl={anchorEl}
            >
                <li>
                    <Typography
                        variant="subtitle2"
                        textTransform="uppercase"
                        color="text.secondary"
                        my="8px"
                        ml="12px"
                        component="div"
                    >
                        DEFAULT REPAIR SHOP
                    </Typography>
                    <SimpleScrollbar
                        sx={{
                            maxHeight: '330px',
                            [`& .simplebar-content`]: {
                                width: '300px',
                            },
                        }}
                    >
                        <StyledElement
                            as="ul"
                            sx={{ padding: 0 }}
                        >
                            {shops.length > 1 && (
                                <MenuItem
                                    onClick={() => setShop(null)}
                                    selected={!defaultShop}
                                >
                                    <BoxContainer gap="8px">
                                        <DataAvatar
                                            sx={{
                                                '& > *': {
                                                    color: '#FFFFFF !important',
                                                },
                                            }}
                                            defaultImage={<Icons.Shop />}
                                            avatarOpacity={0.54}
                                            color={(theme) => theme.palette.text.primary}
                                        />

                                        <Typography variant="menuItem">{noShopText}</Typography>
                                    </BoxContainer>
                                </MenuItem>
                            )}
                            {shops.map((shop) => (
                                <MenuItem
                                    selected={shop.id === defaultShopId}
                                    onClick={() => setShop(shop.id)}
                                    key={shop.id}
                                >
                                    <Stack
                                        gap="8px"
                                        direction="row"
                                        alignItems="center"
                                        overflow="hidden"
                                    >
                                        <DataAvatar
                                            defaultImage={<avatar.Icon record={shop} />}
                                            avatarOpacity={1}
                                            color={shop.avatarColor}
                                        />
                                        <Stack overflow="hidden">
                                            <EllipsisTypography variant="menuItem">
                                                {shop.name}
                                            </EllipsisTypography>
                                            <EllipsisTypography variant="chartsBody">
                                                <AddressRow record={shop} />
                                            </EllipsisTypography>
                                            <EllipsisTypography variant="chartsBody">
                                                <CityStateZipRow record={shop} />
                                            </EllipsisTypography>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </StyledElement>
                    </SimpleScrollbar>
                </li>
            </Menu>
        )
    }),
)

const EllipsisTypography = (props: TypographyProps) => {
    return (
        <Typography
            {...props}
            className={globalClassNames.ellipsis}
        />
    )
}
const getAddressValue = (shop: Pick<ShopModel, 'address' | 'address2'>): string =>
    [shop.address, shop.address2].filter(Boolean).join(', ')

const AddressRow = ({ record }: { record: ShopModel }) => {
    return <>{getAddressValue(record)}</>
}

const getCityStateZipValue = (shop: Pick<ShopModel, 'city' | 'zipCode' | 'stateData'>): string =>
    [shop.city, shop.stateData?.name, shop.zipCode].filter(Boolean).join(', ')

const CityStateZipRow = ({ record }: { record: ShopModel }) => {
    return <>{getCityStateZipValue(record)}</>
}
