import { type Identifier, type BaseModel } from 'appTypes'
import { type Photos } from 'components'
import { type NoteModel } from 'resources/notes'
import { type UnitModel } from 'resources/units'
import { type CK14Model, type CK18Model, type CK33Model } from 'resources/vmrs'
import { type WorkOrderModel } from 'resources/workOrders'

export interface IssueModel extends BaseModel, Photos {
    complaint: Identifier
    complaintData: CK18Model
    component: Identifier
    componentData: CK33Model
    created: string
    description: string
    name: string
    priority: IssuePriorityKeys
    reported: string
    reasonForRepair: Identifier
    reasonForRepairData: CK14Model
    status: IssueStatusKeys
    unit: Identifier
    unitData: Pick<
        UnitModel,
        'id' | 'number' | 'archived' | 'customerData' | 'customer' | 'domicile' | 'domicileData'
    >
    updated: string
    workOrderData: WorkOrderModel
    notes: NoteModel[]
    reasonClosed?: string
    workOrder: Identifier
}

export const enum IssueStatusKeys {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    IN_PROGRESS = 'IN_PROGRESS',
}

export const enum IssuePriorityKeys {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}
