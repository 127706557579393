import { type DataRecord, type NoInfer } from 'appTypes'
import { type SortBy, type Column, type ListFilterChoice, type DataListDetails } from 'components'
import { createResource } from 'core'

import { type CK34Model } from './types'

export const displayCK34 = (record: CK34Model) => (record ? record.description : '')

export const ck34Resource = createResource({
    resource: 'vmrs/ck34',
    name: 'ck34',
})

const source = 'manufacturer' as const
const label = 'Manufacturer'

const column = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToRecord,
}: {
    id?: Source
    label?: string
    dataToRecord: (data: DataRecord) => CK34Model
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => displayCK34(dataToRecord(row)),
    }) as const satisfies Column

const cardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CK34Model
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => displayCK34(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies SortBy

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies ListFilterChoice

export const CK34Field = { column, cardRow, sort, filter }
