import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    type RenderWrapperProps,
    UtilityDrawerForm,
    useOpenUtilityDrawer,
    useUtilityDrawerContext,
} from 'components'
import { serialize, type Serializer, useSubmit, useNotify, useConfirm } from 'core'
import api from 'core/api'
import { woResource } from 'resources/workOrders'
import { pathJoin } from 'utils'

import AddVendorForm from './AddVendorForm'

interface AddVendorDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    id: Identifier
}

const addVendorSerializer: Serializer = [{ name: 'vendor', parse: 'string' }]

const AddVendorFormWrapper = ({ id, ...params }: RenderWrapperProps & { id: Identifier }) => {
    const { forceClose } = useUtilityDrawerContext()
    const confirm = useConfirm()
    const notify = useNotify()
    const handleSubmit = useSubmit(async (formData) => {
        confirm({
            title: 'Add Vendor',
            content: 'You are about to switch to a Vendor Work Order. This cannot be undone.',
            onConfirm: async () => {
                await api
                    .patch(
                        pathJoin(woResource.resource, String(id)),
                        serialize(formData, addVendorSerializer),
                    )
                    .finally(() => {
                        notify('Successfully created')
                        forceClose()
                    })
            },
        })
    })

    return (
        <UtilityDrawerForm
            {...params}
            disableCloseOnSubmit
            onSubmit={handleSubmit}
        />
    )
}
const AddVendorDrawerToggler = ({ id, children }: AddVendorDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()

    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Add Vendor',
                    renderWrapper: (params) => (
                        <AddVendorFormWrapper
                            {...params}
                            id={id}
                        />
                    ),
                    renderContent: () => <AddVendorForm />,
                    renderBottomRight: (render) =>
                        render({
                            icon: <Icons.Add />,
                            label: 'Add',
                        }),
                },
            })
        },
    })
}

export default AddVendorDrawerToggler
