import { type FC } from 'react'

import { AutocompleteInput, type AutocompleteInputProps } from 'components'
import { ReferenceInput } from 'core'

import { ck34Resource, displayCK34 } from '../utils'

interface Props extends Pick<AutocompleteInputProps, 'label' | 'source' | 'disabled'> {}

export const CK34Input: FC<Props> = ({
    source = 'manufacturer',
    disabled,
    label = 'Manufacturer',
}) => {
    return (
        <ReferenceInput
            reference={ck34Resource.resource}
            source={source}
            disabled={disabled}
            perPage={100}
        >
            <AutocompleteInput
                disabled={disabled}
                optionText={displayCK34}
                label={label}
            />
        </ReferenceInput>
    )
}
