import Icons from 'assets/icons'
import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { CostPerCategoryClarification } from '../CostPerCategoryWidget/components'
import { roundTop3WidgetData, roundWidgetJobsData } from '../utils'

import { CostPerVMRSChart, TopCategories } from './components'
import { type QueryResponse, type ResponseType, type listType } from './types'

const description = `Drill down further into maintenance costs, 
by exploring the VMRS Groups which account for most of the expenses.`
let validData = false
let hasData = false
const CostPerVMRSWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-group'),
        sideEffect: (data) => {
            const finalData = roundWidgetJobsData(data)
            const { maxTotalGroups, ...ratioData } = finalData as unknown as ResponseType

            const { listData, positiveDataTotal, ...listExtras } = roundTop3WidgetData(
                maxTotalGroups,
                { allowNegative: true },
            )
            hasData = typeof ratioData.total === 'number'
            validData =
                ratioData.total > 0 &&
                listExtras.top3Percentage >= 0 &&
                typeof listExtras.top3Percentage === 'number'

            const list: listType = {
                ...listExtras,
                data: listData,
                sumOfExcludedGroups: positiveDataTotal - listExtras.top3Total,
            }
            return {
                ...ratioData,
                list,
            }
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Top 3 VMRS Groups Account for',
                label: hasData ? (validData ? `${list.top3Percentage}%` : 'N/A') : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        of all Maintenance Costs
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerVMRSChart
                        list={list}
                        disabled={!validData}
                        total={ratioData.total}
                        icon={Icons.CostPerVMRSGroup}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <TopCategories
                        list={list}
                        description={description}
                    />
                ),
            }}
            separator="Top 3 VMRS groups"
            link={createLink(reportsUrls.costPerVMRSGroup)}
        />
    )
}
export default CostPerVMRSWidget
