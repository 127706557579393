import { TextInput, percentageMaskParams } from 'components'
import {
    maxLengthValidation,
    requiredValidation,
    validateMaxLength,
    validateNonNegative,
} from 'core'

import { DefaultFormInput } from '../../components'
import { markup } from '../fields/markup'
import { nameLabel, nameSource } from '../fields/tierName/name'

const nameValidation = [requiredValidation, maxLengthValidation]
const markupValidator = [
    requiredValidation,
    validateNonNegative(),
    validateMaxLength(10, 'Invalid value'),
]

const PtServiceForm = () => {
    return (
        <>
            <TextInput
                source={nameSource}
                label={nameLabel}
                validate={nameValidation}
            />
            <TextInput
                source={markup.source}
                label={markup.label}
                validate={markupValidator}
                {...percentageMaskParams}
            />
            <DefaultFormInput />
        </>
    )
}
export default PtServiceForm
