import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListBase,
    ListAvatar,
    CardCollapsibleContent,
    ListFilterValueInput,
    type FilterConfig,
    type ListSortContentProps,
    type ListBulkActions,
    type ListFilterChoices,
    phoneMaskResolver,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { type SortPayload } from 'core'
import {
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    multiselectAction,
} from 'core/actions'
import { formatDate } from 'lib'
import { addressFields } from 'resources/address'
import { countryFields } from 'resources/country'
import { displayPaymentMethod, paymentMethodFilter } from 'resources/paymentMethods'
import { displayPaymentTerm, paymentTermFilter } from 'resources/paymentTerms'
import { stateFields } from 'resources/state'
import { TagsCollapsibleContent, TagsCollapsibleIconHolder, TagsField } from 'resources/tags'
import {
    type VendorModel,
    formatVendorTypes,
    formatVendorType,
    type VendorType,
    vendorFields,
    VendorDrawerToggler,
} from 'resources/vendors'
import { PageContent, Typography } from 'ui'

import { deleteVendorParams } from '../constants/config'

import { VendorListHeader } from './components'

const defaultSort: SortPayload<VendorModel> = {
    field: 'created',
    order: 'DESC',
}

const sortConfig: ListSortContentProps<VendorModel> = {
    sortBy: [
        {
            id: 'created',
            label: 'Created on',
        },
        {
            id: 'name',
            label: 'Vendor Name',
        },
        stateFields.self.sort(),
    ],
}

const filtersConfig: FilterConfig<VendorModel> = {
    filters: [
        {
            id: 'type',
            label: 'Vendor Type',
            renderComponent: (params) => (
                <ListFilterValueInput
                    {...params}
                    makeItemLabel={(option) => formatVendorType(option.id as VendorType)}
                />
            ),
        },
        stateFields.self.filter(),
        { id: 'city', label: 'City' },
        paymentMethodFilter({ label: 'Default Payment Method' }),
        paymentTermFilter({ label: 'Default Payment Term' }),
        { id: 'tags', label: 'Tags' },
    ],
}

export const vendorFilters = filtersConfig.filters.reduce(
    (filters, filter) => {
        filters[filter.id] = filter
        return filters
    },
    {} as { [key in keyof VendorModel]: ListFilterChoices<VendorModel>[0] },
)

const bulkActions: ListBulkActions<VendorModel> = ({ children }) => [
    deleteManyFromListAction({
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Vendors?',
        },
        children,
    }),
]
const columnsConfig: DatagridColumnsProps<VendorModel> = {
    resetColumns: {
        nationalAccount: false,
        paymentMethod: false,
        paymentTerm: false,
        phone: false,
        email: false,
        website: false,
        created: false,
        tagsData: false,
        [countryFields.self.source]: false,
    },
    constantColumns: {
        name: true,
    },
    columns: [
        {
            field: 'logo',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: (cell) => (
                <ListAvatar
                    linkProps={{ 'aria-label': `View Vendor ${cell.row.name}` }}
                    id={cell.id}
                    imageSrc={cell.value}
                    defaultImage={<vendorFields.avatar.Icon />}
                />
            ),
        },
        {
            field: 'name',
            headerName: 'Vendor Name',
            renderCell: (params) => <DatagridLink {...params} />,
        },
        {
            field: 'type',
            headerName: 'Vendor Type',
            valueFormatter: ({ value }) => formatVendorTypes(value),
        },
        addressFields.address1.tableColumn({}),
        addressFields.address2.tableColumn({}),
        countryFields.self.tableColumn({
            dataToRecord: (record: VendorModel) => record.countryData,
        }),
        stateFields.self.tableColumn({ dataToRecord: (record: VendorModel) => record.stateData }),
        {
            field: 'city',
            headerName: 'City',
        },
        { field: 'zipCode', headerName: 'ZIP/Postal Code' },
        {
            field: 'taxId',
            headerName: 'Tax ID',
        },
        {
            field: 'nationalAccount',
            headerName: 'National Account',
        },
        {
            field: 'paymentMethod',
            headerName: 'Default Payment Method',
            renderCell: ({ row }) => displayPaymentMethod(row.paymentMethod),
        },
        {
            field: 'paymentTerm',
            headerName: 'Default Payment Term',
            renderCell: ({ row }) => displayPaymentTerm(row.paymentTermData),
        },
        {
            field: 'phone',
            headerName: 'Company Phone',
            valueFormatter: ({ value }) => phoneMaskResolver(value),
        },
        {
            field: 'email',
            headerName: 'Company Email',
        },
        {
            field: 'website',
            headerName: 'Website',
        },
        {
            field: 'created',
            headerName: 'Created on',
            flex: 1,
            valueFormatter: ({ value }) => {
                return formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'tagsData',
            headerName: 'Tags',
            renderCell: ({ value }) => <TagsField tags={value} />,
        },
    ],
    actions: ({ row }, { children }) => [
        editRedirectInListAction({
            children,
            id: row.id,
        }),
        deleteOneAction({
            children,
            id: row.id,
            ...deleteVendorParams,
        }),
    ],
}

const cardsConfig: CardListConfig<VendorModel> = {
    titleSource: 'name',
    subTitleSource: (record) => (
        <Typography
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            color="text.primary"
        >
            {formatVendorTypes(record.type)}
        </Typography>
    ),
    imageSource: 'logo',
    defaultImage: <vendorFields.avatar.Icon />,
    details: [
        addressFields.address1.dataCardRow({}),
        addressFields.address2.dataCardRow({}),
        stateFields.self.dataCardRow({ id: 'state', dataToRecord: (data) => data.stateData }),
        {
            label: 'City',
            source: 'city',
        },
        { label: 'ZIP/Postal Code', source: 'zipCode' },
        {
            label: 'Tax ID',
            source: 'taxId',
        },
    ],
    collapsibleContent: (record) => (
        <CardCollapsibleContent
            content={[
                {
                    iconHolder: (
                        <TagsCollapsibleIconHolder
                            tags={record.tagsData}
                            key="tagsIcon"
                        />
                    ),
                    component: (
                        <TagsCollapsibleContent
                            tags={record.tagsData}
                            key="tagsContent"
                        />
                    ),
                },
            ]}
        />
    ),
    actions: ({ id }, { children }) => [
        editRedirectInListAction({
            children,
            id,
        }),
        multiselectAction({
            children,
            id,
        }),
        deleteOneAction({
            children,
            id,
            ...deleteVendorParams,
        }),
    ],
}

const VendorsList = () => {
    return (
        <ListBase sort={defaultSort}>
            <VendorListHeader />
            <PageContent>
                <List
                    bulkActions={bulkActions}
                    sortCfg={sortConfig}
                    columnsCfg={columnsConfig}
                    cardsCfg={cardsConfig}
                    filtersCfg={filtersConfig}
                    listFTUProps={{
                        secondaryTitle: 'Would you like to create one?',
                        linkText: (
                            <VendorDrawerToggler>
                                {(onClick) => (
                                    <Typography
                                        variant="body1"
                                        onClick={onClick}
                                        color={(theme) => theme.palette.primary.main}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Create Vendor{' '}
                                    </Typography>
                                )}
                            </VendorDrawerToggler>
                        ),
                        linkAction: (e) => {
                            e.preventDefault()
                        },
                    }}
                />
            </PageContent>
        </ListBase>
    )
}

export default VendorsList
