import {
    type CardListConfig,
    List,
    ListBase,
    type DatagridColumnsProps,
    ListAvatar,
    type ListSortContentProps,
    type FilterConfig,
    type ListBulkActions,
    CardCollapsibleContent,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { type SortPayload } from 'core'
import {
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    multiselectAction,
} from 'core/actions'
import { addressFields } from 'resources/address'
import { countryFields } from 'resources/country'
import { customerFields, CustomersDrawerToggler, type CustomerModel } from 'resources/customers'
import { displayPaymentMethod, paymentMethodFilter } from 'resources/paymentMethods'
import { displayPaymentTerm } from 'resources/paymentTerms'
import { stateFields } from 'resources/state'
import { TagsCollapsibleContent, TagsCollapsibleIconHolder } from 'resources/tags'
import { PageContent, Typography } from 'ui'

import { deleteCustomerAction, deleteCustomerBulkAction } from '../config'

import { ListHeader } from './components'

const cardsCfg: CardListConfig = {
    titleSource: (record) => <customerFields.name.Value record={record} />,
    imageSource: customerFields.avatar.source,
    defaultImage: <customerFields.avatar.Icon />,
    details: [
        addressFields.address1.dataCardRow({}),
        addressFields.address2.dataCardRow({}),
        stateFields.self.dataCardRow({ id: 'state', dataToRecord: (data) => data.stateData }),
        addressFields.city.dataCardRow({}),
        addressFields.zipCode.dataCardRow({}),
        {
            source: customerFields.taxId.source,
            label: customerFields.taxId.label,
        },
        {
            source: customerFields.dotNumber.source,
            label: customerFields.dotNumber.label,
        },
        {
            source: customerFields.units.source,
            label: customerFields.units.label,
        },
    ],
    collapsibleContent: (record) => (
        <CardCollapsibleContent
            content={[
                {
                    iconHolder: (
                        <TagsCollapsibleIconHolder
                            tags={record.tagsData}
                            key="tagsIcon"
                        />
                    ),
                    component: (
                        <TagsCollapsibleContent
                            tags={record.tagsData}
                            key="tagsContent"
                        />
                    ),
                },
            ]}
        />
    ),
    actions: ({ id }, { children }) => [
        editRedirectInListAction({
            children,
            id,
        }),
        multiselectAction({
            children,
            id,
        }),
        deleteOneAction({
            children,
            id,
            ...deleteCustomerAction,
        }),
    ],
}

const columnsCfg: DatagridColumnsProps<CustomerModel & { cityStateZip: string }> = {
    resetColumns: {
        [customerFields.paymentMethod.source]: false,
        [customerFields.paymentTerm.source]: false,
        [customerFields.phone.source]: false,
        [customerFields.email.source]: false,
        [customerFields.website.source]: false,
        [customerFields.created.source]: false,
        [customerFields.tags.source]: false,
        [countryFields.self.source]: false,
    },
    constantColumns: {
        name: true,
    },
    columns: [
        {
            field: customerFields.avatar.source,
            headerName: customerFields.avatar.label,
            maxWidth: 72,
            renderCell: (cell) => (
                <ListAvatar
                    id={cell.id}
                    imageSrc={cell.value}
                    defaultImage={<customerFields.avatar.Icon />}
                />
            ),
        },
        {
            field: customerFields.name.source,
            headerName: customerFields.name.label,
            renderCell: (params) => <DatagridLink {...params} />,
        },
        addressFields.address1.tableColumn({}),
        addressFields.address2.tableColumn({}),

        countryFields.self.tableColumn({
            dataToRecord: (record: CustomerModel) => record.countryData,
        }),
        stateFields.self.tableColumn({ dataToRecord: (record: CustomerModel) => record.stateData }),
        addressFields.city.tableColumn({}),
        addressFields.zipCode.tableColumn({}),

        {
            field: customerFields.taxId.source,
            headerName: customerFields.taxId.label,
        },
        {
            field: customerFields.dotNumber.source,
            headerName: customerFields.dotNumber.label,
        },
        {
            field: customerFields.units.source,
            headerName: customerFields.units.label,
        },
        {
            field: customerFields.paymentMethod.source,
            headerName: customerFields.paymentMethod.label,
            renderCell: ({ row }) => displayPaymentMethod(row.paymentMethod),
        },
        {
            field: customerFields.paymentTerm.source,
            headerName: customerFields.paymentTerm.label,
            renderCell: ({ row }) => displayPaymentTerm(row.paymentTermData),
        },
        {
            field: customerFields.phone.source,
            headerName: customerFields.phone.label,
            renderCell: (cell) => <customerFields.phone.Value record={cell.row} />,
        },
        {
            field: customerFields.email.source,
            headerName: customerFields.email.label,
        },
        {
            field: customerFields.website.source,
            headerName: customerFields.website.label,
            renderCell: (cell) => customerFields.website.value(cell.row),
        },
        {
            field: customerFields.created.source,
            headerName: customerFields.created.label,
            renderCell: (cell) => <customerFields.created.Value record={cell.row} />,
        },
        {
            field: customerFields.tags.source,
            headerName: customerFields.tags.label,
            renderCell: (cell) => <customerFields.tags.Value record={cell.row} />,
        },
    ],
    actions: ({ row: customer }, { children }) => [
        editRedirectInListAction({
            children,
            id: customer.id,
        }),
        deleteOneAction({
            children,
            id: customer.id,
            ...deleteCustomerAction,
        }),
    ],
}

const sortCfg: ListSortContentProps<CustomerModel> = {
    sortBy: [
        {
            id: customerFields.created.source,
            label: customerFields.created.label,
        },
        {
            id: customerFields.name.source,
            label: customerFields.name.label,
        },
        stateFields.self.sort(),
        {
            id: customerFields.units.source,
            label: customerFields.units.label,
        },
    ],
}

const filtersCfg: FilterConfig<CustomerModel> = {
    filters: [
        stateFields.self.filter(),
        addressFields.city.filter({}),
        paymentMethodFilter({ label: customerFields.paymentMethod.label }),
        {
            id: customerFields.paymentTerm.source,
            label: customerFields.paymentTerm.label,
        },
        { id: customerFields.tags.source, label: customerFields.tags.label },
    ],
}

const defaultSort: SortPayload = {
    field: customerFields.created.source,
    order: 'DESC',
}

const bulkActions: ListBulkActions<CustomerModel> = ({ children }) => [
    deleteManyFromListAction({
        children,
        ...deleteCustomerBulkAction,
    }),
]

const CustomersList = () => {
    return (
        <ListBase sort={defaultSort}>
            <ListHeader />
            <PageContent>
                <List
                    filtersCfg={filtersCfg}
                    sortCfg={sortCfg}
                    bulkActions={bulkActions}
                    columnsCfg={columnsCfg}
                    cardsCfg={cardsCfg}
                    listFTUProps={{
                        secondaryTitle: 'Would you like to create one?',
                        linkText: (
                            <CustomersDrawerToggler>
                                {(open) => (
                                    <Typography
                                        variant="body1"
                                        onClick={open}
                                        color={(theme) => theme.palette.primary.main}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Create Customer
                                    </Typography>
                                )}
                            </CustomersDrawerToggler>
                        ),
                        linkAction: (e) => {
                            e.preventDefault()
                        },
                    }}
                />
            </PageContent>
        </ListBase>
    )
}

export default CustomersList
