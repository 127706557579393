import { type FC } from 'react'

import { AutocompleteInput, type AutocompleteInputProps } from 'components'
import { ReferenceInputCreateLayer, ReferenceInput, requiredValidation } from 'core'
import { globalClassNames } from 'lib'
import { Typography, BoxContainer, DataAvatar } from 'ui'

import customerFields from '../dataConfig'
import { type CustomerModel } from '../types'
import { customersResource } from '../utils'

import { CustomersDrawerToggler } from './CustomersDrawerToggler'

interface Props extends Pick<AutocompleteInputProps, 'defaultValue'> {
    source?: string
    label?: string
    required?: boolean
    disabled?: boolean
}

export const CustomerInput: FC<Props> = ({
    label = 'Customer',
    source = 'customer',
    required,
    disabled,
    defaultValue,
}) => {
    return (
        <ReferenceInput
            reference={customersResource.resource}
            source={source}
            disabled={disabled}
            perPage={100}
        >
            <ReferenceInputCreateLayer>
                {({ setValue }) => {
                    return (
                        <AutocompleteInput
                            disabled={disabled}
                            defaultValue={defaultValue}
                            renderCreateOption={(renderOption) => (
                                <CustomersDrawerToggler
                                    shortSuccessMessage
                                    onSuccess={(record) => {
                                        setValue(record.id)
                                    }}
                                    children={(onClick) =>
                                        renderOption({
                                            children: '+ Create Customer',
                                            onClick,
                                        })
                                    }
                                />
                            )}
                            label={label}
                            optionText={optionText}
                            inputText={inputText}
                            validate={required ? requiredValidation : undefined}
                        />
                    )
                }}
            </ReferenceInputCreateLayer>
        </ReferenceInput>
    )
}

const optionText = (record: CustomerModel) => {
    return (
        <BoxContainer gap="8px">
            <DataAvatar
                defaultImage={<customerFields.avatar.Icon />}
                imageSrc={record.logo}
                sx={{ width: 42, height: 42 }}
            />
            <div className={globalClassNames.ellipsis}>
                <Typography
                    variant="body1"
                    component="span"
                    className={globalClassNames.ellipsis}
                >
                    {record.name}
                </Typography>
                <DescriptionRow>
                    {[record.address, record.address2].filter(Boolean).join(', ')}
                </DescriptionRow>
                <DescriptionRow>
                    {[record.city, record.state, record.zipCode].filter(Boolean).join(', ')}
                </DescriptionRow>
            </div>
        </BoxContainer>
    )
}

const inputText = (record: CustomerModel) => {
    // This is for the Create layer. It returns undefined for 'name' and that throws an error. TODO
    if (!record.name) {
        return record.id
    }
    return record.name
}

const DescriptionRow: FC<{ children: string }> = ({ children }) => {
    if (!children) {
        return null
    }

    return (
        <Typography
            variant="chartsBody"
            color="text.secondary"
            display="block"
            className={globalClassNames.ellipsis}
        >
            {children}
        </Typography>
    )
}
