import { type ReactNode } from 'react'

import { type CreateProps, CreateBase, useCreate } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { Form, type FormProps } from 'components/form'
import { useFormActionContext } from 'context'
import { type DataProviderMeta } from 'core/data'
import { type UseSubmitExtraArgs, useSubmit } from 'core/form'
import { useCreateResourcePath, type ResourceType, useResource } from 'core/resource'
import { useNavigate } from 'lib'

import { makeSubmit, type makeSubmitConfig } from './common'

export interface CreateCustomProps<RecordType extends DataRecord = any>
    extends Omit<CreateProps<RecordType>, 'resource' | 'mutationOptions'>,
        makeSubmitConfig<RecordType>,
        Pick<FormProps, 'formOnError' | 'validate'>,
        Pick<UseSubmitExtraArgs, 'successMessage'> {
    children: ReactNode
    className?: string
    disableSuccessRedirect?: boolean
    defaultValues?: Partial<RecordType> | ((record: RecordType) => Partial<RecordType>)
    warnWhenUnsavedChanges?: boolean
    resource?: ResourceType
    redirect?: 'edit' | 'list'
    redirectInfo?: { resource?: ResourceType }
    meta?: DataProviderMeta
    disableResetCache?: boolean
}

type formRedirectReturnType = (
    redirect: CreateCustomProps['redirect'],
    resource: string,
    record: DataRecord,
) => void

const useCreateFormRedirect = (): formRedirectReturnType => {
    const navigate = useNavigate()
    const createPath = useCreateResourcePath()

    return (redirect, resource, record) => {
        const path = createPath({
            resource,
            type: redirect,
            id: record.id,
        })
        navigate(path)
    }
}

const Create = <RecordType extends DataRecord = any>({
    children,
    makeData,
    defaultValues,
    onSuccess,
    className,
    disableSuccessRedirect,
    successMessage,
    serializer,
    warnWhenUnsavedChanges,
    formOnError,
    redirect = 'list',
    redirectInfo = {},
    meta,
    validate,
    mutationOptions,
    ...rest
}: CreateCustomProps<RecordType>) => {
    const resource = useResource(rest.resource)
    const formRedirect = useCreateFormRedirect()
    const [create] = useCreate()
    const formAction = useFormActionContext()

    const submit = useSubmit(
        makeSubmit(create, {
            resource,
            makeData,
            onSuccess: (r) => {
                onSuccess?.(r)
                if (redirect) {
                    formRedirect(redirect, redirectInfo.resource?.resource || resource.resource, r)
                }
            },
            serializer,
            meta: getMeta(meta, formAction?.queryParams),
            mutationOptions,
        }),
        {
            successMessage: successMessage ?? (({ defaultMessages }) => defaultMessages.created),
        },
    )

    return (
        <CreateBase
            {...rest}
            resource={resource.resource}
        >
            <Form
                validate={validate}
                onSubmit={submit}
                className={className}
                defaultValues={defaultValues}
                warnWhenUnsavedChanges={warnWhenUnsavedChanges}
                disableSuccessRedirect={disableSuccessRedirect}
                formOnError={formOnError}
            >
                {children}
            </Form>
        </CreateBase>
    )
}

export default Create

const getMeta = (meta: any, queryParams: any) => {
    if (!queryParams) {
        return meta
    }
    if (!meta) {
        return { query: queryParams }
    }

    return {
        ...meta,
        query: { ...meta.query, ...queryParams },
    }
}
