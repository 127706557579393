import { type DataRecord } from 'appTypes'
import { serialize, type Serializer } from 'core/form'
import { type ResourceType } from 'core/resource'
import { type UseMutationOptions } from 'lib'

export interface makeSubmitConfig<RecordType extends DataRecord = any> {
    makeData?: (values: any) => object
    onSuccess?: (newRecord) => void
    resource?: ResourceType
    serializer?: Serializer<RecordType>
    meta?: any
    mutationOptions?: Omit<UseMutationOptions<RecordType>, 'returnPromise'>
}

// TODO: add better types
export const makeSubmit = <RecordType extends DataRecord = any>(
    mutateFunc,
    {
        resource,
        makeData,
        onSuccess,
        serializer,
        meta,
        mutationOptions,
    }: makeSubmitConfig<RecordType> = {},
) => {
    return async (values) => {
        const data = makeData ? makeData(values) : values

        const finalData = serialize<RecordType>(data, serializer)

        const id = values.id

        const record = await mutateFunc(
            resource.resource,
            {
                data: finalData,
                meta,
                ...(id && { id }),
            },
            {
                returnPromise: true,
                ...mutationOptions,
            },
        )
        onSuccess?.(record)

        return record
    }
}
