import { type DataRecord } from 'appTypes'
import { extendRecord } from 'core/data'
import { globalClassNames } from 'lib'
import { Divider, ListItem, ListItemText, DataAvatar } from 'ui'

import { type DialogSelectorListProps } from './DialogSelectorList'

export interface DialogSelectorListItemProps<T extends DataRecord = any>
    extends Pick<DialogSelectorListProps<T>, 'itemPrimary' | 'itemSecondary' | 'itemAvatar'> {
    choice: T
    onSelect: (event: React.MouseEvent<HTMLElement>, choice: T) => void
}

const DialogSelectorListItem = <T extends DataRecord = any>({
    choice,
    onSelect,
    itemPrimary,
    itemSecondary,
    itemAvatar,
}: DialogSelectorListItemProps<T>) => {
    const secondaryText = extendRecord(choice, itemSecondary)

    return (
        <>
            <ListItem
                button
                role="option"
                component="li"
                sx={{ px: '20px', py: '6px' }}
                onClick={(e) => onSelect(e, choice)}
            >
                {itemAvatar && (
                    <DataAvatar
                        sx={{ width: '56px', height: '56px', mr: '12px' }}
                        {...extendRecord(choice, itemAvatar)}
                    />
                )}
                <ListItemText
                    className={globalClassNames.ellipsis}
                    primaryTypographyProps={{
                        sx: {
                            fontWeight: 'bold',
                        },
                        className: globalClassNames.ellipsis,
                    }}
                    secondaryTypographyProps={{
                        component: 'div',
                        className: globalClassNames.ellipsis,
                    }}
                    primary={extendRecord(choice, itemPrimary)}
                    secondary={
                        Array.isArray(secondaryText)
                            ? secondaryText.map((item, i) => <div key={i}>{item}</div>)
                            : secondaryText
                    }
                />
            </ListItem>
            <Divider />
        </>
    )
}
export default DialogSelectorListItem
