import { type FC } from 'react'

import Icons from 'assets/icons'
import { getContrastColor } from 'lib'
import { type ShopModel } from 'resourcesBase'
import { type TypographyProps, Typography } from 'ui'
import { getInitials } from 'utils'

const source = 'logo' as const satisfies keyof ShopModel

const avatarColorSource = 'avatarColor' as const satisfies keyof ShopModel

interface Props extends Pick<TypographyProps, 'variant'> {
    record: ShopModel
    fontWeight?: number
}

const DefaultShopAvatar: FC<Props> = ({ record, fontWeight, variant }) => {
    if (!record) {
        return <Icons.Shop />
    }
    const initials = getInitials(record.name)

    return (
        <Typography
            color={getContrastColor(record.avatarColor)}
            lineHeight="100%"
            height="fit-content"
            width="fit-content"
            fontWeight={fontWeight || 500}
            variant={variant}
        >
            {initials}
        </Typography>
    )
}

const avatar = {
    source,
    avatarColorSource,
    label: 'Avatar',
    avatarColorLabel: 'Avatar Color',
    Icon: DefaultShopAvatar,
}

export default avatar
