import { type FC } from 'react'

import { useWatch, useFormContext } from 'react-hook-form'

import { CheckboxInput, TextInput, TextareaInput, inputQuantityMaskParams } from 'components'
import { maxLengthValidationText, requiredValidation } from 'core'
import { PartInput } from 'resources/parts'
import { ShopInput } from 'resources/shops'
import { Stack, Typography, BoxContainer } from 'ui'

export interface InventoryItemFormContentProps {
    hidePartDetails?: boolean
}

const InventoryItemFormContent: FC<InventoryItemFormContentProps> = ({ hidePartDetails }) => {
    const trackInventory = useWatch({ name: 'trackInventory' })
    const { getValues } = useFormContext()

    return (
        <Stack>
            <Typography
                variant="subtitle1"
                mb="16px"
            >
                Repair Shop Details
            </Typography>
            <ItemShopInput />

            {hidePartDetails ? null : (
                <>
                    <Typography
                        variant="subtitle1"
                        mb="16px"
                    >
                        Part Details
                    </Typography>
                    <ItemPartInput />
                </>
            )}

            <Typography
                variant="subtitle1"
                mb="16px"
            >
                Quantity Threshold
            </Typography>
            <CheckboxInput
                source="trackInventory"
                label="Track Inventory"
                defaultValue={
                    typeof (getValues('minQuantity') || getValues('maxQuantity')) === 'number'
                }
            />
            <BoxContainer gap="20px">
                <TextInput
                    source="minQuantity"
                    label="Min QTY"
                    disabled={!trackInventory}
                    {...inputQuantityMaskParams}
                />
                <TextInput
                    source="maxQuantity"
                    label="Max QTY"
                    disabled={!trackInventory}
                    {...inputQuantityMaskParams}
                />
            </BoxContainer>
            <Typography
                variant="subtitle1"
                mb="16px"
            >
                Bin Location
            </Typography>
            <TextInput
                source="binLocationId"
                label="Bin Location ID"
            />
            <TextareaInput
                source="binDescription"
                label="Bin Description"
                validate={maxLengthValidationText}
            />
        </Stack>
    )
}

export default InventoryItemFormContent

const ItemShopInput: FC = () => {
    const { getValues, watch, setValue } = useFormContext()
    const id = getValues('id')
    const part = watch('part') || getValues('part')

    return (
        <ShopInput
            contextType="inventory-items"
            disabled={Boolean(id)}
            validate={requiredValidation}
            filter={part ? { availableForInventoryItemWithPart: part } : undefined}
            defaultValueIfExist={{
                onSelect: () => {
                    setValue('partsEnabled', true)
                },
            }}
        />
    )
}

const ItemPartInput: FC = () => {
    const { getValues, watch } = useFormContext()
    const shop = watch('shop') || getValues('shop')
    const partsEnabled = watch('partsEnabled') || getValues('partsEnabled')

    return (
        <PartInput
            contextType="inventory-items"
            referenceFilter={shop ? { availableForInventoryItemWithShop: shop } : undefined}
            resettable
            disabled={!partsEnabled}
        />
    )
}
