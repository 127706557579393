import { useState } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core/auth'
import { globalClassNames } from 'lib'
import { shopFields, DefaultRSMenu } from 'resources/shops'
import { DataAvatar, Typography, BoxContainer, StyledElement } from 'ui'

import SidebarHide from '../SidebarHide'
import SidebarTooltip from '../SidebarTooltip'

export const SidebarDefaultRS = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

        const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handleMenuClose = () => {
            setAnchorEl(null)
        }

        return (
            <>
                <BoxContainer
                    sx={{
                        px: '16px',
                        minHeight: '48px',
                        cursor: 'pointer',
                    }}
                    onClick={handleMenuClick}
                >
                    <SidebarTooltip
                        title="Default Repair Shop"
                        placement="right"
                        arrow
                    >
                        <DataAvatar
                            sx={{
                                width: 32,
                                height: 32,
                                '& > *': auth.defaultShop
                                    ? undefined
                                    : {
                                          width: 20,
                                          height: 20,
                                          color: '#FFFFFF !important',
                                      },
                            }}
                            defaultImage={
                                <shopFields.avatar.Icon
                                    record={auth.defaultShop}
                                    fontWeight={400}
                                    variant="body2"
                                />
                            }
                            avatarOpacity={auth.defaultShop ? 1 : 0.54}
                            color={(theme) =>
                                auth.defaultShop?.avatarColor || theme.palette.text.primary
                            }
                        />
                    </SidebarTooltip>

                    <SidebarHide>
                        <BoxContainer
                            justifyContent="space-between"
                            width="100%"
                        >
                            <StyledElement sx={{ display: 'grid', pr: '16px' }}>
                                <Typography
                                    variant="menuItem"
                                    sx={{
                                        marginLeft: '28px',
                                    }}
                                    className={globalClassNames.ellipsis}
                                >
                                    {auth.defaultShop ? auth.defaultShop.name : 'None'}
                                </Typography>
                            </StyledElement>
                            <Icons.ArrowDropDownOutlined
                                sx={{
                                    color: (theme) => theme.palette.text.primary,
                                    opacity: 0.54,
                                }}
                            />
                        </BoxContainer>
                    </SidebarHide>
                </BoxContainer>
                <DefaultRSMenu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                />
            </>
        )
    }),
)
