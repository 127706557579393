import { TextInput, percentageMaskParams } from 'components'
import {
    maxLengthValidation,
    requiredValidation,
    validateMaxLength,
    validateNonNegative,
} from 'core'

import { DefaultFormInput } from '../../components'
import { nameLabel, nameSource } from '../fields/name/name'
import { percentageOnTotal } from '../fields/percentageOnTotal'

const nameValidation = [requiredValidation, maxLengthValidation]
const percentageOnTotalValidator = [
    requiredValidation,
    validateNonNegative(),
    validateMaxLength(10, 'Invalid value'),
]

export const PTTaxForm = () => {
    return (
        <>
            <TextInput
                source={nameSource}
                label={nameLabel}
                validate={nameValidation}
            />

            <TextInput
                source={percentageOnTotal.source}
                label={percentageOnTotal.label}
                validate={percentageOnTotalValidator}
                {...percentageMaskParams}
            />

            <DefaultFormInput withAlert={false} />
        </>
    )
}
