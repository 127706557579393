import { type NoInfer } from 'appTypes'
import { type SortBy, type ListFilterChoice, type Column, type DataListDetails } from 'components'
import { urls } from 'configs'
import { globalClassNames } from 'lib'
import { IconElement, LinkButton, TextWithIcon } from 'ui'
import { pathJoin } from 'utils'

import { TitleWithTelematicsStatus } from '../components/TitleWithTelematicsStatus'
import { type UnitModel } from '../types'

import { avatar } from './avatar'

const label = 'Unit Number'
const source: keyof UnitModel = 'number'

export const value = (record: UnitModel) => record.number
export const linkValue = (record: UnitModel) => (
    <LinkButton to={pathJoin(urls.units, record.id)}>{record.number}</LinkButton>
)

export const linkWithIconValue = (record: Pick<UnitModel, 'id' | 'number'>) => {
    if (!record) {
        return null
    }
    return (
        <LinkButton
            to={pathJoin(urls.units, record.id)}
            className={globalClassNames.ellipsis}
        >
            <TextWithIcon icon={<IconElement component={avatar.Icon} />}>
                {record.number}
            </TextWithIcon>
        </LinkButton>
    )
}

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => UnitModel
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => <TitleWithTelematicsStatus record={dataToRecord(row) || row} />,
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => UnitModel
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => <TitleWithTelematicsStatus record={dataToRecord(data) || data} />,
    }) as const satisfies DataListDetails<any, any>

export const number = {
    source,
    label,
    format: (number: string) => number,
    formatByRecord: (record: UnitModel) => record.number,
    tableColumn,
    dataCardRow,
    filter: <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
        ({
            id: id || source,
            label,
        }) as const satisfies ListFilterChoice,
    sort: <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
        ({
            id: id || source,
            label,
        }) as const satisfies SortBy,

    value,
    linkValue,
}
