import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    ListFilterRangeInput,
    type ListSortContentProps,
    useDistanceLabel,
    formatMoney,
    integerNonNegativeSpacedMaskResolver,
    LinkArrowButton,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { urls } from 'configs'
import {
    getListData,
    ResourceContextProviderWithClearEffect,
    excludeNulls,
    type SortPayload,
    type AuthStore,
} from 'core'
import { useMediaQuery, formatDate, globalClassNames } from 'lib'
import { customerFields } from 'resources/customers'
import { tagField } from 'resources/tags'
import {
    type UnitModel,
    type UnitAggregates,
    unitResource,
    isUnitArchived,
    unitAspects,
} from 'resources/units'
import unitFields from 'resources/units/fields'
import { CK2Field, CK34Field, displayCK2 } from 'resources/vmrs'
import { PageContent, BoxContainer, StyledElement } from 'ui'
import { pathJoin } from 'utils'

import { ArchivedUnitBadge, UnitCardTitleLink } from '../Downtime/components'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'
export interface CostPerMeterModel extends UnitModel {
    costPerHour: number
    distance: number
    costPerDistance: number
    engineHours: number
    costPerMeterTotal: number
}
const defaultSort: SortPayload<CostPerMeterModel> = {
    field: 'costPerMeterTotal',
    order: 'DESC',
}

export const navigateToUnit = ({ id }: Pick<UnitModel, 'id'>) => pathJoin(urls.units, id)

const preferencesResource = {
    ...unitResource,
    name: 'cost-per-meter',
}

const CostPerMeter: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const distanceLabel = useDistanceLabel({ textCase: 'capital' })
        const distanceAbbr = useDistanceLabel({ variant: 'short-abbr', textCase: 'upper' })
        const distancePluralLabel = useDistanceLabel({ plural: true, textCase: 'capital' })

        const isExtraSmall = useMediaQuery((theme) =>
            theme.breakpoints.down(theme.props.mobileViewBreakpoint),
        )

        const columnsCfg: DatagridColumnsProps<CostPerMeterModel> = {
            resetColumns: {
                created: false,
                licensePlate: false,
                vin: false,
                vmrsEquipmentCategory: false,
                model: false,
                modelYear: false,
                color: false,
                status: false,
            },
            constantColumns: {
                number: true,
            },
            checkboxSelection: false,
            columns: [
                {
                    field: unitFields.number.source,
                    headerName: unitFields.number.label,
                    renderCell: (params) => {
                        const { value, ...restParams } = params
                        return (
                            <DatagridLink
                                {...restParams}
                                resource={unitResource.resource}
                                value={
                                    <BoxContainer>
                                        <StyledElement className={globalClassNames.ellipsis}>
                                            {value}
                                        </StyledElement>
                                        {isUnitArchived(params.row) && <ArchivedUnitBadge />}
                                    </BoxContainer>
                                }
                            />
                        )
                    },
                },
                unitFields.name.tableColumn({
                    dataToValue: (record) => record.name,
                    headerName: unitFields.name.longLabel,
                }),
                {
                    field: 'engineHours',
                    headerName: 'Engine Hours',
                    valueFormatter: ({ value }) => integerNonNegativeSpacedMaskResolver(value),
                },
                {
                    field: 'distance',
                    headerName: distancePluralLabel + ' Traveled',
                    valueFormatter: ({ value }) => integerNonNegativeSpacedMaskResolver(value),
                },
                {
                    field: 'costPerMeterTotal',
                    headerName: 'Maintenance Cost',
                    renderCell: ({ value }) => (value || value === 0 ? formatMoney(value) : null),
                    headerAlign: 'right',

                    align: 'right',
                },
                {
                    field: 'costPerHour',
                    headerName: 'Cost per Engine Hour',
                    renderCell: ({ value }) => (value || value === 0 ? formatMoney(value) : null),
                    align: 'right',
                    headerAlign: 'right',
                },
                {
                    field: 'costPerDistance',
                    headerName: 'Cost per ' + distanceLabel,
                    renderCell: ({ value }) => (value || value === 0 ? formatMoney(value) : null),
                    align: 'right',
                    headerAlign: 'right',
                },
                {
                    field: 'created',
                    headerName: 'Created on',
                    renderCell: (cell) => {
                        return formatDate(
                            cell.value,
                            (dateFormats) => dateFormats.shortenedDateTime,
                        )
                    },
                },
                unitFields.licensePlate.tableColumn({
                    dataToValue: (record) => record.licensePlate,
                }),
                unitFields.vin.tableColumn({
                    dataToValue: (record) => record.vin,
                }),
                {
                    field: 'vmrsEquipmentCategory',
                    headerName: 'Equipment Category',
                    renderCell: ({ row }) => displayCK2(row.vmrsEquipmentCategoryData),
                },
                unitFields.model.tableColumn({
                    dataToValue: (record) => record.model,
                }),
                unitFields.modelYear.tableColumn({
                    dataToValue: (record) => record.modelYear,
                }),
                unitFields.color.tableColumn({
                    dataToValue: (record) => record.color,
                }),
                {
                    field: unitFields.status.source,
                    headerName: unitFields.status.label,
                    renderCell: ({ row }) => {
                        return unitFields.status.value(row)
                    },
                },
            ],
            actions: null,
        }

        const cardsCfg: CardListConfig<CostPerMeterModel> = {
            titleSource: (record) => <UnitCardTitleLink record={record} />,
            disableTitleLink: true,
            defaultImage: null,
            details: [
                unitFields.name.dataCardRow({
                    headerName: unitFields.name.longLabel,
                    dataToValue: (record) => record.name,
                }),
                {
                    source: 'engineHours',
                    label: 'Engine Hours',
                    render: (value) => integerNonNegativeSpacedMaskResolver(value),
                },
                {
                    source: 'distance',
                    label: distancePluralLabel + ' Traveled',
                    render: (value) => integerNonNegativeSpacedMaskResolver(value),
                },
                {
                    source: 'costPerMeterTotal',
                    label: 'Maintenance Cost',
                    render: (value) => (value || value === 0 ? formatMoney(value) : null),
                },
                {
                    source: 'costPerHour',
                    label: 'Cost per Engine Hour',
                    render: (value) => (value || value === 0 ? formatMoney(value) : null),
                },
                {
                    source: 'costPerDistance',
                    label: 'Cost per ' + distanceLabel,
                    render: (value) => (value || value === 0 ? formatMoney(value) : null),
                },
            ],
            action: (record) => <LinkArrowButton path={navigateToUnit(record)} />,
        }
        const sortCfg: ListSortContentProps<CostPerMeterModel> = {
            sortBy: [
                {
                    id: 'engineHours',
                    label: 'Engine Hours',
                },
                {
                    id: 'distance',
                    label: distancePluralLabel + ' Traveled',
                },
                unitFields.created.sort(),
                unitFields.number.sort(),
                unitFields.name.sort({ label: unitFields.name.longLabel }),
                unitFields.licensePlate.sort(),
                unitFields.status.sort({ label: 'Unit Status' }),
                CK2Field.sort({ id: 'vmrsEquipmentCategory' }),
                unitFields.vin.sort(),
                unitFields.model.sort(),
                unitFields.modelYear.sort(),
                unitFields.color.sort(),
                unitFields.total.sort({ id: 'costPerMeterTotal' }),
                unitFields.costPerDistance.sort(),
                unitFields.costPerHour.sort(),
            ],
        }

        const filtersCfg: FilterConfig<CostPerMeterModel> = {
            filters: [
                {
                    id: 'engineHours',
                    label: 'Engine Hours',
                    filterType: 'range' as const,
                    renderComponent: (props) => (
                        <ListFilterRangeInput
                            integerValuesInput
                            {...props}
                        />
                    ),
                },
                {
                    id: 'distance',
                    label: distancePluralLabel + ' Traveled',
                    filterType: 'range' as const,
                    renderComponent: (props) => (
                        <ListFilterRangeInput
                            integerValuesInput
                            {...props}
                        />
                    ),
                },
                unitFields.created.filter(),
                unitFields.number.filter(),
                unitFields.name.filter({ label: unitFields.name.longLabel }),
                unitFields.vin.filter(),
                unitFields.licensePlate.filter(),
                unitFields.status.filter({ label: 'Unit Status' }),
                CK2Field.filter({ id: 'vmrsEquipmentCategory', label: 'Equipment Category' }),
                CK34Field.filter({ id: 'vmrsManufacturer', label: 'Manufacturer/Make' }),
                unitFields.model.filter(),
                unitFields.modelYear.filter(),
                CK34Field.filter({ id: 'engineVmrsManufacturer', label: 'Engine Make' }),
                unitFields.engineModel.filter(),
                unitFields.engineHp.filter(),
                CK34Field.filter({
                    id: 'transmissionVmrsManufacturer',
                    label: 'Transmission Make',
                }),
                unitFields.transmissionModel.filter(),
                unitFields.transmissionGears.filter(),
                unitFields.color.filter(),
                unitFields.tireSize.filter(),
                unitFields.total.filter({ id: 'costPerMeterTotal' }),
                unitFields.costPerDistance.filter(),
                unitFields.costPerHour.filter(),
                tagField.filter(),
                ...(auth.companySettings.hasCustomers ? [customerFields.self.filter()] : []),
                { id: 'archived', label: 'Archived Unit' },
            ],
        }

        return (
            <ResourceContextProviderWithClearEffect value={unitResource}>
                <ListBaseWithPeriod
                    preferencesResource={preferencesResource}
                    sort={defaultSort}
                    filter={{ withAspects: unitAspects.costPerMeter, ...excludeNulls }}
                >
                    <ReportsHeader<UnitModel>
                        renderTotal={(list) => {
                            const listData = getListData<UnitAggregates>(list)
                            return (
                                <>
                                    {isExtraSmall
                                        ? `CP${distanceAbbr}`
                                        : 'Cost per ' + distanceLabel}{' '}
                                    {formatMoney(listData.costPerDistance || 0)} |{' '}
                                    {isExtraSmall ? 'CPEH' : 'Cost per Engine Hour'}{' '}
                                    {formatMoney(listData.costPerHour || 0)}
                                </>
                            )
                        }}
                        // aside={<ArchivedUnitsToggler />}
                    >
                        Cost per Meter
                    </ReportsHeader>
                    <PageContent>
                        <ListWithPeriodSelector
                            preferencesResource={preferencesResource}
                            exportFileName="cost-per-meter"
                            filtersCfg={filtersCfg}
                            sortCfg={sortCfg}
                            columnsCfg={columnsCfg}
                            cardsCfg={cardsCfg}
                        />
                    </PageContent>
                </ListBaseWithPeriod>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default CostPerMeter
