import { type InvoiceModel, InvoiceTypeKeys } from '../types'

const source = 'type' as const satisfies keyof InvoiceModel
const label = 'Invoice Type'

const config: { [key in InvoiceTypeKeys]: string } = {
    [InvoiceTypeKeys.DIRECT]: 'Direct Invoice',
    [InvoiceTypeKeys.WORK_ORDER]: 'Work Order',
}

interface Data extends Pick<InvoiceModel, 'status'> {}

const value = (record: Data) => config[record.status]

const invoiceType = {
    value,
    source,
    label,
}

export default invoiceType
