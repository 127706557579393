import { type ReactElement } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { partFields } from 'resources/parts'
import { ck33Fields, displayCK34 } from 'resources/vmrs'
import { type SvgIconComponent } from 'ui'

import { type JobItemModels, JobItemTypeKeys } from '../workOrders/items/types'

export const maxJobNameLength = 150

export const getJobItemData = <K extends keyof JobItemModels>(item: JobItemModels[K]) =>
    jobItemsMap[item.type as K](item)

type JobItemsMapType = {
    [key in keyof JobItemModels]: (record: JobItemModels[key]) => {
        icon: SvgIconComponent | SvgIconElement
        total: number
        totalCaption?: ReactElement
        subtitle: string
    }
}
const jobItemsMap: JobItemsMapType = {
    [JobItemTypeKeys.LABOR]: (record) => ({
        icon: Icons.ScheduleOutlined,
        total: record.hourlyRate * record.hours,
        totalCaption: (
            <>
                {record.hours} x {formatMoney(record.hourlyRate)}
            </>
        ),
        subtitle: ck33Fields.self.value(record.componentData),
    }),
    [JobItemTypeKeys.PART]: (record) => ({
        icon: partFields.avatar.Icon,
        total: record.price * record.quantity,
        totalCaption: (
            <>
                {record.quantity} x {formatMoney(record.price)}
            </>
        ),
        subtitle: `${record.partNumber || ''}${
            record.manufacturerData ? ` • ${displayCK34(record.manufacturerData)}` : ''
        }`,
    }),
    [JobItemTypeKeys.FEE]: (record) => ({
        icon: Icons.Fee,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
    }),
    [JobItemTypeKeys.TAX]: (record) => ({
        icon: Icons.PercentOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
    }),
    [JobItemTypeKeys.SERVICE]: (record) => ({
        icon: Icons.WorkOutlineOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
    }),
}
