import { urls } from 'configs'

export const createReportResource = (report: string) => `reports/${report}`

export const reportsSlugs = {
    maintenanceCost: 'maintenance-cost',
    costPerMeter: 'cost-per-meter',
    downtime: 'downtime',
    costPerCategory: 'cost-per-category',
    costPerVMRSGroup: 'cost-per-vmrs-group',
    vendorExpenses: 'total-vendor-expenses',
    costPerReasonForRepair: 'cost-per-reason-for-repair',
    costPerEquipmentCategory: 'cost-per-equipment-category',
    intervals: 'pm-intervals',
    defects: 'defects',
    urme: 'unscheduled-roadside-maintenance-event',
    mtbf: 'mean-time-between-failures',
    unitExpirations: 'unit-expirations',
}

export const reportsUrls = Object.keys(reportsSlugs).reduce(
    (acc, key) => {
        acc[key] = `${urls.reports}/${reportsSlugs[key]}`
        return acc
    },
    {} as typeof reportsSlugs,
)
