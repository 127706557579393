import { useEffect } from 'react'

import Icons from 'assets/icons'
import { useMediaQuery } from 'lib'
import {
    Stack,
    IconButton,
    Box,
    Divider,
    HideOnBreakpoint,
    BoxContainer,
    StyledElement,
    SimpleScrollbar,
    List,
    ListItemText,
} from 'ui'

import LogoSection from '../LogoSection'

import { useSidebarContext } from './SidebarContext'
import { SidebarDefaultRS } from './SidebarDefaultRS'
import { SidebarDivider } from './SidebarDivider'
import SidebarDrawer from './SidebarDrawer'
import SidebarHide from './SidebarHide'
import SidebarNavItem from './SidebarNavItem'
import SidebarProfile from './SidebarProfile'
import SidebarTooltip from './SidebarTooltip'
import mainMenu from './mainMenu'

const Sidebar = () => {
    const { open, close, toggle } = useSidebarContext()
    const matches = useMediaQuery((theme) => theme.breakpoints.up(theme.props.mobileViewBreakpoint))
    useEffect(() => {
        if (open) {
            document.body.classList.add('scroll-lock')
        } else {
            document.body.classList.remove('scroll-lock')
        }

        return () => {
            document.body.classList.remove('scroll-lock')
        }
    }, [open])

    return (
        <SidebarDrawer
            variant="permanent"
            anchor="left"
            open={open}
            onClose={close}
            ModalProps={{ keepMounted: true }}
        >
            <Box>
                <StyledElement
                    sx={{
                        padding: '16px 20px',
                    }}
                >
                    {/* No ternary operator here, it has small transition effect */}
                    <LogoSection sx={{ ...(open ? null : { display: 'none' }) }} />
                    <LogoSection
                        type="small"
                        sx={{ ...(open ? { display: 'none' } : null) }}
                    />
                </StyledElement>
                <SidebarDivider />
            </Box>

            <SimpleScrollbar
                style={{
                    paddingLeft: '4px',
                    paddingRight: '3px',
                    paddingTop: '16px',
                    height: '100%',
                    overflowX: 'hidden',
                }}
            >
                <List disablePadding>
                    {mainMenu.map((item) => (
                        <SidebarNavItem {...item} />
                    ))}
                </List>
            </SimpleScrollbar>
            <Box mt="auto">
                <SidebarDivider />
                <Stack
                    gap="16px"
                    py="16px"
                >
                    {matches && <SidebarDefaultRS />}
                    <SidebarProfile />
                </Stack>
                <HideOnBreakpoint>
                    <>
                        <Divider />
                        <BoxContainer
                            sx={(theme) => ({
                                padding: '8px ' + theme.props.sidebarPaddingX + 'px',
                                cursor: 'pointer',
                            })}
                            onClick={toggle}
                        >
                            <SidebarTooltip
                                title="Expand"
                                placement="right"
                                arrow
                            >
                                <IconButton
                                    aria-label={open ? 'Expand Sidebar' : 'Collapse Sidebar'}
                                    sx={{
                                        width: '48px',
                                        height: '48px',
                                    }}
                                >
                                    {open ? <Icons.ArrowBack /> : <Icons.ArrowForward />}
                                </IconButton>
                            </SidebarTooltip>

                            <SidebarHide>
                                <ListItemText sx={{ marginLeft: '20px' }}>Collapse</ListItemText>
                            </SidebarHide>
                        </BoxContainer>
                    </>
                </HideOnBreakpoint>
            </Box>
        </SidebarDrawer>
    )
}

export default Sidebar
