import { type ReactNode } from 'react'

import { type ExtendRecordType } from 'appTypes'
import { formatMoney } from 'components'
import { createResource } from 'core'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck33Fields, displayCK34 } from 'resources/vmrs'
import { displayBooleanValue } from 'utils'

import { PartCk33Label } from './components/PartCk33Label'
import { type PartModel } from './types'

type PartCardDetailsType = {
    [key in keyof PartModel]?: {
        label: ReactNode
        source: ExtendRecordType<PartModel, ReactNode>
    }
}

export const partCardDetails: PartCardDetailsType = {
    componentData: {
        label: <PartCk33Label />,
        source: ({ componentData }) => ck33Fields.self.value(componentData),
    },
    manufacturerData: {
        label: 'Manufacturer',
        source: ({ manufacturerData }) => displayCK34(manufacturerData),
    },
    manufacturerPartNumber: {
        label: 'Manufacturer Part Number',
        source: 'manufacturerPartNumber',
    },
    unitOfMeasure: {
        label: uomFields.self.label,
        source: ({ unitOfMeasureData }) => uomFields.self.value(unitOfMeasureData),
    },
    universalProductCode: { label: 'UPC', source: 'universalProductCode' },
    positionApplicable: {
        label: 'Position Applicable',
        source: ({ positionApplicable }) => displayBooleanValue(positionApplicable),
    },
    serializedPart: {
        label: 'Serialized Part',
        source: ({ serializedPart }) => displayBooleanValue(serializedPart),
    },
    cost: {
        label: 'Last Known Cost',
        source: ({ cost }) => formatMoney(cost),
    },
}

export const partsResource = createResource({ resource: 'parts', name: 'parts' })

export const vendorPartsResource = createResource({
    resource: 'vendor-parts',
    name: 'vendor-parts',
})
