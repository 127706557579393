import { type ReactNode } from 'react'

import { type EditProps, EditBase, useUpdate } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { Form, type FormProps } from 'components/form'
import { type DataProviderMeta } from 'core/data'
import { type UseSubmitExtraArgs, useSubmit } from 'core/form'
import { type ResourceType, useResource } from 'core/resource'

import { makeSubmit, type makeSubmitConfig } from './common'

export interface EditCustomProps<RecordType extends DataRecord = any>
    extends Omit<EditProps<RecordType>, 'resource' | 'mutationOptions'>,
        makeSubmitConfig<RecordType>,
        Pick<FormProps, 'formOnError' | 'validate'>,
        Pick<UseSubmitExtraArgs, 'successMessage'> {
    children: ReactNode
    className?: string
    warnWhenUnsavedChanges?: boolean
    resource?: ResourceType
    meta?: DataProviderMeta
    defaultValues?: Partial<RecordType> | ((record: RecordType) => Partial<RecordType>)
}

const Edit = <RecordType extends DataRecord = any>({
    children,
    makeData,
    className,
    onSuccess,
    serializer,
    warnWhenUnsavedChanges,
    formOnError,
    resource: resourceProp,
    meta,
    defaultValues,
    validate,
    successMessage,
    mutationOptions,
    ...rest
}: EditCustomProps<RecordType>) => {
    const resource = useResource(resourceProp)
    const [edit] = useUpdate()

    const submit = useSubmit(
        makeSubmit(edit, {
            resource,
            makeData,
            onSuccess: (...args) => {
                onSuccess?.(...args)
            },
            serializer,
            meta,
            mutationOptions,
        }),
        {
            successMessage: successMessage ?? (({ defaultMessages }) => defaultMessages.updated),
        },
    )

    return (
        <EditBase
            {...rest}
            // TODO: Remove after RA
            id={rest.id as any}
        >
            <Form
                validate={validate}
                formOnError={formOnError}
                onSubmit={submit}
                className={className}
                defaultValues={defaultValues}
                warnWhenUnsavedChanges={warnWhenUnsavedChanges}
            >
                {children}
            </Form>
        </EditBase>
    )
}

export default Edit
