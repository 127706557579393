import { type ReactElement } from 'react'

import { useListContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer, useResource } from 'core'
import { basePermissions, renderOnPermission } from 'core/permissions'
import { type MemberModel } from 'resourcesBase'

import MemberForm from './MemberForm'

const memberSerializer: Serializer<MemberModel> = ['role', { name: 'shops', parse: 'emptyToNull' }]

const EditMemberAction = renderOnPermission(
    ({ children, id }: { children: (openDrawer: () => void) => ReactElement; id: Identifier }) => {
        const open = useOpenUtilityDrawer()
        const listContext = useListContext<MemberModel>()
        const resource = useResource()

        const openDrawer = () => {
            open({
                drawerArgs: {
                    title: 'Edit User',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            serializer={memberSerializer}
                            {...params}
                        />
                    ),
                    renderContent: () => <MemberForm />,
                },
                extraArgs: {
                    listContext,
                    id,
                    resource,
                },
            })
        }

        return children(openDrawer)
    },
    basePermissions.update,
)

export default EditMemberAction
