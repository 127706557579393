import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core'
import { StatusKeys, expirationFields } from 'resources/expirations'
import { createReportResource, reportsUrls } from 'resources/reports'
import { defaultDomicileFilter } from 'resources/units'
import { StatusText } from 'ui'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface DataType {
    overdue: number
    planned: number
    dueSoon: number
}

const MonitorExpirations: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const data = useMonitor<DataType>(
            createReportResource('expirations-by-status'),
            defaultDomicileFilter(auth.defaultShop),
        )

        if (!data) {
            return <Monitor.Skeleton />
        }

        const createLink = (status: StatusKeys) => {
            return (
                reportsUrls.unitExpirations +
                '?filter=' +
                encodeURIComponent(
                    JSON.stringify({
                        status: [status],
                        ...defaultDomicileFilter(auth.defaultShop),
                    }),
                )
            )
        }
        return (
            <Monitor>
                <Monitor.Title icon={Icons.UnitExpirations}>Unit Expirations</Monitor.Title>

                <Monitor.ArrowButton navigateTo={reportsUrls.unitExpirations} />
                <Monitor.Container>
                    <Monitor.Button navigateTo={createLink(StatusKeys.OVERDUE)}>
                        <Monitor.ValueText>{data.overdue || 0}</Monitor.ValueText>
                        <StatusText
                            sx={{ width: 'fit-content' }}
                            statusColor={(theme) => theme.palette.charts.red}
                        >
                            {expirationFields.status.getConfig(StatusKeys.OVERDUE).text}
                        </StatusText>
                    </Monitor.Button>
                    <Monitor.Button navigateTo={createLink(StatusKeys.DUE_SOON)}>
                        <Monitor.ValueText>{data.dueSoon || 0}</Monitor.ValueText>
                        <StatusText
                            sx={{ width: 'fit-content' }}
                            statusColor={(theme) => theme.palette.charts.orange}
                        >
                            {expirationFields.status.getConfig(StatusKeys.DUE_SOON).text}
                        </StatusText>
                    </Monitor.Button>
                    <Monitor.Button navigateTo={createLink(StatusKeys.PLANNED)}>
                        <Monitor.ValueText>{data.planned || 0}</Monitor.ValueText>
                        <StatusText
                            sx={{ width: 'fit-content' }}
                            statusColor={(theme) => theme.palette.charts.greenBody}
                        >
                            {expirationFields.status.getConfig(StatusKeys.PLANNED).text}
                        </StatusText>
                    </Monitor.Button>
                </Monitor.Container>
            </Monitor>
        )
    }),
)
export default MonitorExpirations
