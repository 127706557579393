import { type NoInfer } from 'appTypes'
import { type SortBy, type Column, type ListFilterChoice } from 'components'
import { createResource } from 'core'

import { TagsField } from './components/TagsField'
import { type TagModel } from './types'

export const tagsResource = createResource({
    name: 'tags',
    resource: 'tags',
})

const source = 'tags' as const
const label = 'Tags'

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({ id: (id || source) as NoInfer<Source>, label }) as const satisfies ListFilterChoice

const sort = () => ({ id: source, label }) as const satisfies SortBy

const column = <Source extends string = typeof source>({
    dataToRecord,
    id,
}: {
    id?: Source
    dataToRecord: (data) => TagModel[]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => <TagsField tags={dataToRecord(row)} />,
    }) as const satisfies Column

export const tagField = {
    filter,
    sort,
    column,
}
