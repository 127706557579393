import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { type WorkOrderModel } from 'resources/workOrders'
import { IconBox } from 'ui'

import WorkOrderDrawerToggler from './WorkOrderDrawerToggler'

const WorkOrderEditButton = () => {
    const record = useRecordContext<WorkOrderModel>()

    if (record?.status !== 'OPEN') {
        return null
    }

    return (
        <WorkOrderDrawerToggler id={record.id}>
            {({ onClick }) => (
                <IconBox
                    onClick={onClick}
                    title="Edit"
                >
                    <Icons.Edit />
                </IconBox>
            )}
        </WorkOrderDrawerToggler>
    )
}

export default WorkOrderEditButton
