import { type FC } from 'react'

import { EmailInput, TelInput, TextInput, UploadImage } from 'components'
import { validateMaxLength, maxLengthValidation, requiredValidation, urlValidation } from 'core'
import { addressFields } from 'resources/address'
import { CountryInput } from 'resources/country'
import { PaymentMethodInput } from 'resources/paymentMethods'
import { PaymentTermInput } from 'resources/paymentTerms'
import { StateInput } from 'resources/state'
import { TagInput } from 'resources/tags'
import { SectionTitleSmall } from 'ui'
import { lowerCaseFormat } from 'utils'

import customerFields from '../dataConfig'
import { type CustomerModel } from '../types'

const nameValidation = [requiredValidation, maxLengthValidation]
const dotValidation = validateMaxLength(30)
const zipValidation = validateMaxLength(10)
const taxValidation = validateMaxLength(20)

export const CustomersForm: FC = () => {
    return (
        <>
            <SectionTitleSmall>Company Details</SectionTitleSmall>
            <TextInput<CustomerModel>
                source={customerFields.name.source}
                label={customerFields.name.label}
                validate={nameValidation}
            />
            <TextInput<CustomerModel>
                source={customerFields.dotNumber.source}
                label={customerFields.dotNumber.label}
                validate={dotValidation}
            />
            <UploadImage
                source={customerFields.avatar.source}
                buttonUploadText="UPLOAD LOGO"
                buttonChangeText="CHANGE LOGO"
                defaultIcon={<customerFields.avatar.Icon />}
            />
            <TelInput label={customerFields.phone.label} />
            <EmailInput label={customerFields.email.label} />
            <TextInput<CustomerModel>
                parse={lowerCaseFormat}
                source={customerFields.website.source}
                label={customerFields.website.label}
                validate={urlValidation}
            />
            <TagInput />
            <SectionTitleSmall frontLine>Address</SectionTitleSmall>
            <TextInput<CustomerModel>
                source={addressFields.address1.source}
                label={addressFields.address1.label}
                validate={maxLengthValidation}
            />
            <TextInput<CustomerModel>
                source={addressFields.address2.source}
                label={addressFields.address2.label}
                validate={maxLengthValidation}
            />
            <CountryInput />
            <StateInput />
            <TextInput<CustomerModel>
                source={addressFields.city.source}
                label={addressFields.city.label}
                validate={maxLengthValidation}
            />
            <TextInput
                source={addressFields.zipCode.source}
                label={addressFields.zipCode.label}
                validate={zipValidation}
            />
            <SectionTitleSmall>Financial Information</SectionTitleSmall>
            <TextInput
                source={customerFields.taxId.source}
                label={customerFields.taxId.label}
                validate={taxValidation}
            />
            <PaymentMethodInput
                label={customerFields.paymentMethod.label}
                source={customerFields.paymentMethod.source}
            />
            <PaymentTermInput label={customerFields.paymentTerm.label} />
        </>
    )
}
