import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEdit } from 'components'
import { basePermissions, useResourcePermissions } from 'core'
import { shopResource } from 'resources/shops'

import InvoicingSettingsForm from './InvoicingSettingsForm'

interface InvoicingSettingsDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    id: Identifier
}

const InvoicingSettingsDrawerToggler = ({ children, id }: InvoicingSettingsDrawerTogglerProps) => {
    const permissions = useResourcePermissions()

    const disabled = !permissions[basePermissions.update]
    const open = useOpenUtilityDrawer()
    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Settings',
                    renderWrapper: (params) => <UtilityDrawerEdit {...params} />,
                    renderContent: () => <InvoicingSettingsForm disabled={disabled} />,
                },
                extraArgs: {
                    id,
                    resource: shopResource,
                },
            })
        },
    })
}

export default InvoicingSettingsDrawerToggler
