import { useShowContext } from 'react-admin'

import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtFeeResource, ptFeePreferencesResource } from 'resources/pricingTiers'

import { FeeList, ListHeader } from './components'

const defaultSort: SortPayload = {
    field: 'name',
    order: 'DESC',
}

export const PricingTiersFees = () => {
    const { record } = useShowContext()

    return (
        <ResourceContextProviderWithClearEffect value={getPtFeeResource(record?.id)}>
            <ListBase
                sort={defaultSort}
                isLoading={!record}
                preferencesResource={ptFeePreferencesResource}
            >
                <ListHeader />
                <FeeList preferencesResource={ptFeePreferencesResource} />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}
