import { useShowContext } from 'react-admin'

import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtServicesResource, ptServicesPreferencesResource } from 'resources/pricingTiers'

import { ServicesList, ListHeader } from './components'

const defaultSort: SortPayload = {
    field: 'name',
    order: 'DESC',
}

export const PricingTiersServices = () => {
    const { record } = useShowContext()
    return (
        <ResourceContextProviderWithClearEffect value={getPtServicesResource(record?.id)}>
            <ListBase
                preferencesResource={ptServicesPreferencesResource}
                sort={defaultSort}
                isLoading={!record}
            >
                <ListHeader />
                <ServicesList preferencesResource={ptServicesPreferencesResource} />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}
